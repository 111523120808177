import React, { useState, useRef, useEffect } from 'react';

import Layout from 'components/common/DashboardLayout';
import { Link } from '@reach/router';
import GroupPathway from '../groupPathway';
import FeedsChat from '../feedsChat';
import { FaArrowLeft } from 'react-icons/fa';
import { navigate } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import OthergrouppathwayFeeds from './othergrouppathwayFeeds';
import { updatePrevLocation } from 'appRedux/actions/location';

const OtherGroupIndex = () => {
  const dispatch = useDispatch();
  const prevPathname = useSelector(state => state.location.prevpathname);

  useEffect(() => {
    dispatch(updatePrevLocation(prevPathname));
  }, []);

  return (
    <Layout>
      <section className=" feeds-dashboard bg-white">
        <div
          className="isw-container"
          style={{ width: '100%', overflow: 'scroll' }}
        >
          <Link
            to={`${prevPathname ? prevPathname : `/feeds`}`}
            style={{
              color: '#66A848',
              fontSize: '16px',
              marginBottom: '1rem',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              fontWeight: '700',
              marginTop: '5px',
            }}
            onClick={() => {
              dispatch(updatePrevLocation(''));
            }}
          >
            <FaArrowLeft />
            Go back
          </Link>

          <div className="d-flex w-full">
            <div style={{ width: '70%' }}>
              {/* <GroupPathway/> */}
              <OthergrouppathwayFeeds />
            </div>
            <div style={{ width: '30%' }}>
              <FeedsChat />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OtherGroupIndex;
