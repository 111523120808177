import React, { useEffect } from 'react';
import './transfercash.scss';
import DividedPinInput from 'components/common/DividedPinInput';
import { Form } from 'antd';
import Loader from 'components/Loader';
import { connect } from 'react-redux';
import { Success, Transuccess } from '../../common/success/success.js';
import { useDispatch } from 'react-redux';
import { SUCCESSFUL_TRANSACTION } from 'appRedux/constants';
import { Currencyamount } from 'components/currencyandamount/currencyamount';
import Error from 'assets/newerror.svg';
import { Transreview } from 'components/common/transactionreview/review';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { getCurrency } from 'helpers/utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Textbal } from '../littlebalance/littlebalance';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';
const Transfercash = props => {
  // console.log('Transfer Cash Props : ', props);
  const allWallet = useSelector(state => state.wallets?.wallets, _.isEqual);
  const dispatch = useDispatch();
  const setStep = value => {
    dispatch({ type: SUCCESSFUL_TRANSACTION, payload: value });
  };

  const closeSuccess = () => {
    props.closeModal();
    dispatch({ type: SUCCESSFUL_TRANSACTION, payload: 0 });
    setStep(0);
  };
  const closeError = () => {
    setStep(0);
    dispatch({ type: SUCCESSFUL_TRANSACTION, payload: 0 });
  };
  switch (props.step) {
    case 0:
      return (
        <>
          <Titlesubtitle
            title={`Recipient • ${props.currentUser.firstName +
              ' ' +
              props.currentUser.lastName}`}
            subtitle="Confirm recipient details and send funds to recipient."
          />
          <Recipient
            setStep={setStep}
            step={props.step}
            firstname={props.currentUser.firstName}
            lastname={props.currentUser.lastName}
            username={props.currentUser.username}
            profile={props.image}
            number={props.currentUser.phoneNumber}
            email={props.currentUser.email}
            amount={props.amount}
            currency={props.currency}
            onAmountChange={props.onAmountChange}
            nairaAmount={props.nairaAmount}
            dollarAmount={props.dollarAmount}
            handleCurrencyChange={props.handleCurrencyChange}
            allWallet={allWallet}
          />
        </>
      );
    case 1:
      return (
        <Finalize
          setStep={setStep}
          step={props.step}
          // loading={props.loading}
          loading={props.transLoading}
          handleTransferCash={props.handleTransferCash}
          setTransactionOtp={props.setTransactionOtp}
          amount={props.amount}
          dispatch={dispatch}
          currency={props.currency}
          currentUser={props.currentUser}
        />
      );
    case 2:
      return (
        <Transuccess
          url={props.image}
          title={`Successful Transfer to ${props.currentUser.firstName}`}
          button="Okay, Thank You!!!"
          subtitle={` Your transfer of ${props.currency.slice(
            0,
            3,
          )} ${props.amount.toLocaleString('en-us')}  to ${
            props.currentUser.firstName
          } ${props.currentUser.lastName} was successful.`}
          onClick={() => closeSuccess()}
        />
      );
    case 3:
      return (
        <Success
          image={Error}
          type="error"
          button="Let Me Try Again"
          title="Transaction Failed"
          subtitle={` Your transfer of ${props.currency.slice(
            0,
            3,
          )} ${props.amount.toLocaleString('en-us')} to ${
            props.currentUser.firstName
          } ${props.currentUser.lastName} failed, ${props.transMessage}.`}
          onClick={closeError}
        />
      );
    default:
      return <>Not Found {props.step}</>;
  }
};

const Recipient = props => {
  var nextStep = () => {
    props.setStep(1);
  };
  var selected = props.allWallet.filter(
    item =>
      item.currency.toLocaleLowerCase() === props.currency.toLocaleLowerCase(),
  )[0];

  return (
    <div className="recipient">
      <div className="recipient__inner">
        <img src={props.profile} alt="recipient profile" />

        <div className="recipient__content">
          <p>
            {props.firstname} {props.lastname}
          </p>
          <p>{props.email}</p>
          {props.username.length < 100 ? <p>@{props.username}</p> : ''}
        </div>

        <Currencyamount
          handleCurrencyChange={props.handleCurrencyChange}
          onAmountChange={props.onAmountChange}
          amount={props.amount}
          currency={props.currency}
          btn="Proceed to Input Pin to send"
          onClick={nextStep}
          selected={selected}
        />
        {props.currency && (
          <Textbal
            wallet={`${selected.name} `}
            bal={selected.balance / 100}
            currency={selected.currency}
          />
        )}
      </div>
    </div>
  );
};

const Finalize = props => {
  // console.log("Finalize props :", props)
  useEffect(() => {
    props.dispatch(fetchTransactionOTP());
  }, []);
  return (
    <Form onFinish={props.handleTransferCash} style={{ width: '100%' }}>
      <div className="recipient__form">
        <Transreview
          data={[
            { title: 'Transaction Type', value: 'Transfer' },
            {
              title: 'Amount',
              value: `${getCurrency(
                props.currency,
              )}${props.amount.toLocaleString('en-US')}`,
            },

            { title: 'Currency', value: props.currency },
            {
              title: 'User Fullname',
              value: `${props.currentUser.firstName} ${props.currentUser.lastName}`,
            },
            {
              title: 'Fee',
              value: props.currency === 'NGN_KOBO' ? `0.00` : '0.00',
            },
          ]}
        />
        <div className="w-100 flex-fill pt-4" style={{ width: '100%' }}>
          <p className="mb-2">Enter transaction code sent to your email</p>
          <DividedPinInput onChange={props.setTransactionOtp} />
        </div>
        {props.loading ? (
          <Loader />
        ) : (
          <>
            <Backcontinue
              back="Go Back"
              goBack={() =>
                props.step <= 2
                  ? props.setStep(0)
                  : props.setStep(props.step - 1)
              }
            >
              <Platformbutton name="Finalize" type="submit" />
            </Backcontinue>
            <p className="mt-2">
              Didn't get the code?{' '}
              <span
                className="italic text-green-800"
                onClick={() => props?.dispatch(fetchTransactionOTP())}
              >
                Resend
              </span>
            </p>
          </>
        )}
      </div>
    </Form>
  );
};

// eslint-disable-next-line
const Smallbox = props => {
  return (
    <div className="smallbox-container">
      <div className="smallbox-inner-container">
        <p>
          You are about to transfer the sum of
          <strong>
            {' '}
            {props.currency.slice(0, 3)}{' '}
            {(props.amount / 1)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
          </strong>
          to{' '}
          <strong>
            {' '}
            {props.currentUser.firstName} {props.currentUser.lastName}{' '}
          </strong>
          kindly input your<strong> Pin </strong> to conclude the transaction.
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { step, transMessage, transLoading } = transactions;
  return {
    step,
    transMessage,
    transLoading,
  };
};
export default connect(mapStateToProps)(Transfercash);
