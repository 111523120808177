import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import PaymentSection from './paymentSection';
import { ImCheckboxChecked } from 'react-icons/im';
import { H1bNote } from '../../../note';
import { FillingCompanies } from './fillingCompanies';
import { useDispatch, useSelector } from 'react-redux';
import { handleH1bFormRes } from 'appRedux/actions/visaForm';
import './h1brightSide.scss';

ReactGA.initialize('G-CY10JKD1J3');

export const RightSection = props => {
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);

  const [isPaid, setIsPaid] = useState(false);

  const h1bDegreeRes = useSelector(state => state.visaForm.degree);
  const h1bCompanyRes = useSelector(state => state.visaForm.fillingCompany);
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsPaid(isPaid);
  }, [isPaid]);

  useEffect(() => {
    dispatch(
      handleH1bFormRes(
        h1bDegreeRes,
        isChecked && h1bCompanyRes !== 'I have company filing for me'
          ? 'I have company filing for me'
          : '',
      ),
    );
  }, [isChecked]);

  return (
    <div
      style={{ height: '600px', overflowY: 'auto' }}
      className="VisaCustomScroll"
    >
      {props.paymentPercent == 100 ? (
        <div>
          <p
            style={{
              color: '#62943B',
              fontSize: '14px',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            Clarity Session Instructional Video
          </p>

          <div
            className="aboutpathwayimgbox"
            style={{ margin: '10px 0px', borderRadius: '10px' }}
          >
            <video
              className="video-wrapper"
              controls
              width="100%"
              height="100%"
              style={{ borderRadius: '10px' }}
            >
              <source
                src={`https://storagefileforpathway.s3.amazonaws.com/videos/web/H1B+video.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '10px',
              // justifyContent: 'center',
            }}
          >
            <div style={{}}>
              <ImCheckboxChecked
                style={{
                  background: 'white',
                  color: props.isClarityVideoChecked ? '#518C36' : 'white',
                  cursor: 'pointer',
                  border: '1px solid #518C36',
                  width: '15px',
                  height: '15px',
                  borderRadius: '4px',
                  marginTop: '6px',
                }}
                onClick={() => {
                  props.handleVideoCheckboxChange();
                  ReactGA.event({
                    event_name: 'clarity_video_verification',
                    event_category: 'User',
                    event_action: 'Verification Button for Clarity Video',
                    event_label: 'H1B Video Button',
                  });
                }}
              />
            </div>
            <p
              style={{
                color: '#67A948',
                fontSize: '14px',
                fontWeight: '500',
                // textAlign: 'center',
              }}
            >
              I have watched the video and I agree to Vesti’s{' '}
              <a
                href="https://wevesti.com/tos"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                {' '}
                Terms & Conditions{' '}
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              justifyContent: 'center',
            }}
          >
            <div style={{}}>
              <ImCheckboxChecked
                style={{
                  background: 'white',
                  color:
                    isChecked || h1bCompanyRes == 'I have company filing for me'
                      ? '#518C36'
                      : 'white',
                  cursor: 'pointer',
                  border: '1px solid #518C36',
                  width: '15px',
                  height: '15px',
                  borderRadius: '4px',
                }}
                onClick={() => handleCheckboxChange()}
              />
            </div>
            <p
              style={{ color: '#67A948', fontSize: '14px', fontWeight: '500' }}
            >
              I have my own filing company{' '}
            </p>
          </div>
          <PaymentSection
            isChecked={isChecked}
            setIsPaid={setIsPaid}
            visaValue={props.visaValue}
            formId={props.formId}
            firstName={props.firstName}
            h1bFee={props.h1bFee}
          />
        </div>
      )}

      <div style={{ marginTop: '20px' }}>
        <H1bNote />
      </div>

      {isChecked || h1bCompanyRes == 'I have company filing for me' ? (
        <></>
      ) : (
        <div style={{ marginTop: '20px' }}>
          <FillingCompanies />
        </div>
      )}
    </div>
  );
};
