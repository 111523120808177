import React, { useState, useEffect } from 'react';
import './h1brightSide.scss';
import { Platformbutton } from 'components/common/button/button';
import { SelectCurrencyThree } from 'components/bank/selectcurrencyTwo';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import api from 'appRedux/api';
import { objectValuesStringify } from 'helpers/utils';
import { Getpricefromdollar } from 'containers/Admin/Streaming/getpricefromdollar';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { SimpleCancelmodal } from 'components/common/simplifiedmodal';

const myData = [
  {
    currencyFlag:
      'https://cdn.pixabay.com/photo/2012/04/10/16/22/united-26177__480.png',
    currency: 'USD',
  },
  {
    currencyFlag: 'https://flagcdn.com/ng.svg',
    currency: 'NGN',
  },
  {
    currencyFlag: '',
    currency: 'Others',
  },
];

const PaymentSection = props => {
  const userData = useSelector(state => state.auth?.userData);

  const h1bDegreeRes = useSelector(state => state.visaForm.degree);
  const h1bCompanyRes = useSelector(state => state.visaForm.fillingCompany);

  const [selected, setSelected] = useState({});

  const LotteryRegistration = props.h1bFee?.fee[1].amount / 100;
  const RecruiterFee =
    props.isChecked || h1bCompanyRes == 'I have company filing for me'
      ? 0
      : props.h1bFee?.fee[2].amount / 100;

  const totalAmount = LotteryRegistration + RecruiterFee;

  // console.log();

  const currencies = myData;

  const [rate, setRate] = useState(null);

  useEffect(() => {
    // settIsLoading(true);
    const fetchpriceData = async () => {
      try {
        const response = await api.get(
          `/vesti-fx/lists?currency=${
            selected?.currency == 'Others' ? 'USD' : selected?.currency
          }`,
        );
        setRate(response.data?.usdConversionRate);
        // settIsLoading(false);
      } catch (error) {
        // settIsLoading(false);
        console.log(error);
      }
    };

    fetchpriceData();
  }, [selected.currency]);

  useEffect(() => {
    setSelected(myData[0]);
  }, []);

  const handleCurrencyChange = selectedCurrency => {
    // setSelected(selectedCurrency);
  };

  const [openIframeFull, setOpenIframeFull] = useState(false);

  const handlePayment = async formId => {
    setOpenIframeFull(true);

    // setTimeout(() => {
    //   props.isChecked
    //     ? window.open(
    //         `https://mainstack.store/wevesti/20NLhw2O-WhC/checkout?currency=${selected.currency}`,
    //       )
    //     : window.open(
    //         `https://mainstack.store/wevesti/_ltkW97_AiOl/checkout?currency=${selected.currency}`,
    //       );
    // }, 100);
    // const payload = {
    //   userId: userData?.id,
    //   email: userData?.email,
    //   fullname: `${userData?.firstName} ${userData?.lastName}`,
    //   currency: selected.currency,
    //   amount: totalAmount * rate * 100,
    //   visaType: 'H1B',
    //   paymentPercent: 100,
    //   amountPaid: totalAmount * rate * 100,
    //   id: props.formId,
    //   paymentOption: 'card',
    //   firstName: props.firstName,
    //   totalCost: totalAmount * rate * 100,
    // };

    // const url = `https://pathwayapi.wevesti.com/api/v1/pathway/pay-for-visa-document`;

    // try {
    //   const res = await api.post(url, payload);
    //   const { data } = res;
    //   setTimeout(() => {
    //     window.open(data?.data?.url);
    //   }, 100);
    // } catch (error) {
    //   console.log(error);
    //   if (error?.data?.errors) {
    //     openNotificationWithIconErr(
    //       objectValuesStringify(error?.data?.errors),
    //       'Visa Form Payment',
    //       'error',
    //     );
    //   } else {
    //     const err = error?.data?.message || error.message;
    //     openNotificationWithIconErr(err, 'Visa Form Payment', 'error');
    //   }
    // }
  };

  const toWholeCurrency = num => {
    const value = num / 100;

    const formattedValue = value.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };

  return (
    <div>
      <SimpleCancelmodal
        open={openIframeFull}
        onClick={() => setOpenIframeFull(false)}
      >
        <div style={{ marginTop: '20px' }}></div>
        {/* {openIframeFull && ( */}
        <div className="mainStackpopup" style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              left: '0px',
              top: '0px',
              right: '0px',
              bottom: '0px',
              height: '100%',
            }}
          >
            {props.isChecked ? (
              <iframe
                id="mainstack_checkout_iframe"
                src={`https://checkout.mainstack.me/?productId=UzdwGXfvqjhU&type=speed&currency=${
                  selected?.currency == 'Others' ? '' : selected?.currency
                }`}
                style={{
                  height: '100%',
                  width: '100%',
                  border: '0',
                  borderRadius: '20px',
                  padding: '0',
                }}
                allow="payment"
              ></iframe>
            ) : (
              <iframe
                id="mainstack_checkout_iframe"
                src={`https://checkout.mainstack.me/?productId=v7TwEInChz8k&type=speed&currency=${
                  selected?.currency == 'Others' ? '' : selected?.currency
                }`}
                style={{
                  height: '100%',
                  width: '100%',
                  border: '0',
                  borderRadius: '20px',
                  padding: '0',
                }}
                allow="payment"
              ></iframe>
            )}
          </div>
        </div>
        {/* )} */}
      </SimpleCancelmodal>

      <div className="Hb1paymentSection">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#1D2939',
            fontWeight: '600',
            padding: '10px 10px',
          }}
        >
          <p>INVOICE</p>
          <p>Download</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#eaedf3',
            padding: '10px 10px',
            borderBottom: '1px solid #d0d5dd',
          }}
        >
          <p>Currency</p>

          {/* <p style={{ color: '#1D2939', fontWeight: '600' }}>USD</p> */}

          <div className="wallet__topinner">
            <SelectCurrencyThree
              setSelected={setSelected}
              selected={selected}
              options={currencies.map(item => ({
                value: item.currency,
                label: '',
                // image: __renderWalletSvg(props.selected?.name),
                image: item.currencyFlag,
                ...item,
              }))}
              handleCurrencyChange={handleCurrencyChange}
            />
          </div>
        </div>{' '}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#eaedf3',
            padding: '10px 10px',
            borderBottom: '1px solid #d0d5dd',
          }}
        >
          {' '}
          <p>Lottery Registration & Clarity Fees</p>
          <p style={{ color: '#1D2939', fontWeight: '600' }}>
            {selected.currency === 'USD' || selected.currency == 'Others'
              ? '$'
              : '₦'}
            {toWholeCurrency(LotteryRegistration * rate * 100)}
          </p>
        </div>{' '}
        <div
          // className="h1bOptioonalFee"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#eaedf3',
            padding: '10px 10px',
            borderBottom: '1px solid #d0d5dd',
          }}
        >
          {' '}
          <p>Recruiter’s Fee</p>
          <p style={{ color: '#1D2939', fontWeight: '600' }}>
            {selected.currency === 'USD' || selected.currency == 'Others'
              ? '$'
              : '₦'}
            {toWholeCurrency(RecruiterFee * rate * 100)}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#1D2939',
            fontWeight: '600',
            padding: '10px 10px',
          }}
        >
          {' '}
          <p>Total amount</p>
          <p>
            {selected.currency === 'USD' || selected.currency == 'Others'
              ? '$'
              : '₦'}
            {toWholeCurrency(totalAmount * rate * 100)}
          </p>
        </div>
      </div>

      <div
        onClick={() => {
          handlePayment();
        }}
        className=" text-white  w-full cursor-pointer text-center  rounded-md text-[18px] font-[500]"
        style={{
          background: '#67A948',
          padding: '15px 8px',
          marginTop: '20px',
        }}
      >
        Pay{' '}
        {selected.currency === 'USD' || selected.currency == 'Others'
          ? '$'
          : '₦'}
        {toWholeCurrency(totalAmount * rate * 100)}{' '}
        {!selected.currency === 'USD' ||
        !selected.currency == 'Others' ||
        selected.currency === 'NGN' ||
        props.isChecked ? (
          ''
        ) : (
          <s className="ml-4">$1000</s>
        )}
      </div>

      {/* <Platformbutton
        name={`Pay ${selected.currency === 'USD' ? '$' : '₦'}${toWholeCurrency(
          totalAmount * rate * 100,
        )} `}
        type="normal"
        click={() => {
          handlePayment();
        }}
        disabled={h1bDegreeRes == 'No' ? true : false}
      /> */}
    </div>
  );
};

export default PaymentSection;
