import React, { useState, useEffect } from 'react';
import api from 'appRedux/api';
import { useUserData } from 'helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { Newpost } from 'components/common/notifybox/newpost';
import duummyAvatar from '../../../../assets/dummy-avatar.png';
import Loader from 'components/Loader/Loader';

const OtherGroupsMembers = props => {
  const [groupPost, setGroupPost] = useState([]);
  const { userData } = useUserData();
  const [isloading, setIsloading] = useState(false);

  const userD = useSelector(state => state.auth?.userData);

  useEffect(() => {
    setIsloading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/group/messages-and-comments?groupId=${props.groupId}`,
        );
        const data = await response.data.data.data;
        setGroupPost(data);
        setIsloading(false);
      } catch (error) {
        // Handle error
        setIsloading(false);
        console.error(error);
      }
    };

    fetchData();
  }, [props.groupId]);

  var onScroll = () => {
    // const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };

  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);

  const uniqueUserData = Array.from(
    new Set(groupPost?.map(obj => obj?.sender?.firstName)),
  ).map(firstName => {
    return groupPost?.find(obj => obj?.sender?.firstName === firstName);
  });

  //   const uniqueUserData = Array.from(
  //     new Set(
  //       groupPost.map(({ sender }) => {
  //         const fullname = `${sender?.firstName}  ${sender?.lastName}`;
  //         return fullname;
  //       }),
  //     ),
  //   ).map(fullName => {
  //     return groupPost.find(fullname => fullname === fullName);
  //   });

  return (
    <div
      className=""
      style={{ paddingRight: '3rem', paddingLeft: '3rem', paddingTop: '1rem' }}
    >
      {isloading ? (
        <Loader />
      ) : (
        <div className="" style={{ position: 'relative' }}>
          {uniqueUserData.map((post, index) => {
            console.log(post);

            if (post?.creator?.username?.includes('null')) {
              return '';
            } else {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                  }}
                >
                  <img
                    src={
                      post?.creator?.profilePictureURL !== null
                        ? post?.creator?.profilePictureURL
                        : duummyAvatar
                    }
                    alt="proflie"
                    className="single-vesti-post-img"
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      marginRight: '10px',
                    }}
                  />

                  <div style={{ fontWeight: 500 }}>
                    {' '}
                    {post?.creator?.firstName} {post?.creator?.lastName}
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
export default OtherGroupsMembers;
