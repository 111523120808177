import React, { useState, useEffect } from 'react';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import './virtualcard.scss';
import { Platformbutton } from 'components/common/button/button';
import { Success } from 'components/common/success/success';
import errorsvg from '../../assets/newerror.svg';
import { useStep } from 'helpers/hooks';
import { connect, useDispatch } from 'react-redux';
import {
  createVirtualCard,
  createMoreVirtualCard,
} from 'appRedux/actions/cards';
import { fetchAllStates } from 'appRedux/actions/Common';
// import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { Newprompt } from 'components/common/prompt/prompt';
import vcardprompt from 'assets/vcardprompt.svg';
import { Numberedcheck } from 'components/common/listcheck/listcheck';
import { Shortinfonew } from 'components/common/shortinfo/shortinfo';
import {
  formatAmount,
  getCurrency,
  getCurrencyName,
  openMail,
  removeCommaAmount,
} from 'helpers/utils';
import { autoAssignProvidusAccount } from 'appRedux/actions/waitlist';
import { depositViaPays } from 'appRedux/actions/nairatrans';
import { Transreview } from 'components/common/transactionreview/review';
import api from 'appRedux/api';
import Loader from 'components/Loader';
import { Singleselect } from 'components/common/inputs/singleselect';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { Vestirate } from 'components/common/vestirate/vestirate';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { Textbal } from 'components/bank/littlebalance/littlebalance';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';

const Createmono = props => {
  // console.log('Createmono props: ', props);
  // console.log('Createmono userData: ', props.userdata);
  const dispatch = useDispatch();
  const cardCurrency = props.userdata.country === 'Ghana' ? 'GHS' : 'NGN';
  const { step, nextStep, setStep } = useStep(0);

  const [state, setState] = useState({
    amount: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    idType: '',
    idNumber: '',
    currency: cardCurrency,
  });

  const [pin, setPin] = useState();
  const [charges, setCharges] = useState(1);
  const [loading, setLoading] = useState(true);
  const [wallet, setwallet] = useState('NGN WALLET');

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        const country = props.userdata.country;

        switch (country) {
          case 'Ghana':
          case 'GH':
            setCharges(
              `$ ${res.data?.data?.data[0].virtualCardCreationChargesInGhs /
                100}`,
            );
            setwallet('GHS WALLET');
            break;
          case 'Nigeria':
          case 'NG':
            setCharges(
              `$ ${res.data?.data?.data[0].virtualCardCreationChargesInKobo /
                100}`,
            );
            setwallet('NGN WALLET');

            break;
          case 'United Kingdom':
          case 'UK':
            setCharges(
              `$ ${res.data?.data?.data[0].virtualCardCreationChargesInGbp /
                100}`,
            );
            setwallet('GBP WALLET');

            break;
          case 'Zambia':
          case 'ZMB':
            setCharges(
              `$ ${res.data?.data?.data[0].virtualCardCreationChargesInZmw /
                100}`,
            );
            setwallet('ZMW WALLET');

            break;

          default:
            setCharges(
              `$ ${res.data?.data?.data[0].virtualCardCreationChargesInKobo /
                100}`,
            );
            setwallet('NGN WALLET');
        }

        setLoading(false);
      })
      .catch(err => err);
    // eslint-disable-next-line
  }, []);

  // console.log('userData: ', props.userdata);

  useEffect(() => {
    props.userdata?.v1CardId !== null ? setStep(5) : setStep(0);
  }, [props.userdata?.v1CardId]);

  const formatNumberInput = value => {
    // Remove existing commas and convert to string
    const stringValue = value.toString().replace(/,/g, '');

    // Use a regular expression to add commas for thousands separators
    const formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  };

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'targetAmount' || name === 'amount') {
      value = formatNumberInput(value);
    }

    setState({ ...state, [name]: value });
    // console.log(state);
  };

  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  var callBack = value => {
    props.refetch();
    setStep(value);
    props.cb();
  };

  // const __renderTitle = () => {
  //   switch (step) {
  //     case 1:
  //       return 'Set Card PIN';
  //     default:
  //       return '';
  //   }
  // };

  // const __renderSubt = () => {
  //   switch (step) {
  //     case 1:
  //       return 'Input a 4-Digit PIN to complete your Vesti Virtual Card Creation';
  //     default:
  //       return '';
  //   }
  // };

  var options = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'BVN',
      value: 'BVN',
    },
    {
      label: 'NIN',
      value: 'NIN',
    },
  ];

  var ghOptions = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'SSNIT',
      value: 'SSNIT',
    },
  ];

  const nigerianStates = [
    {
      value: 'Abia',
      label: 'Abia',
    },
    {
      value: 'Abuja',
      label: 'Abuja',
    },
    {
      value: 'Adamawa',
      label: 'Adamawa',
    },
    {
      value: 'Akwa Ibom',
      label: 'Akwa Ibom',
    },
    {
      value: 'Anambra',
      label: 'Anambra',
    },
    {
      value: 'Bauchi',
      label: 'Bauchi',
    },
    {
      value: 'Bayelsa',
      label: 'Bayelsa',
    },
    {
      value: 'Benue',
      label: 'Benue',
    },
    {
      value: 'Borno',
      label: 'Borno',
    },
    {
      value: 'Cross River',
      label: 'Cross River',
    },
    {
      value: 'Delta',
      label: 'Delta',
    },
    {
      value: 'Ebonyi',
      label: 'Ebonyi',
    },
    {
      value: 'Edo',
      label: 'Edo',
    },
    {
      value: 'Ekiti',
      label: 'Ekiti',
    },
    {
      value: 'Enugu',
      label: 'Enugu',
    },
    {
      value: 'Gombe',
      label: 'Gombe',
    },
    {
      value: 'Imo',
      label: 'Imo',
    },
    {
      value: 'Jigawa',
      label: 'Jigawa',
    },
    {
      value: 'Kaduna',
      label: 'Kaduna',
    },
    {
      value: 'Kano',
      label: 'Kano',
    },
    {
      value: 'Katsina',
      label: 'Katsina',
    },
    {
      value: 'Kebbi',
      label: 'Kebbi',
    },
    {
      value: 'Kogi',
      label: 'Kogi',
    },
    {
      value: 'Kwara',
      label: 'Kwara',
    },
    {
      value: 'Lagos',
      label: 'Lagos',
    },
    {
      value: 'Nassarawa',
      label: 'Nassarawa',
    },
    {
      value: 'Niger',
      label: 'Niger',
    },
    {
      value: 'Ogun',
      label: 'Ogun',
    },
    {
      value: 'Ondo',
      label: 'Ondo',
    },
    {
      value: 'Osun',
      label: 'Osun',
    },
    {
      value: 'Oyo',
      label: 'Oyo',
    },
    {
      value: 'Plateau',
      label: 'Plateau',
    },
    {
      value: 'Rivers',
      label: 'Rivers',
    },
    {
      value: 'Sokoto',
      label: 'Sokoto',
    },
    {
      value: 'Taraba',
      label: 'Taraba',
    },
    {
      value: 'Yobe',
      label: 'Yobe',
    },
    {
      value: 'Zamfara',
      label: 'Zamfara',
    },
  ];

  const __rendersteps = () => {
    switch (step) {
      case 0:
        return (
          <Stepprompt
            continue={nextStep}
            id={props.id}
            setStep={setStep}
            rate={props.rate}
            userdata={props.userdata}
          />
        );
      case 1:
        return (
          <Stepone
            setInput={setInput}
            rate={props.rate}
            data={state}
            setState={setState}
            handleSelect={handleSelect}
            goContinue={nextStep}
            // goBack={previousStep}
            // states={props.states}
            nigerianStates={nigerianStates}
            setStep={setStep}
            step={step}
            userdata={props.userdata}
            options={options}
            ghOptions={ghOptions}
            balance={props.balance}
            cardCurrency={cardCurrency}
          />
        );

      case 2:
        return (
          <>
            <Transreview
              data={[
                { title: 'Transaction Type', value: 'Virtual Card Creation' },
                {
                  title: 'Amount',
                  value: `$${state.amount}`,
                },
                {
                  title: 'Wallet',
                  value: wallet,
                },
                {
                  title: 'Fee',
                  value: loading ? <Loader /> : charges,
                },
              ]}
            />

            <div className="mb-2"></div>
            {/* <Backcontinue goBack={() => (props.id ? setStep(1) : setStep(2))}> */}
            <Backcontinue goBack={() => setStep(1)}>
              <Platformbutton
                type="normal"
                name="Create Card"
                disabled={false}
                click={() =>
                  props.createVirtualCard(
                    {
                      ...state,
                      state: state.state?.value,
                      idType: state.idType?.value,
                      amount: parseFloat(state.amount) * 100,
                    },
                    callBack,
                  )
                }
              />
            </Backcontinue>
          </>
        );

      case 3:
        return (
          <Success
            title="Successful"
            subtitle={props.message}
            button="Okay Thank You"
            onClick={() => {
              props.refetch();
              props.closeModal();
            }}
          />
        );
      case 4:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Failed"
            subtitle={props.message}
            button="Try Again"
            onClick={() => {
              setStep(props.id ? 3 : 2);
            }}
          />
        );
      case 5:
        return (
          <StepFive
            setInput={setInput}
            rate={props.rate}
            data={state}
            setState={setState}
            handleSelect={handleSelect}
            goContinue={nextStep}
            setStep={setStep}
            step={step}
            userdata={props.userdata}
            balance={props.balance}
            cardCurrency={cardCurrency}
            closeModal={props.closeModal}
          />
        );
      case 6:
        return (
          <>
            <Transreview
              data={[
                { title: 'Transaction Type', value: 'Virtual Card Creation' },
                {
                  title: 'Amount',
                  value: `$${state.amount}`,
                },
                {
                  title: 'Wallet',
                  value: wallet,
                },
                {
                  title: 'Fee',
                  value: loading ? <Loader /> : charges,
                },
              ]}
            />

            <Comptransaction
              fetcher={fetchTransactionOTP}
              setPin={setPin}
              pin={pin}
              loading={props.loading}
              goBack={() => props.back()}
              btn={`Initiate Transaction`}
              // onFinish={() => alert('')}
              len={4}
              lower={true}
            >
              <Backcontinue goBack={() => setStep(5)}>
                <Platformbutton
                  name="Create Card"
                  type="normal"
                  disabled={!pin}
                  click={() =>
                    props.createMoreVirtualCard(
                      {
                        currency: state?.currency,
                        transactionOtp: pin,
                        amount: parseFloat(state.amount) * 100,
                      },
                      callBack,
                    )
                  }
                />
              </Backcontinue>
            </Comptransaction>
            <p style={{ textAlign: 'center' }}>
              Didn't get the code?{' '}
              <span
                style={{ cursor: 'pointer', color: 'green' }}
                onClick={() => dispatch(fetchTransactionOTP())}
              >
                Resend
              </span>
            </p>

            <div className="mb-2"></div>
            {/* <Backcontinue goBack={() => setStep(5)}>
              <Platformbutton
                type="normal"
                name="Create Card"
                disabled={props.loading}
                click={() =>
                  props.createMoreVirtualCard(
                    {
                      currency: state?.currency,
                      transactionOtp: state.state?.value,
                      amount: parseFloat(state.amount) * 100,
                    },
                    callBack,
                  )
                }
              />
            </Backcontinue> */}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="globalgeng">
      <div className="globalgeng__content">{__rendersteps()}</div>
    </div>
  );
};
// eslint-disable-next-line

const Stepprompt = props => {
  var data = [
    `Card Creation Processing would take up to 2 Minutes.`,
    'Card works only on USD billing platforms, not on other currency platforms like Pounds, CAD, AUD, etc.',
    'The card may not work on Aliexpress.',
  ];
  const [charges, setCharges] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        const country = props.userdata.country;
        // console.log(country);
        // console.log(res);
        switch (country) {
          case 'Ghana':
          case 'GH':
            setCharges(
              `$ ${res.data.data.data[0].virtualCardCreationChargesInGhs /
                100}`,
            );
            break;
          case 'Nigeria':
          case 'NG':
            setCharges(
              `$ ${res.data.data.data[0].virtualCardCreationChargesInKobo /
                100}`,
            );
            break;
          case 'United Kingdom':
          case 'UK':
            setCharges(
              `$ ${res.data.data.data[0].virtualCardCreationChargesInGbp /
                100}`,
            );
            break;
          case 'Zambia':
          case 'ZMW':
            setCharges(
              `$ ${res.data.data.data[0].virtualCardCreationChargesInZmw /
                100}`,
            );
            break;

          default:
            setCharges(
              `$ ${res.data.data.data[0].virtualCardCreationChargesInKobo /
                100}`,
            );
        }

        setLoading(false);
      })
      .catch(err => err);
  }, []);

  return (
    <Newprompt img={vcardprompt}>
      <Titlesubtitle
        title="GlobalGeng Virtual Dollar Card"
        subtitle="Things you need to know about this virtual card."
      />
      <div className="stepone__monoprompt">
        <div className="stepone__column">
          {data?.map((item, index) => (
            <Numberedcheck index={index + 1} body={item} />
          ))}
          {/* <Listcheck data={data}/> */}
          <Shortinfonew subject="You will be charged $40 for a successful credit report pull, but you wont be charged if it fails">
            <span>
              <p>
                If you ever need to convert the dollar amount in your card to
                Naira, The conversion rate back to naira may be updated from
                time to time.
              </p>
              <p>
                {' '}
                Vesti also charges a <strong>{charges}</strong> card handling
                and creation fee.{' '}
              </p>
              <p>
                {' '}
                Contact support at{' '}
                <strong onClick={() => openMail('help@wevesti.com')}>
                  help@wevesti.com
                </strong>{' '}
                if you need have any questions.{' '}
              </p>
            </span>
          </Shortinfonew>
        </div>
        <div className="mt-4"></div>
      </div>
      {/* <p className="stepone__see"> See <strong onClick={()=> openLink('https://vesti-dollar-card.notion.site/Platforms-where-Vesti-s-Virtual-Dollar-Card-Works-a225b6dac88a457a8bb7a0498351948e') }>sites where this card works</strong> </p> */}
      <Platformbutton
        type="normal"
        name="Proceed"
        click={() => {
          props.continue();
        }}
      />
      <div className="mb-2"></div>
      <p> By proceeding you agree to the above terms.</p>
    </Newprompt>
  );
};

const Stepone = props => {
  // console.log('Stepone', props);
  const [total, setTotal] = useState(null);
  const {
    address,
    state,
    city,
    idType,
    idNumber,
    amount,
    zipcode,
  } = props.data;

  const balance = props.balance;
  const initialTotal = removeCommaAmount(props.data.amount) * props.rate;

  useEffect(() => {
    initialTotal === 'NaN' || initialTotal === NaN
      ? setTotal(0)
      : setTotal(initialTotal);
  }, [initialTotal]);

  return (
    <div className="mcard" style={{ width: '100%' }}>
      <Titlesubtitle
        steps={`Step 1 of 1`}
        title="Virtual Card Creation"
        subtitle="Enter details below to create card"
      />
      <div className="mcard__column">
        <Inputfloat
          type="text"
          label="Address"
          name="address"
          placeholder="What's your address"
          value={props.data.address}
          disabled={false}
          onChange={props.setInput}
        />
        <Singleselect
          value={props.data.state}
          options={props.nigerianStates}
          onChange={value => {
            props.handleSelect('state', value);
          }}
          placeholder="Select your state"
        />
        <div className="mcard__row">
          <Inputfloat
            type="text"
            label="City"
            name="city"
            placeholder="Enter Your city"
            value={props.data.city}
            disabled={false}
            onChange={props.setInput}
          />
          <Inputfloat
            type="text"
            label="Zipcode"
            name="zipcode"
            placeholder="Enter city zipcode"
            value={props.data.zipcode}
            disabled={false}
            onChange={props.setInput}
          />
        </div>
        <div className="mcard__row">
          <Singleselect
            value={props.data.idType}
            options={
              props.userdata.country === 'Nigeria' ||
              props.userdata.country === 'NG'
                ? props.options
                : props.userdata.country === 'Ghana'
                ? props.ghOptions
                : props.options
            }
            name="idType"
            placeholder="Select your means of Identification"
            onChange={value => {
              // console.log(value.value);
              props.handleSelect('idType', value);
            }}
          />
          <Inputfloat
            type="text"
            label="ID Number"
            name="idNumber"
            placeholder="Enter the Id number"
            value={props.data.idNumber}
            disabled={false}
            onChange={props.setInput}
          />
        </div>
        {props.data.amount && (
          <Vestirate
            card="true"
            reverse={props.currency === props.cardCurrency ? false : true}
            rate={props.rate}
            currency={props.cardCurrency}
          />
        )}
        <Amountinputcurrency
          type="text"
          currency="$"
          name="amount"
          label="Amount"
          value={props.data.amount}
          disabled={false}
          placeholder="Enter amount"
          onChange={props.setInput}
          pattern="[0-9,.]*"
          autoComplete="false"
        />
        {props.data.amount && (
          <Textbal
            wallet={`${getCurrencyName(props.cardCurrency)} Wallet`}
            bal={formatAmount(balance)}
            currency={props.cardCurrency}
          />
        )}
        {/* {console.log(balance < total)} */}
        {balance < total && (
          <AlertError
            body={`You have less than ${getCurrency(
              props.cardCurrency,
            )}${props.rate *
              removeCommaAmount(
                props.data.amount,
              )} in your Vesti ${getCurrencyName(
              props.cardCurrency,
            ).toLocaleString('en-us')} wallet.`}
          />
        )}
      </div>
      <div className="backcontinue-container">
        <Backcontinue goBack={() => props.setStep(1)}>
          <Platformbutton
            type="normal"
            name="Proceed"
            disabled={
              !address ||
              !state ||
              !city ||
              !idType ||
              !amount ||
              !idNumber ||
              !zipcode ||
              props.data.amount <= 0 ||
              balance < total
                ? true
                : false
            }
            click={() => props.goContinue()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};

const StepFive = props => {
  // console.log('Stepone', props);
  const [total, setTotal] = useState(null);
  const { amount } = props.data;

  const balance = props.balance;
  const initialTotal = removeCommaAmount(props.data.amount) * props.rate;

  useEffect(() => {
    initialTotal === 'NaN' || initialTotal === NaN
      ? setTotal(0)
      : setTotal(initialTotal);
  }, [initialTotal]);

  return (
    <div className="mcard" style={{ width: '100%' }}>
      <Titlesubtitle
        steps={`Step 1 of 1`}
        title="Virtual Card Creation"
        subtitle="Enter details below to create card"
      />
      <div className="mcard__column">
        {props.data.amount && (
          <Vestirate
            card="true"
            reverse={props.currency === props.cardCurrency ? false : true}
            rate={props.rate}
            currency={props.cardCurrency}
          />
        )}
        <Amountinputcurrency
          type="text"
          currency="$"
          name="amount"
          label="Amount"
          value={props.data.amount}
          disabled={false}
          placeholder="Enter amount"
          onChange={props.setInput}
          pattern="[0-9,.]*"
          autoComplete="false"
        />
        {props.data.amount && (
          <Textbal
            wallet={`${getCurrencyName(props.cardCurrency)} Wallet`}
            bal={formatAmount(balance)}
            currency={props.cardCurrency}
          />
        )}
        {balance < total && (
          <AlertError
            body={`You have less than ${getCurrency(
              props.cardCurrency,
            )}${props.rate *
              removeCommaAmount(
                props.data.amount,
              )} in your Vesti ${getCurrencyName(
              props.cardCurrency,
            ).toLocaleString('en-us')} wallet.`}
          />
        )}
      </div>
      <div className="backcontinue-container">
        <Backcontinue goBack={() => props.closeModal()}>
          <Platformbutton
            type="normal"
            name="Proceed"
            disabled={!amount || amount <= 0 || balance < total ? true : false}
            click={() => props.setStep(6)}
          />
        </Backcontinue>
      </div>
    </div>
  );
};

const mapStateToProps = ({ cards, common, transactions, waitlist }) => {
  const { message, loading } = cards;
  const { states } = common;
  const { loading: tload } = transactions;
  const { loading: wload } = waitlist;
  return {
    message,
    loading,
    states,
    tload,
    wload,
  };
};

const mapDispatchToProps = {
  createVirtualCard,
  createMoreVirtualCard,
  fetchAllStates,
  autoAssignProvidusAccount,
  depositViaPays,
};

export default connect(mapStateToProps, mapDispatchToProps)(Createmono);
