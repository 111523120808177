import React, { useEffect, useState } from 'react';
import '../components.css';
import './header.scss';
import { connect } from 'react-redux';
import { NotifIco } from 'assets/assets';
import { userSignOut, getProfile } from '../../appRedux/actions/auth';
import { toggleMenu } from '../../appRedux/actions/menu';
// import { useUserData } from 'helpers/hooks';
import { Link } from '@reach/router';
// import Empty from "../../assets/empty-gray.svg"
import dummy from 'assets/dummy-avatar.png';
import { useSelector } from 'react-redux';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { useQuery } from 'react-query';
import { errorMessage } from 'helpers/utils';
import MiaiAvatar from 'assets/miai/Miai.svg';
import { navigate } from '@reach/router';
import messageSnd from 'assets/sound/soundsmessage.mp3';

// eslint-disable-next-line
const useFetchNots = () => {
  const { isLoading, data: userNotifications, refetch } = useQuery(
    'my-notifications',
    // {refetchOnWindowFocus: false,enabled: false},
    async () =>
      await api
        .get('notification/get_new_notifications')
        .then(res => res.data.data),
  );

  return { isLoading, userNotifications, refetch };
};

const Header = ({ toggleMenu, info, link, info2, link2 }) => {
  // const [notifications, setNots] = useState([])
  const [open, setOpen] = useState(false);

  // const {userNotifications,refetch} = useFetchNots();

  // eslint-disable-next-line
  var deleteNotification = async id => {
    try {
      const res = await api.patch(`notification/cancel_one_notification/${id}`);
      openNotificationWithIcon(
        'Notification Deleted',
        res.data.message,
        'success',
      );
      //console.log('');
      // refetch()
    } catch (error) {
      openNotificationWithIconErr(errorMessage(error), 'Error', 'error');
    }
  };
  // eslint-disable-next-line
  var clearNotification = e => {
    // alert('Hiiii')
    e.preventDefault();
    api
      .patch('notification/cancel_all_my_notifications')
      .then(res => {
        openNotificationWithIcon('Notification', res.data.message, 'success');
        // refetch()
      })
      .catch(error => {
        openNotificationWithIconErr(
          'Error clearing all notifications',
          'Error',
          'error',
        );
      });
  };

  useEffect(() => {
    getProfile();
    // getNotifications();
  }, []);

  const userData = useSelector(state => state.auth?.userData);
  const [showAlert, setShowAlert] = useState(false);

  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  useEffect(() => {
    if (userData) {
      // If userData is true, wait for 10 seconds and then show the alert
      const alertTimeout = setTimeout(() => {
        setShowAlert(true);
        if (splitLocation[1] === 'pathways') {
          setShowAlert(true);
        }
        // After 10 seconds, hide the alert
        const hideAlertTimeout = setTimeout(() => {
          setShowAlert(false);
        }, 20000);

        return () => clearTimeout(hideAlertTimeout); // Clear the inner timeout if the component unmounts
      }, 2000);

      return () => clearTimeout(alertTimeout); // Clear the outer timeout if the component unmounts
    }
  }, [userData]);

  // const { userData } = useUserData();

  // const firstName = userData?.firstName;

  // const playNotificationSound = () => {
  //   const sound = new Audio(messageSnd);
  //   sound.play();
  // };

  // if (showAlert) {
  //   playNotificationSound();
  // }

  return (
    <div className="header_box --sticky">
      {info && (
        <div className="header_box__info">
          <span>
            <p onClick={() => link && link()}>
              {info} {link && link} {info2 && ','}
            </p>
            {info2 && (
              <p onClick={() => link2 && link2()}>
                {info2} {link2 && link2}
              </p>
            )}
          </span>
        </div>
      )}
      <div className=" w-100 d-flex justify-content-between align-items-center header_box__content">
        <div
          className="curs_pointer d-flex flex-column mr-3 justify-content-between align-items-stretch toggle-menu"
          style={{
            background: 'transparent',
            height: '14px',
          }}
          onClick={toggleMenu}
        >
          <div
            style={{ width: '18px', height: '2px', background: '#67A948' }}
          />
          <div
            style={{ width: '18px', height: '2px', background: '#67A948' }}
          />
          <div
            style={{ width: '18px', height: '2px', background: '#67A948' }}
          />
        </div>

        <div className="hiname">
          <p>Hi, {userData?.firstName} 👋</p>

          <p className="hinamesub">
            Take a step closer to your new country today
          </p>
        </div>

        <div className="search_form_container flex-grow-1">
          <input
            type="search"
            name="search_admin"
            className="admin_search_control search_header"
            placeholder="🔍          Search for anything"
          />
        </div>

        {/* <button onClick={playNotificationSound}>Play Sound</button> */}

        {/* {notifications.length} */}
        <div className="header-icons ml-auto d-flex align-items-center relative">
          {showAlert && (
            <>
              <div className="alertContainer">
                <div className="alert-box">
                  <p
                    style={{
                      color: 'white',
                      fontSize: '12px',
                      fontWeight: '500',
                      textAlign: 'center',
                    }}
                  >
                    Miai ™ (Pronounced Maya)
                  </p>
                  <p
                    style={{
                      fontSize: '9px',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    Your AI migration assistant.
                  </p>
                  <div className="arrow-up"></div>
                </div>
              </div>
            </>
          )}

          <div
          // style={{ borderRadius: '50vw' }}
          // className={`notif_cont ${open ? ' active' : ''}`}
          >
            <div className="wave-container">
              <div className="center-image" onClick={() => navigate('/miai')}>
                <div
                  style={{
                    background: '#518C36',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                  }}
                >
                  <img
                    style={{ width: '100%', height: '100%' }}
                    src={MiaiAvatar}
                    alt=""
                  />
                </div>
              </div>

              <div className="wave wave1"></div>
              <div className="wave wave2"></div>
              <div className="wave wave3"></div>
            </div>

            {/* <img
              src={NotifIco}
              alt="notification icon"
              className={`notif_icon ${open ? ' active' : ''} img-fluid`}
              style={{ width: '25px', height: '25px' }}
              onClick={() => setOpen(!open)}
            /> */}

            {/* <Link to="/miai" className="notif_cont">
              <img
                src={MiaiAvatar}
                alt=""
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            </Link> */}

            {/* <div className={`notification-box ${open ? ' active' : ''}`}>
             <p>Notification(s)</p>
              { userNotifications ? userNotifications.length < 1 ? <div className="notification-box_inner">
                <img src={Empty} alt="Empty Gray"/>
                <p className="empty">Notification is empty, Nothing to show yet </p>
              </div> :<div className="notification-box_inner">{ (userNotifications ?? []).map ((item, index)=> (
                <>
                  <Notification
                    // key={index}
                    data={item}
                    deleteNotification={deleteNotification}
                  />
                  <hr/>
                </>
              ))}
              <p className="clear-not" onClick={(e)=> clearNotification(e)}>Clear All Notifications</p>
              </div> : <div className="notification-box_inner">
                <img src={Empty} alt="Empty Gray"/>
                <p className="empty">Notification is empty, Nothing to show yet </p>
              </div>}
            </div> */}
          </div>
          <Link to="/myprofile" className="notif_cont ml-3">
            <img
              src={
                userData?.profilePictureURL
                  ? userData?.profilePictureURL
                  : dummy
              }
              alt=""
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50vw',
                objectFit: 'cover',
              }}
            />
          </Link>
          {/* <div className="notif_cont ml-3">
            <img
              src={userData.profilePictureURL}
              alt=""
              style={{ width: '50px', height: '50px', borderRadius: '50vw' }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
// eslint-disable-next-line
const Notification = props => {
  return (
    <div className="singlenotification" key={props.data.id}>
      <div className="singlenotification__text">
        <p>{props.data.eventType}</p>
        <p>{props.data.title}</p>
      </div>
      <i
        className="fas fa-times"
        onClick={() => props.deleteNotification(props.data.id)}
      />
    </div>
  );
};

const mapStateToProps = ({ auth, common }) => {
  const { loader, alertMessage, showMessage } = auth;
  const { display, message, loading } = common;
  return {
    loader,
    alertMessage,
    showMessage,
    display,
    message,
    loading,
  };
};

export default connect(mapStateToProps, {
  userSignOut,
  getProfile,
  toggleMenu,
})(Header);
