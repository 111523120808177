import React, { useState, useEffect } from 'react';
import Singleinputlabel from 'components/common/inputs/singleinputlabel';
import api from 'appRedux/api';
import { connect, useSelector } from 'react-redux';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { useDispatch } from 'react-redux';
import dummyAvatar from '../../assets/dummy-avatar.png';
import './accountdetails.scss';

import {
  fetchUserData,
  updateUsername,
  updateEmail,
  updateLocation,
  updateNumber,
  sendVerificationMail,
  sendDeletionCode,
  verifyAccount,
} from 'appRedux/actions/profile';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Platformbutton } from 'components/common/button/button';
import { Simplemodal } from 'components/common/simplifiedmodal';
import Deleteaccount from './deleteaccount';
import { Success } from 'components/common/success/success';
import verify from 'assets/verify.svg';
import Inputfloat from 'components/common/inputs/inputfloat';

const Accountdetails = props => {
  // const [inputs, setInputs] = useState({});
  const [modal, showModal] = useState(false);
  const [profile, setProfile] = useState();
  const [error, setError] = useState({
    email: '',
  });
  const [btn, setButton] = useState('');

  const dispatch = useDispatch();
  // eslint-disable-next-line
  const userData = useSelector(state => state.auth?.userData);

  var setInput = e => {
    dispatch(props.updateUsername(e.target.value));
    //console.log(e.target.value);
    // setInputs({ ...inputs, [e.target.name]: [e.target.value] }, [inputs]);
  };

  var setLocation = value => {
    dispatch(props.updateLocation(value.value));
  };

  var setNumber = e => {
    dispatch(props.updateNumber(e.target.value));
  };
  const handleOpenDeleteModal = e => {
    e.preventDefault();
    showModal(true);
    // props.sendDeletionCode({email:props.userdata.email});
  };
  var setEmail = e => {
    var phoneRegex = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g); //eslint-disable-line
    if (phoneRegex.test(e.target.value)) {
      dispatch(props.updateEmail(e.target.value));
      setError({ ...error, email: '' });
    } else {
      setError({ ...error, email: 'invalid email' });
    }

    // setInputs({ ...inputs, [e.target.name]: [e.target.value] }, [inputs]);
  };

  var changeProfilePicture = event => {
    event.preventDefault();
    setProfile(
      event.currentTarget.files.length && event.currentTarget.files[0],
    );

    var formData = new FormData();
    formData.append('profilePicture', event.currentTarget.files[0]);
    api
      .post('/customer/update-profile', formData)
      .then(() => {
        //console.log(profile); // Moved the console.log to the success block
        openNotificationWithIcon(
          'Profile Picture',
          'Profile Picture Uploaded successfully',
          'success',
        );
        setProfile(''); // Reset the profile state after successful upload
      })
      .catch(({ response }) => {
        openNotificationWithIconErr(
          'Profile Picture',
          'Could not upload files',
          'error',
        );
      });
  };

  var submitForm = e => {
    e.preventDefault();
    setButton('Saving...');
    const payload = {
      fullName: props.userdata.firstName + ' ' + props.userdata.lastName,
      username: props.singleProfile.username,
      // profilePicture: props.userdata.profilePictureURL,
    };

    api
      .post('/customer/update-profile', payload)
      // .post('/customer/update-profile', props.singleProfile)

      .then(() => {
        openNotificationWithIcon(
          `You have successfully updated your profile`,
          'Profile Update',
          'success',
        );
        setButton('');
      })

      .catch(({ response }) => {
        openNotificationWithIconErr(
          'Profile Update',
          'Could not upload profile',
          'error',
        );
        setButton('');
      });
  };

  return (
    <div className="accountdetails">
      <Simplemodal open={modal} onCancel={() => showModal(false)}>
        <Deleteaccount
          handleDelete={() => {}}
          userdata={props.userdata}
          refetch={props.refetch}
          closeModal={() => showModal(false)}
        />
      </Simplemodal>
      <div className="accountdetails__inner">
        {props.userdata.emailVerified ? (
          <>
            <div className="accountdetails__picture">
              <img
                src={
                  props.userdata.profilePictureURL !== null
                    ? props.userdata.profilePictureURL
                    : dummyAvatar
                }
                alt="myprofile"
              />
              <input
                type="file"
                id="actual-btn"
                accept=".jpg, .jpeg, .png .JPG, .JPEG"
                onChange={e => changeProfilePicture(e)}
                hidden
              />
              <label htmlFor="actual-btn">Edit</label>
            </div>
            <form
              onSubmit={e => submitForm(e)}
              className="accountdetails__form"
            >
              <Inputfloat
                type="text"
                label="Username"
                name="username"
                value={props.singleProfile.username}
                placeholder={
                  props.userdata.username
                    ? props.userdata.username
                    : 'Edit your username'
                }
                disabled={false}
                onChange={setInput}
              />

              <Inputfloat
                type="text"
                label="Full Name"
                name="fullname"
                value={props.userdata.firstName + ' ' + props.userdata.lastName}
                disabled={true}
                onChange={setInput}
              />

              <Inputfloat
                type="text"
                label="Email"
                name="email"
                placeholder="Edit your Email address"
                value={props.userdata.email}
                disabled={true}
                onChange={setEmail}
                error={error.email}
              />
              <Inputfloat
                type="text"
                label="Phone Number"
                name="pnumber"
                placeholder="Edit your Phone number"
                value={props.userdata.phoneNumber}
                disabled={true}
                onChange={setNumber}
              />

              <Inputfloat
                type="text"
                label="Location"
                name="location"
                placeholder="Country"
                value={props.userdata.country || props.userdata.location}
                disabled={true}
                onChange={setLocation}
              />

              <span className="mb-4"></span>
              <div className="referal">
                {props.userdata.emailVerified === false && (
                  <Platformbutton
                    type="normal"
                    name="Verify Account"
                    disabled={props.loading ? true : false}
                    click={() => props.sendVerificationMail(props.userdata.id)}
                  />
                )}
              </div>
              <div className="action-buttons">
                <button
                  className="delete-account"
                  onClick={e => handleOpenDeleteModal(e)}
                >
                  Delete Account
                </button>
                <button
                  className="save-changes"
                  disabled={error.email.length > 1 ? true : false}
                >
                  {btn !== '' ? btn : 'Save Changes'}
                </button>
              </div>
            </form>
          </>
        ) : (
          <Success
            image={verify}
            title="Verify Account"
            subtitle="In order to ensure the security of your account, we need to verify your account, click the button below to start."
            button="Verify Account"
            onClick={() =>
              props.verifyAccount({
                id: props.userdata.id,
                email: props.userdata.email,
              })
            }
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ profile }) => {
  const { singleProfile, loading } = profile;
  return {
    singleProfile,
    profile,
    loading,
  };
};

export default connect(mapStateToProps, {
  fetchUserData,
  updateUsername,
  updateEmail,
  updateLocation,
  updateNumber,
  sendVerificationMail,
  sendDeletionCode,
  verifyAccount,
})(Accountdetails);
