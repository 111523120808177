import {
  OPEN_ABOUT,
  OPEN_FEEDS,
  OPEN_MEMBERS,
  OPEN_EVENTS,
  FETCH_ALL_GROUPS,
} from 'appRedux/constants';

const INIT_STATE = {
  about: true,
  feedss: false,
  members: false,
  events: false,
  groups: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_ABOUT: {
      return { feedss: false, members: false, events: false, about: true };
    }
    case OPEN_FEEDS: {
      return { feedss: true, members: false, events: false, about: false };
    }

    case OPEN_MEMBERS: {
      return { feedss: false, members: true, events: false, about: false };
    }

    case OPEN_EVENTS: {
      return { feedss: false, members: false, events: true, about: false };
    }
    case FETCH_ALL_GROUPS: {
      return { ...state, groups: action.payload };
    }

    default:
      return state;
  }
};
