/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import RouteComponent from './routes/RouteComponent';

import './App.css';
import PageLayout from './containers/PageLayout/PageLayout';
import configureStore from './appRedux/store';
import { QueryClient, QueryClientProvider } from 'react-query';

import ReactGA from 'react-ga';
// import { FloatButton } from 'antd';
import IframeComponent from 'components/website/Iframe.mia';
import FloatingButton from 'components/common/floatingbutton/FloatingButton';

export const store = configureStore();
const queryClient = new QueryClient();
ReactGA.initialize('G-CY10JKD1J3');
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  const [showIframe, setShowIframe] = useState(false);

  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  return (
    <>
      {/* {splitLocation[1] === 'miai' ? (
        <></>
      ) : (
        <FloatingButton
          shape="circle"
          type="default"
          title="Miai (beta)"
          onClick={() => setShowIframe(true)}
        />
      )} */}

      {showIframe && (
        <div className="popup">
          {<IframeComponent onClick={() => setShowIframe(false)} />}
        </div>
      )}

      <QueryClientProvider client={queryClient}>
        <PageLayout>
          <RouteComponent />
        </PageLayout>
      </QueryClientProvider>
    </>
  );
}

export default App;
