import React, { useEffect, useState } from 'react';
import Layout from 'components/common/DashboardLayout';
import GroupPathway from './groupPathway';
import FeedsChat from './feedsChat';
import OtherGroupIndex from './OtherGroups';
import { Link } from '@reach/router';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { BiSolidMessageRoundedDots } from 'react-icons/bi';
import './index.css';
import api from 'appRedux/api';

const NewDashboard = () => {
  // { path: '/feeds/groups', component: OtherGroupIndex, auth: true },

  var myReminder = localStorage.getItem('memberReminder');
  const [isactive, setIsActive] = useState(false);

  const [plantype, setPlanType] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/pathway/plan-and-join`);
        const data = await response.data.data.plan;
        setIsActive(data?.active);
        setPlanType(data?.type);
        console.log(data);
        // console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <section
        className=" feeds-dashboard bg-white "
        style={{ height: '100vh' }}
      >
        <div
          className="isw-container"
          style={{ width: '100%', overflow: 'scroll' }}
        >
          {/* <Link to='/feeds/groups'  onClick={() => {}}>
          Other Groups
          </Link> */}
          {/* <ul>
            <li onClick={handlegeneralGroup}>General Groups</li>
            <li onClick={handleotherGroups}>Other Groups</li>
          </ul> */}

          <div className="d-flex w-full">
            <div className="myGroupfeedsContainerAstn">
              <GroupPathway />
              {/* {generalGroup &&  <GroupPathway/>}
             {otherGroups && <OtherGroupIndex/>} */}
            </div>
            <div className="myChatfeedsContainerAstn">
              <FeedsChat isactive={isactive} plantype={plantype} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NewDashboard;
