import React, { useEffect } from 'react';
import { Ebone } from '../EB-1Page/eboneNiw';
import { Oonevisa } from '../0-1Page/oIVisa';
import { navigate } from '@reach/router';
import { H1b } from '../H1-BPage/h1b';
import { EB2Niw } from '../EB-2NIWPage/ebtwoniw';
import { fetchUserData } from 'appRedux/actions/profile';

export const MainVisaPage = () => {
  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  useEffect(() => {
    if (splitLocation[2] === ':visaType') {
      navigate('/pathways/visa');
    }
  }, []);

  switch (splitLocation[2]) {
    case '0-1visa':
      return <Oonevisa />;
    case 'EB-1visa':
      return <Ebone />;
    case 'EB-2NIWvisa':
      return <EB2Niw />;
    case 'H1Bvisa':
      return <H1b />;
    case 'B-1B-2visa':
      return <Oonevisa />;
    case 'F1visa':
      return <Oonevisa />;
    default:
      return <Oonevisa />;
  }
};
