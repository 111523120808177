import React, { useState } from 'react';
import Layout from 'components/common/DashboardLayout';
import { connect, useDispatch, useSelector } from 'react-redux';
import api from 'appRedux/api';
import '../Cash/carddetails/carddetails.scss';
import { useRates } from 'helpers/hooks';
import { useQuery } from 'react-query';
import { Cards } from 'components/virtualcards/cards';
import {
  Cardbottom,
  Carddetails,
  MonoCarddetails,
} from 'components/virtualcards/carddetails';
import {
  activateDeactivateCard,
  changeCardPin,
  deleteCard,
  freezeUnfreezeCard,
} from 'appRedux/actions/cards';
import { closeVirtual, openVirtual } from 'appRedux/actions/domore';
import { openUpdateBox } from 'appRedux/actions/update';
import { ChangePin, Freezecard } from 'components/virtualcards/cardactions';
import Loader from 'components/Loader';
import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';
// import Cardstranstable from 'components/virtualcards/cardstranstable';
import Fundcard from '../Cash/carddetails/fundcard';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { Cardlimit } from '../Cash/carddetails/cardlimit';
import larrow from 'assets/larrow.svg';
import rarrow from 'assets/rarrow.svg';
import { Empty } from 'components/common/empty/empty';
import VCardsTransactions from './cardsTransactions.js';
import './slider.css';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from '@reach/router';
import _ from 'lodash';
import { FundCardTour } from 'TourGuide/CardTourGuide/Fundcard/Fund';
import { TourGuidemodal } from 'components/common/simplifiedmodal';

// import { Platformbutton } from 'components/common/button/button';

const useFetchCards = userData => {
  const { isLoading, data: userCards, refetch } = useQuery(
    'Fetch Cards',
    // {refetchOnWindowFocus: false,enabled: false},
    async () =>
      await api
        .get(`/card/vc/all`)
        .then(res => {
          // return res.data?.data?.datacardDetails
          return res.data?.data?.data
            .sort(
              (a, b) =>
                new Date(b.createdAt.split(' ')[0]) -
                new Date(a.createdAt.split(' ')[0]),
            )
            .filter(
              item =>
                item.providerName !== 'Juice' && item.providerName !== 'Stripe',
            );
        })
        .catch(() => []),
  );

  return { isLoading, userCards, refetch };
};

const CardsFullDetails = props => {
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  const [active, setActive] = useState(0);
  const [limitmodal, setLimit] = useState({ name: '', value: false });
  const [pinmodal, setpinModal] = useState(false);
  // eslint-disable-next-line
  const [openorder, setOpen] = useState(false);
  const [action, setAction] = useState(false);

  const IsCardTrue = useSelector(state => state.Tourguide.openCardTourModal);
  const [CardTour, setCardTour] = useState(IsCardTrue);

  const [modal, setModal] = useState({
    value: false,
    currency: '',
    action: 'liquidate',
  });
  const userData = props.userData;
  // console.log('CardsFullDetails: ', userData);
  const { pricesData } = useRates();
  const { isLoading, userCards, refetch } = useFetchCards(userData);
  // console.log('CardsFullDetails userCards: ', userCards);
  // console.log('CardsFullDetails pricesData: ', pricesData);

  const dispatch = useDispatch();
  const [change, setChange] = useState({
    password: '',
    cardId: userCards ? userCards[active]?.cardId : '',
    newCardPin: '',
  });
  var openModal = value => {
    value
      ? setModal({ ...modal, value: true, currency: value })
      : setModal({ ...modal, value: true });
  };
  var closeModal = () => {
    setModal({ ...modal, value: false, currency: '' });
  };

  var spinCardModal = () => {
    userData.verifiedKyc === 'APPROVED' || userData.verifiedKyc === true
      ? dispatch(openVirtual('mono'))
      : dispatch(openUpdateBox());
  };

  return (
    <Layout>
      <TourGuidemodal onClick={() => setCardTour(false)} open={CardTour}>
        <FundCardTour />
      </TourGuidemodal>
      {isLoading ? (
        <Loader />
      ) : userCards?.length < 1 ? (
        <div className="mycard__empty">
          <Empty
            title="No Card"
            subtitle="You are yet to create a Card, click the button below to start process."
            click={() => spinCardModal()}
            // click={() => alert("Hello, I'm up")}
            name="Create A Card"
            type="normal"
          />
        </div>
      ) : (
        <section className="w-full p-3">
          <Simplemodal onClick={() => closeModal()} open={modal.value}>
            <Fundcard
              name={userCards[active].providerName}
              cardId={userCards[active].cardId}
              cardStatus={userCards[active].cardStatus}
              closeModal={closeModal}
              balance={parseFloat(userCards[active].balance)}
              cb={() => userCards[active] && refetch()}
              // cb={() => userCards[active].providerName === 'Mono' && refetch()}
              rate={pricesData}
              action={modal.action}
              wallet={allWallet}
              currency={modal.currency}
            />
          </Simplemodal>

          <Simplemodal onClick={() => setpinModal(false)} open={pinmodal}>
            <ChangePin
              data={change}
              currency={modal.currency}
              setField={e =>
                setChange({ ...change, [e.target.name]: e.target.value })
              }
              setPinfield={value => setChange({ ...change, newCardPin: value })}
              changecardPin={props.changeCardPin}
              closepinModal={() => setpinModal(false)}
              btn={''}
              name={userCards[active].providerName}
              loading={props.cardLoading}
              message={props.message}
            />
          </Simplemodal>

          <Simplemodal
            onClick={() =>
              setLimit({ ...limitmodal, name: '', value: !limitmodal.value })
            }
            open={limitmodal.value}
          >
            {limitmodal.name === 'limit' ? (
              <Cardlimit
                name={userCards[active].providerName}
                closeModal={() =>
                  setLimit({
                    ...limitmodal,
                    name: '',
                    value: !limitmodal.value,
                  })
                }
              />
            ) : (
              <Freezecard
                rate={pricesData?.MONO_LIQUIDATION_RATE / 100}
                balance={parseFloat(userCards[active].pcustomerBalance / 100)}
                cancel={() =>
                  setLimit({
                    ...limitmodal,
                    name: '',
                    value: !limitmodal.value,
                  })
                }
                freeze={props.freezeUnfreezeCard}
                delete={props.deleteCard}
              />
            )}
          </Simplemodal>

          <div className="">
            <div className="">
              <Link
                to="/cards"
                style={{
                  color: '#66A848',
                  fontSize: '24px',
                  marginBottom: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <FaArrowLeft />
                Go back
              </Link>
              <div
                className="cardsDetailP flex flex-col xl:flex-row justify-between"
                style={{ marginBottom: '4rem' }}
              >
                <div className="cardsDetailP-inner" style={{ width: '40%' }}>
                  <div
                    className="flex w-full"
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '27rem',
                    }}
                  >
                    <Smalltitle title="My Card(s)" />
                    <div className="flex items-center ">
                      <img
                        style={{
                          width: '4rem',
                          height: '4rem',
                          marginRight: '-1rem',
                        }}
                        src={larrow}
                        alt="left"
                        onClick={() =>
                          active === 0 ? setActive(0) : setActive(active - 1)
                        }
                      />

                      <img
                        style={{ width: '4rem', height: '4rem' }}
                        src={rarrow}
                        alt="reft"
                        onClick={() =>
                          active === userCards.length - 1
                            ? setActive(0)
                            : setActive(active + 1)
                        }
                      />
                    </div>
                  </div>

                  <div
                    className="w-full flex"
                    style={{
                      height: '23rem',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                    }}
                  >
                    <Cards
                      cdata={userCards}
                      userData={userData}
                      active={active}
                      setActive={setActive}
                    />
                    <div>
                      <Cardbottom
                        action={action}
                        setAction={setAction}
                        setpinModal={setpinModal}
                        setLimit={setLimit}
                        limitmodal={limitmodal}
                        setOpen={setOpen}
                        openModal={openModal}
                        FreezeUnfreeze={() =>
                          props.deleteCard(
                            userCards[active].cardId,
                            null,
                            refetch,
                          )
                        }
                        data={userCards[active]}
                        cardStatus={userCards[active].cardStatus}
                        activateDeactivate={() =>
                          activateDeactivateCard(
                            userCards[active].cardId,
                            refetch,
                          )
                        }
                        cb={refetch}
                      />
                    </div>
                  </div>
                </div>

                <div className=" cardMonoDetailsA">
                  <Smalltitle title="Card detail" />
                  <div className=" w-full">
                    {userCards[active]?.providerName === 'Mono' ? (
                      <MonoCarddetails
                        data={{
                          ...userCards[active],
                          cardStatus:
                            userCards[active].cardStatus === null
                              ? 'active'
                              : userCards[active].cardStatus,
                        }}
                      />
                    ) : (
                      <Carddetails
                        data={userCards[active]}
                        userdata={userData}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <VCardsTransactions id={userCards[active]?.cardId} />
          </div>
        </section>
      )}
    </Layout>
  );
};

const mapStateToProps = ({ auth, cards, domore }) => {
  const { userData } = auth;
  const { message, loading, cardLoading } = cards;
  const { openVirtual } = domore;
  return {
    message,
    loading,
    userData,
    cardLoading,
    openVirtual,
  };
};
const mapDispatchToProps = {
  changeCardPin,
  freezeUnfreezeCard,
  deleteCard,
  closeVirtual,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsFullDetails);
