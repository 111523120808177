import React from 'react';
import checkIcon from 'assets/viasForm/circle-check.svg';
import { FaCircle } from 'react-icons/fa6';
import './visatype.scss';

const Items = [
  { title: 'Resume & Credentials' },
  { title: 'Evidence of Employment with Filing Company (Your Company)' },
  { title: 'Employment in Critical Capacity' },
  { title: 'Recommendation Letters' },
  { title: 'Scholarly Articles, or Evidence of Authorship' },
  { title: 'Evidence of Judging the work of others' },
  { title: 'Awards and Award Nominations' },
  { title: 'Memberships' },
  { title: 'Newspaper Publications' },
  { title: 'Evidence of High Salary' },
  { title: 'Itinerary' },
  { title: 'Immigration Documents' },
];

export const Note = props => {
  if (props.submitted) {
    return (
      <div className="VisaFormMainPage__quickreadSubsection__sub__article">
        <div
        // className="VisarequirementsArticle"
        >
          <p
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#14290A',
            }}
          >
            Completing your payment qualifies you for the following:{' '}
          </p>
        </div>

        <ul
          style={{
            color: '#2B5219',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            fontSize: '14px',
            marginTop: '1rem',
          }}
        >
          <li
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
            Real time updates on the progress of your documents review.
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
            You will get the result of your document review which includes
            expert advice on how you can improve your submission.
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
            You will get quick resolution for any issues you might have
            encountered during the course of your documents submission.{' '}
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
            You will be automatically added to a 6-month membership subscription
            plan. This give you access to groups and pathways relating to the
            Visa type documents that you've uploaded.
          </li>
        </ul>

        <p style={{ color: '#518C36', fontWeight: '600', fontSize: '14px' }}>
          For more clarification reach out to info@wevesti.com.
        </p>
      </div>
    );
  } else {
    return (
      <div className="VisaFormMainPage__quickreadSubsection__sub__article">
        <div
        // className="VisarequirementsArticle"
        >
          <p
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: '#14290A',
            }}
          >
            Before you proceed you need to take notice of the following things:
          </p>
        </div>

        <ul
          style={{
            color: '#2B5219',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            fontSize: '14px',
            marginTop: '1rem',
          }}
        >
          <li
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" />
            This form is strictly for people applying for an {props.title}. Do
            not proceed if you are not applying for an {props.title} petition.
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" />
            Screenshots and photographs of documents are generally not
            admissible by USCIS. Please upload very clear scanned copies of all
            documents.
          </li>

          {/* <>
            <li
              style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'flex-start',
              }}
            >
              <img src={checkIcon} alt="" />
              This form is comprised of 12 different sections which include:
            </li>
          </> */}
        </ul>

        {/* <ul style={{ paddingLeft: '20px' }}>
          {Items.map(({ title }) => {
            return (
              <li
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '4px',
                  color: '#2B5219',
                  fontSize: '13px',
                  fontWeight: '600',
                }}
              >
                <FaCircle
                  style={{
                    background: '#98A2B3',
                    fontSize: '6px',
                    borderRadius: '100%',
                    marginTop: '6px',
                  }}
                />

                <p>{title}</p>
              </li>
            );
          })}
        </ul> */}

        <p style={{ color: '#518C36', fontWeight: '500' }}>
          NB: You can save your progress and continue uploading your files at
          your leisure before the deadline.
          <br />
          <span style={{ fontWeight: '600' }}>
            This visa covers your immediate dependants. A vesti team member will
            reach out after you have submitted your documents for more
            clarification.
          </span>
        </p>
      </div>
    );
  }
};

export const H1bNote = props => {
  return (
    <div
      className="VisaFormMainPage__quickreadSubsection__sub__article"
      style={{ backgroundColor: '#F7FFF3' }}
    >
      <div>
        <p
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#14290A',
          }}
        >
          Before you proceed you need to take notice of the following things:
        </p>
      </div>

      <ul
        style={{
          color: '#2B5219',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          fontSize: '14px',
          marginTop: '1rem',
        }}
      >
        <li
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />A Bachelor
          Degree/HND is mandatory to complete registration and enter the
          lottery.{' '}
        </li>

        <li
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
          Real time updates on the progress of your documents review.
        </li>

        <li
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
          This is the first stage of your H1-B Lottery registration. This is not
          an application for the H1-B Visa.
        </li>

        <li
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
          Please enter your details as written on your International passport.
        </li>

        <li
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
          If you do not complete payment, you will not be entered into the
          lottery.
        </li>

        <li
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
          None of the listed employers are liable for any errors in your
          registration.
        </li>

        <li
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
          Communication for this process is only through Vesti's official email
          only which is talentvisa@wevesti.com.
        </li>

        <li
          style={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
          }}
        >
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
          You will be able watch a clarity session once your payment is
          completed.
        </li>
      </ul>

      <div
        style={{
          color: '#518C36',
          fontWeight: '600',
          fontSize: '14px',
          marginTop: '1rem',
        }}
      >
        For support:
        <ul
          style={{
            color: '#2B5219',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            fontSize: '14px',
            marginTop: '1rem',
          }}
        >
          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
            Email talentvisa@wevesti.com.
          </li>

          <li
            style={{
              display: 'flex',
              gap: '5px',
              alignItems: 'flex-start',
            }}
          >
            <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />
            <p>
              Chat with H1B registration support on WhatsApp.{' '}
              <a href="https://Wa.me/+19453450043" target="_blanck">
                Wa.me/+19453450043
              </a>
            </p>
          </li>
        </ul>
        {/* <p style={{ color: '#2B5219' }}></p>
        <p style={{ color: '#2B5219' }}>
         
        </p> */}
      </div>
    </div>
  );
};

export const Stage2H1bNote = props => {
  return (
    <div
      className="VisaFormMainPage__quickreadSubsection__sub__article"
      style={{ backgroundColor: '#F7FFF3' }}
    >
      {' '}
      <div>
        {' '}
        <p style={{ fontSize: '16px', fontWeight: '600', color: '#14290A' }}>
          {' '}
          Before you proceed you need to take notice of the following things:{' '}
        </p>{' '}
      </div>
      <ul
        style={{
          color: '#2B5219',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          fontSize: '14px',
          marginTop: '1rem',
        }}
      >
        {' '}
        <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
          {' '}
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} /> This form
          is for those who have paid for their H1B Petition filing.{' '}
        </li>
        <li style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
          {' '}
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} /> If you are
          here by mistake, please close this form immediately.{' '}
        </li>
        <li style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
          {' '}
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} /> Do NOT
          share this form with anyone.{' '}
        </li>
        <li style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
          {' '}
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} /> If you
          fill this form without completing payment, we will not file a Petition
          for you.{' '}
        </li>
        <li style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
          {' '}
          <img src={checkIcon} alt="" style={{ marginTop: '6px' }} />{' '}
          <li>
            {' '}
            This form is comprised of 2 sections which include: <br /> <br />{' '}
            <span className="flex gap-2 items-center">
              {' '}
              <FaCircle className="" style={{ fontSize: '8px' }} /> Personal
              Information Section{' '}
            </span>{' '}
            <span
              className="flex gap-2 items-center"
              style={{ marginTop: '2px' }}
            >
              {' '}
              <FaCircle className="" style={{ fontSize: '8px' }} /> File Upload
              Section{' '}
            </span>{' '}
          </li>{' '}
        </li>{' '}
      </ul>
      <div
        style={{
          color: '#518C36',
          fontWeight: '600',
          fontSize: '14px',
          marginTop: '1rem',
        }}
      >
        {' '}
        For support:{' '}
        <ul
          style={{
            color: '#2B5219',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            fontSize: '14px',
            marginTop: '1rem',
          }}
        >
          {' '}
          <li style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
            {' '}
            <img src={checkIcon} alt="" style={{ marginTop: '6px' }} /> Email
            aanu@wevesti.com.{' '}
          </li>{' '}
        </ul>{' '}
        <p style={{ color: '#2B5219' }}>
          {' '}
          NB: You can save your progress and continue filling the form at your
          leisure before the deadline.{' '}
        </p>{' '}
      </div>{' '}
    </div>
  );
};
