import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../Admin.css';
// import { navigate } from '@reach/router';
import { useState, useRef } from 'react';
import duummyAvatar from '../../../assets/dummy-avatar.png';

// eslint-disable-next-line
const GroupMembers = ({ onDataLengthChange }) => {
  const posts = useSelector(state => state.posts.data);
// eslint-disable-next-line
  var onScroll = () => {
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };
// eslint-disable-next-line
  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);

  // var myReminder = localStorage.getItem('memberReminder');

  // console.log(posts);

  const uniqueUserData = Array.from(
    new Set(posts.map(obj => obj?.creator?.username)),
  ).map(username => {
    return posts.find(obj => obj?.creator?.username === username);
  });

  return (
    <div
      className=""
      style={{ paddingRight: '3rem', paddingLeft: '3rem', paddingTop: '1rem' }}
    >
      <div className="" style={{ position: 'relative' }}>
        {uniqueUserData.map((post, index) => {
          // console.log(post);

          if (post?.creator?.username?.includes('null')) {
            return '';
          } else {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.5rem',
                }}
              >
                <img
                  src={
                    post?.creator?.profilePictureURL !== null
                      ? post?.creator?.profilePictureURL
                      : duummyAvatar
                  }
                  alt="proflie"
                  className="single-vesti-post-img"
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    marginRight: '10px',
                  }}
                />

                <div style={{ fontWeight: 500 }}>
                  {' '}
                  {post?.creator?.firstName} {post?.creator?.lastName}
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default GroupMembers;
