import React, { useEffect, useState } from 'react';
import '../billPayment.scss';
import '../../Admin.css';
import '../Electricity/ElectPayment.scss';
import { Success } from 'components/common/success/success';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import Loader from 'components/Loader';
// import { useQuery } from 'react-query';
import { Simplemodal } from 'components/common/simplifiedmodal';
import api from 'appRedux/api';
import SingleBiller from '../SingleBiller';
import newerror from '../../../../assets/newerror.svg';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import telephone from '../../../../assets/telephone.svg';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { removeCommaAmount } from 'helpers/utils';
import { Transreview } from 'components/common/transactionreview/review';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { fetchTransactionOTP } from 'appRedux/actions/wallets';

const AirtimeMain = props => {
  const [open, setOpen] = useState(false);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  const balance = userData?.walletInNGNKobo;

  return (
    <>
      <Simplemodal onClick={() => setOpen(false)} open={open}>
        <AirtimePayment
          onClick={() => setOpen(false)}
          airtimeData={props.airtimeData}
          setAirtimeData={props.setAirtimeData}
          balance={balance}
        />
      </Simplemodal>
      <SingleBiller
        title="Airtime"
        image={telephone}
        color="#FFF1E9"
        onClick={() => setOpen(true)}
        // onClick={() => navigate('/buy-airtime')}
      />
    </>
  );
};

export default AirtimeMain;

export const AirtimePayment = props => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [airtimeData, setAirtimeData] = useState([]);
  const [message, setMessage] = useState('');
  const [airtimeDetails, setAirtimeDetails] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);

  const [form, setForm] = useState({
    type: '',
    amount: '',
    billingNumber: '',
    transactionPin: '',
  });

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const validate = async type => {
    const data = {
      billCode: type.billCode,
      itemCode: type.itemCode,
      billingNumber: form.billingNumber,
    };
    // console.log(data);
    setIsLoading(true);
    await api
      .post(`/bill-payment/validate`, data)
      .then(res => {
        setIsLoading(false);
        // console.log(res);
        // console.log(res.data.data.message);
        res.data?.status === 'error'
          ? openNotificationWithIconErr(
              `Airtime Purchase`,
              `${res.data?.message}, Please Input a valid Phone Number`,
              'error',
            )
          : setAirtimeDetails(res.data?.data);
        // console.log(airtimeDetails);
      })
      .catch(error => {
        setIsLoading(false);
        // console.log(error);
        setMessage(error.data?.message);
        openNotificationWithIconErr(
          `Airtime Purchase`,
          `${error.data?.message}`,
          'error',
        );
        // setStep(4);
      });
  };

  const getAirtimeData = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/bill-payment/categories?country=${selectedCurrency.value}`,
      );
      setIsLoading(false);
      const filteredData = res.data?.data;
      setAirtimeData(filteredData);

      // console.log('Filtered Data for Nigeria:', filteredData);
    } catch (error) {
      // console.error('API Error:', error);

      setMessage(error.response?.data?.message);

      if (error.response) {
        openNotificationWithIconErr(
          'Airtime Purchase',
          error.response.data.message,
          'error',
        );
      } else {
        openNotificationWithIconErr(
          'Airtime Purchase',
          'Network Error, Please try again',
          'error',
        );
      }
    }
  };

  useEffect(() => {
    if (selectedCurrency) {
      getAirtimeData();
    }
    // eslint-disable-next-line
  }, [selectedCurrency]);

  useEffect(() => {
    let isMounted = true;
    if (form.billingNumber.length === 11 && isMounted) {
      validate(form.type);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.billingNumber.length, form.type]);

  // check this part of the code

  const generateUniqueOptions = data => {
    const uniqueOptions = [];
    const seenNames = new Set();

    if (data) {
      for (const item of data) {
        const name = item.label.toUpperCase();

        if (!seenNames.has(name)) {
          seenNames.add(name);
          uniqueOptions.push(item);
        }
      }
    }

    return uniqueOptions;
  };

  var Options =
    airtimeData.length > 0
      ? (airtimeData?.filter(item => item.name.includes('VTU')) ?? []).map(
          item => ({
            value: item.amount,
            label: item.name.split(' ')[0],
            type: item.biller_name,
            itemCode: item.item_code,
            billCode: item.biller_code,
            labelName: item.label_name,
            fee: item.fee,
            country: item.country,
            // fee: item.fee === 0 ? 100 : item.fee,
          }),
        )
      : [];
  // console.log('Airtime Options:', Options);

  const uniqueOptions = generateUniqueOptions(Options);

  var handleChange = value => {
    // console.log(value);
    setForm({ ...form, type: value });
  };

  const payBill = async () => {
    setIsLoading(true);
    const data = {
      country: form.type.country,
      // amountInKoboCent: form.amount * 100,
      currency: selectedCurrency.value,
      recurrence: 'ONCE',
      // chargesInKobo: form.type.fee * 100,
      type: form.type.type,
      billingNumber: form.billingNumber,
      transactionOtp: form.transactionPin,
      category: 'airtime',
    };
    // console.log(data);
    await api
      .post(`/bill-payment/initiate`, data)
      .then(res => {
        setIsLoading(false);
        setMessage(res.data.message.toLowerCase());
        openNotificationWithIcon(
          `Airtime Purchase`,
          `Airtime Purchase ${res.data.message.toLowerCase()}`,
          'success',
        );
        setStep(3);
        // console.log(res);
      })
      .catch(error => {
        setIsLoading(false);
        // console.log(error)
        if (error.data) {
          // Handle specific error from the server
          setMessage(
            'Service temporarily not available on web, please try mobile',
          );
          openNotificationWithIconErr(
            `Airtime Purchase`,
            `Service temporarily not available on web, please try mobile`,
            'success',
          );
          // setMessage(error.data?.message);
          // openNotificationWithIconErr(
          //   `Airtime Purchase`,
          //   `${error.data?.message}`,
          //   'error',
          // );
          setStep(4);
        } else {
          // Handle generic network error
          setMessage('Network Error');
          openNotificationWithIconErr(
            `Airtime  Purchase`,
            `Network Error`,
            'error',
          );
        }
      });
  };

  switch (step) {
    case 0:
      return (
        <section className="w-full md:w-[400px] lg:w-[450px]">
          <Titlesubtitle
            title="Airtime •1 of 3"
            subtitle="Choose a wallet to debit from"
          />
          <span className="my-[20px]"></span>
          <Singleselect
            value={selectedCurrency}
            options={allWallet
              .filter(
                item => item.currency === 'NGN' || item.currency === 'GHS',
              )
              .map(item => ({
                value: item.currency,
                label: item.name.toLocaleUpperCase(),
                image: item.currencyFlag,
                balance: item.balance,
              }))}
            placeholder="Select Wallet to debit"
            onChange={value => setSelectedCurrency(value)}
            components={{ SingleValue: IconSingleValue, Option: IconOption }}
          />
          <p className="my-12 h-[20px]"></p>
          <Platformbutton
            name={
              selectedCurrency
                ? ` Proceed with ${selectedCurrency.label}  `
                : 'Select'
            }
            type="normal"
            disabled={!selectedCurrency ? true : false}
            click={() => setStep(1)}
          />
        </section>
      );
    case 1:
      return (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="elect-payment-cont">
              <Titlesubtitle
                title="Airtime •2 of 3"
                subtitle="Fill in the fields below to get your airtime without hassles."
              />

              <Myform
                form={form}
                step={step}
                setAirtimeData={setAirtimeData}
                airtimeData={airtimeData}
                // Options={selectedCurrency === 'NGN' ? Options : ghOptions}
                Options={uniqueOptions}
                handleChange={handleChange}
                validate={validate}
                setForm={setForm}
                setStep={setStep}
                setInput={setInput}
                airtimeDetails={airtimeDetails}
                setAirtimeDetails={setAirtimeDetails}
                balance={props.balance}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                allWallet={allWallet}
              />
            </div>
          )}
        </>
      );
    case 2:
      return (
        <div className="elect-payment-cont">
          <Finalize
            form={form}
            setStep={setStep}
            step={step}
            setForm={setForm}
            transactionpin={form.transactionPin}
            payBill={payBill}
            airtimeDetails={airtimeDetails}
            setAirtimeDetails={setAirtimeDetails}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            dispatch={dispatch}
          />
        </div>
      );

    case 3:
      return (
        <Success
          title="Success"
          subtitle={`Airtime purchase ${message}`}
          button="Close!"
          onClick={props.onClick}
        />
      );
    case 4:
      return (
        <Success
          image={newerror}
          type="error"
          title="Error"
          subtitle={message}
          button="Try again!"
          // onClick={props.onClick}
          onClick={() => setStep(2)}
        />
      );
    default:
      return <p>Error</p>;
  }
};

const Myform = props => {
  return (
    <>
      <form className="elect-payment">
        <Singleselect
          placeholder="Select a biller"
          value={props.form.type}
          options={props.Options}
          onChange={e => props.handleChange(e)}
        />
        {props.form.type !== '' && (
          <Inputfloat
            type="text"
            label="Phone Number"
            name="billingNumber"
            value={props.form.billingNumber}
            onChange={props.setInput}
            disabled={props.form.type === ''}
          />
        )}

        {props.airtimeDetails.response_code === '00' ? (
          <>
            <Amountinputcurrency
              type="text"
              currency={props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'}
              label="Amount"
              name="amount"
              value={
                props.form.amount === 'NaN'
                  ? 0
                  : props.form.amount.toLocaleString('en-US') || ''
              }
              onChange={props.setInput}
              disabled={false}
              pattern="[0-9,.]*"
            />

            {removeCommaAmount(props.form.amount) > 0 &&
              (removeCommaAmount(props.form.amount) >
                removeCommaAmount(props.selectedCurrency.balance / 100) ||
                removeCommaAmount(props.form.amount) < 100) && (
                <AlertError
                  body={
                    removeCommaAmount(props.form.amount) < 1000
                      ? `Amount should be atleast ₦100 Naira.`
                      : `You do not have upto ${
                          props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
                        }${props.form.amount} in your Vesti ${
                          props.selectedCurrency.value
                        } wallet, please deposit into your account.`
                  }
                />
              )}
            {removeCommaAmount(props.form.amount) > 0 &&
              removeCommaAmount(props.form.amount) > 5000 && (
                <AlertError
                  body={
                    removeCommaAmount(props.form.amount) >
                    removeCommaAmount(5000)
                      ? `Maximum amount allowed per transaction is ₦5,000`
                      : ''
                  }
                />
              )}

            <button
              type="submit"
              className="default-btn w-100 py-3"
              disabled={
                (props.form.type !== '' && props.form.amount !== ''
                  ? false
                  : true) ||
                props.form.amount < 100 ||
                props.form.amount > 5000
              }
              onClick={() => props.setStep(2)}
            >
              Proceed to Enter your Pin
            </button>
          </>
        ) : (
          <></>
        )}
      </form>
    </>
  );
};

const Finalize = props => {
  const transactionPin = value => {
    props.setForm({ ...props.form, transactionPin: value });
  };

  return (
    <div className="ngnwithdrawal__review">
      <Transreview
        data={[
          { title: 'Transaction Type', value: 'Airtime' },
          {
            title: 'Network',
            value: props.form.type.label.split(' VTU'),
          },
          {
            title: 'Amount',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(props.form.amount).toLocaleString('en-US')}`,
          },
          {
            title: 'Fee',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(props.form.type.fee).toLocaleString('en-US')}`,
          },
          {
            title: 'Phone Number',
            value: props.form.billingNumber,
          },
          {
            title: 'Total',
            value: `${
              props.selectedCurrency.value === 'NGN' ? '₦' : 'GH¢'
            }${removeCommaAmount(
              parseInt(props.form.type.fee) + parseInt(props.form.amount),
            ).toLocaleString('en-US')}`,
          },
        ]}
      />
      <div className="mb-2"></div>
      <Comptransaction
        fetcher={props?.fetchTransactionOTP}
        setPin={transactionPin}
        loading={props.isLoading}
        goBack={() => props.setStep(0)}
        btn={`Finalize Transaction`}
        onFinish={Finalize}
        len={4}
        lower={true}
      >
        <Backcontinue goBack={() => props.setStep(0)}>
          <Platformbutton
            name="Finalize Transaction"
            type="normal"
            click={() => props.payBill()}
            disabled={
              props.form.transactionPin.length !== 4 || props.isLoading
                ? true
                : false
            }
          />
        </Backcontinue>
      </Comptransaction>
      <p style={{ textAlign: 'center' }}>
        Didn't get the code?{' '}
        <span
          style={{ cursor: 'pointer', color: 'green' }}
          onClick={() => props?.dispatch(fetchTransactionOTP())}
        >
          Resend
        </span>
      </p>
    </div>
  );
};
