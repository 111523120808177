import React, { useState, useEffect } from 'react';
import {
  SubLabelRadioOption,
  RadioOption,
} from 'components/common/radiobutton/radiobutton';
import { Platformbutton } from 'components/common/button/button';
import { navigate } from '@reach/router';
import api from 'appRedux/api';
import { FiSearch } from 'react-icons/fi';
import Inputfloat from 'components/common/inputs/inputfloat';
import { useSelector } from 'react-redux';
import UsSkeletonLoader from './skeletonLoader';
import { openNotificationWithIconErr } from 'appRedux/actions/Common';

export const UscisModal = () => {
  const [step, setStep] = useState(null);

  const [option, setOption] = useState('Check-History');
  const [optiontwo, setOptionTwo] = useState('usImmigration');

  const [selectedCountry, setSelectedCountry] = useState('');

  const [countries, setCountries] = useState(null);

  const [history, setHistory] = useState(null);

  const [isloading, setIsloading] = useState(false);

  const userD = useSelector(state => state.auth?.userData);

  const setDynamicOption = value => {
    setOption(value);
  };

  const setOtherOptions = value => {
    setOptionTwo(value);
  };

  const handleHistoryFetch = async () => {
    try {
      const res = await api.get(
        'https://pathwayapi.wevesti.com/api/v1/pathway/get-foia-case-country',
      );
      setCountries(res.data?.country);
    } catch (error) {
      console.log(error);
    }
  };

  const FetchHistory = async () => {
    setIsloading(true);
    try {
      const res = await api.get(
        `https://pathwayapi.wevesti.com/api/v1/pathway/get-foia-case-history?userId=${userD.id}`,
      );
      setStep(0);
      setIsloading(false);
    } catch (error) {
      setStep(1);
      setIsloading(false);
    }
  };

  useEffect(() => {
    FetchHistory();
    handleHistoryFetch();
  }, []);

  const [formState, setFormState] = useState({
    // dateOfBirth: '',
    caseControlNumber: '',
    requestNumber: '',
  });

  const handleInputChange = (name, value) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  switch (step) {
    case 0:
      return (
        <SelectOption
          option={option}
          setOption={setOption}
          setStep={setStep}
          setDynamicOption={setDynamicOption}
        />
      );

    case 1:
      return (
        <NewstatusCheck
          countries={countries}
          setSelectedCountry={setSelectedCountry}
          selectedCountry={selectedCountry}
          setStep={setStep}
        />
      );

    case 2:
      return (
        <SelectOptionTwo
          option={optiontwo}
          setOption={setOptionTwo}
          setStep={setStep}
          setDynamicOption={setOtherOptions}
        />
      );

    case 3:
      return (
        <UsForm
          option={optiontwo}
          setOption={setOptionTwo}
          setStep={setStep}
          setDynamicOption={setOtherOptions}
          formState={formState}
          setFormState={setFormState}
          handleInputChange={handleInputChange}
        />
      );

    default:
      return (
        <div>
          <UsSkeletonLoader />
        </div>
      );
  }
};

const SelectOption = props => {
  return (
    <div className="paymentoption-container">
      <div className="paymentoption-inner">
        <div>
          <h5 className="font-[600] text-[18px]  text-[#14290A]">
            Select Option
          </h5>
        </div>

        <div className="paymentoption-inner mt-2 center mb-4">
          <SubLabelRadioOption
            image=""
            changed={props.setDynamicOption}
            id="1"
            isSelected={props.option === 'Check-History'}
            sublabel="Check History"
            value="Check-History"
          />
          <div className="mb-2"></div>
          <SubLabelRadioOption
            image=""
            changed={props.setDynamicOption}
            id="2"
            isSelected={props.option === 'new-status'}
            sublabel="New Status Check"
            value="new-status"
          />
        </div>
        {props.option === 'Check-History' ? (
          <Platformbutton
            type="normal"
            name="Proceed"
            click={() => navigate('/casestatus-check')}
            classname="fas fa-arrow-right"
          />
        ) : (
          <Platformbutton
            type="normal"
            name="Proceed"
            click={() => props.setStep(1)}
            classname="fas fa-arrow-right"
          />
        )}
      </div>
    </div>
  );
};

const NewstatusCheck = props => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [searchResult, setSearchResult] = useState('');
  const [emptyState, setEmptyState] = useState('');

  const handleSearchChange = event => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const applyFilter = () => {
    if (searchValue.trim() === '') {
      setFilteredData(props.countries);
      setSearchResult('');
    } else {
      const filteredTitles = props.countries?.filter(country =>
        country.country.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setSearchResult(filteredTitles.length > 0 ? '' : 'Country not found');
      setFilteredData(filteredTitles);
    }
  };

  useEffect(() => {
    applyFilter();
  }, [props.countries, searchValue]);
  return (
    <div>
      <div>
        <h5 className="font-[600] text-[20px]  text-[#14290A] ">
          Select Country
        </h5>
      </div>
      <div className="mb-4"></div>

      <div
        style={{
          background: '#F7F8FA',
          padding: '10px',
          color: '#858CA0',
          fontWeight: '500',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {' '}
        <FiSearch />
        <input
          type="text"
          placeholder=" Search for Country"
          value={searchValue}
          onChange={handleSearchChange}
          className="usInputField"
        />
      </div>

      <div className="mb-4"></div>

      <div style={{ height: '10rem', overflowY: 'auto' }}>
        {filteredData?.map(country => {
          return (
            <div
              key={country.country}
              onClick={() => {
                country.country === 'USA'
                  ? props.setSelectedCountry(country.country)
                  : setEmptyState('');
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '1rem',
                background: `${
                  props.selectedCountry === country.country
                    ? '#F7F8FA'
                    : 'white'
                }`,
                cursor: `${
                  country.country === 'USA' ? 'pointer' : 'not-allowed'
                }`,
                padding: '2px',
              }}
            >
              <img
                src={country.countryFlag}
                alt=""
                style={{ borderRadius: '100%', width: '30px', height: '30px' }}
              />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p>{country.country}</p>
                <div>
                  {country.country === 'USA' ? (
                    <></>
                  ) : (
                    <p
                      style={{
                        fontSize: '11px',
                        background: '#F7F8FA',
                        color: '#000',
                        padding: '2px 10px',
                      }}
                    >
                      Coming soon
                    </p>
                  )}{' '}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <p className="mt-2 text-red-600 text-center">{searchResult}</p>
      <div className="mb-4"></div>

      <Platformbutton
        type="normal"
        name="Proceed"
        disabled={props.selectedCountry ? false : true}
        click={() => props.setStep(2)}
        classname="fas fa-arrow-right"
      />
    </div>
  );
};

const SelectOptionTwo = props => {
  return (
    <div className="paymentoption-container">
      <div className="paymentoption-inner">
        <div>
          <h2 className="font-[600] text-[24px]  text-[#14290A]">
            Select Option
          </h2>
        </div>
        <div className="mb-4"></div>

        <div className="paymentoption-inner  center ">
          <RadioOption
            image=""
            changed={props.setDynamicOption}
            id="1"
            isSelected={props.option === 'usImmigration'}
            label="US immigration case status"
            sublabel="Track US immigration public case"
            value="usImmigration"
          />
        </div>
        <div className="mb-4"></div>

        <Platformbutton
          type="normal"
          name="Proceed"
          click={() => props.setStep(3)}
          classname="fas fa-arrow-right"
        />
      </div>
    </div>
  );
};

const UsForm = props => {
  const userD = useSelector(state => state.auth?.userData);
  const [buttonText, setButtonText] = useState('Check Status');

  const [isSuccess, setIssuccess] = useState(false);

  const fetchStatus = async () => {
    setButtonText('Checking ...');
    try {
      const res = await api.get(
        `https://pathwayapi.wevesti.com/api/v1/pathway/get-foia-case-status?caseControlNumber=${props.formState.caseControlNumber}&requestNumber=${props.formState.caseControlNumber}&userId=${userD.id}`,
      );
      setIssuccess(true);
      navigate(`/casestatus-check/${props.formState.caseControlNumber}`);
      setButtonText('Check Status');
    } catch (error) {
      setIssuccess(false);
      openNotificationWithIconErr(
        error.response.data.message,
        'Check Status',
        'error',
      );
      setButtonText('Check Status');
    }
  };

  return (
    <div className="paymentoption-container">
      <div className="paymentoption-inner">
        <div>
          <h2 className="font-[600] text-[24px]  text-[#14290A]">
            Enter Details{' '}
          </h2>

          <p className="font-[600] text-[13px]  text-[#14290A]">
            Fill out the form with the required details
          </p>
        </div>
        <div className="mb-4"></div>

        <div className="paymentoption-inner  center ">
          <Inputfloat
            label="Receipt Number"
            type="text"
            name="Receipt Number"
            value={props.formState.caseControlNumber}
            disabled={false}
            placeholder="Enter your case receipt number"
            onChange={e =>
              props.handleInputChange('caseControlNumber', e.target.value)
            }
            invalid={'true'}
            error={''}
          >
            <div className="mb-[10px]"></div>
          </Inputfloat>

          {/* <Inputfloat
            label="Receipt Number"
            type="text"
            name="Receipt Number"
            value={props.formState.requestNumber}
            disabled={false}
            placeholder="Enter your request number"
            onChange={e =>
              props.handleInputChange('requestNumber', e.target.value)
            }
            invalid={'true'}
            error={''}
          >
            <div className="mb-[10px]"></div>
          </Inputfloat> */}
        </div>
        <div className="mb-4"></div>

        <Platformbutton
          type="normal"
          name={buttonText}
          click={() => {
            fetchStatus();
          }}
          disabled={props.formState.caseControlNumber ? false : true}
          classname="fas fa-arrow-right"
        />
      </div>
    </div>
  );
};
