import React, { useState } from 'react';
import Carousel, { CarouselItem } from 'components/common/carousel/carousel';
import GGcard from 'assets/physicalCard/GGcardP.png';
import '../../../components/vesticards/selectcard.scss';
import { Platformbutton } from 'components/common/button/button';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import Sad from 'assets/physicalCard/vcardprompt.png';
import successPng from 'assets/physicalCard/newsuccess.png';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import GGcardbg from 'assets/physicalCard/GGcardprompt.png';
import tick from 'assets/physicalCard/circle-check.png';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import Inputfloat from 'components/common/inputs/inputfloat';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import api from 'appRedux/api';
import ModernDatepicker from 'react-modern-datepicker';
import errorsvg from 'assets/newerror.svg';
// import { Success } from '../success/success';
import { Success } from 'components/common/success/success';
import { navigate } from '@reach/router';

export const PhysicalCard = () => {
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);

  // console.log(userData);
  // console.log(allWallet);

  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [step, setStep] = useState(0);
  const [cardtype, setCard] = useState(0);
  const [option, setOption] = useState('HOME-DELIVERY');
  const [errortitle, setErrorTile] = useState('');
  const [errorSubtitle, setErrorsubtitle] = useState('');

  const NgnWallet = allWallet?.find(wallet => {
    return wallet?.currency === 'NGN';
  });

  const setPaymentOption = value => {
    setOption(value);
  };

  const physical = [
    {
      image: GGcard,
      title: 'Vesti GlobalGeng Card (MasterCard)',
      subtitle: 'This is a Physical Naira Card that works everywhere you go.',
      button: 'Request For Card',
    },
  ];

  const [formState, setFormState] = useState({
    // dateOfBirth: '',
    stateOfResidence: '',
    address: '',
    postalCode: '',
    otherName: '',
  });

  // const [month, day, year] = formState.dateOfBirth.split('/');
  // const isoDate = new Date(`${year}-${month}-${day}`).toISOString();

  const handleInputChange = (name, value) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRequestAccount = async () => {
    const url = `/bank/request-account-number`;
    // setLoading(true);

    try {
      const res = await api.post(url);
      const { data } = res;
      // console.log(data);
      setStep(2);

      //  setStep(4);
    } catch (error) {
      // console.log(error);
      setErrorTile('Account Number Request');
      setErrorsubtitle('Something happened');
      setStep(7);
    }
  };

  const handleRequestGGcard = async () => {
    const url = `/card/pc/1/create`;

    const payload = {
      country: 'Nigeria',
      otherName: formState.otherName,
      city: formState.stateOfResidence,
      address: formState.address,
      postalCode: formState.postalCode,
      deliveryType: option,
      dob: formState.dob?.value,
      state: formState.stateOfResidence,
      lagos:
        formState.stateOfResidence === 'Lagos' ||
        formState.stateOfResidence === 'lagos' ||
        formState.stateOfResidence === 'LAGOS'
          ? true
          : false,
    };

    try {
      const res = await api.post(url, payload);
      const { data } = res;
      // console.log(data);
      //  openNotificationWithIcon(data.message, 'Subscription Payment', 'success');
      setStep(6);
      setFormState({
        address: '',
        otherName: '',
        postalCode: '',
        stateOfResidence: '',
      });

      //  setStep(4);
    } catch (error) {
      // console.log(error);
      setErrorTile('Vesti GlobalGeng Card');
      setErrorsubtitle('Something happened');
      setStep(7);

      //  if (error?.data?.errors) {
      //    openNotificationWithIconErr(
      //      objectValuesStringify(error?.data?.errors),
      //      'Subscription Payment',
      //      'error',
      //      setErrorMsg(error?.data?.errors),
      //    );
      //  } else {
      //    const err = error?.data?.message || error.message;
      //    openNotificationWithIconErr(err, 'Subscription Payment', 'error');
      //    setErrorMsg(err);
      //  }
      //  setStep(5);
      // setLoading(false);
    }
  };

  switch (step) {
    case 0:
      return (
        <SelectGGcard
          physical={physical}
          setCard={setCard}
          cardtype={cardtype}
          setStep={setStep}
          NgnWallet={NgnWallet}
        />
      );

    case 1:
      return (
        <RequestAccountNumber
          setStep={setStep}
          handleRequestAccount={handleRequestAccount}
        />
      );

    case 2:
      return <RequestAccountNumberSuccess setStep={setStep} />;

    case 3:
      return <VestiGGcard setStep={setStep} />;

    case 4:
      return (
        <DeliveryOption
          setStep={setStep}
          option={option}
          setPaymentOption={setPaymentOption}
        />
      );

    case 5:
      return (
        <GGDeliveryRequest
          setStep={setStep}
          formState={formState}
          setFormState={setFormState}
          handleRequestGGcard={handleRequestGGcard}
          handleInputChange={handleInputChange}
        />
      );

    case 6:
      return <RequestGGcardSuccess setStep={setStep} />;

    case 7:
      return (
        <Success
          image={errorsvg}
          title={errortitle}
          subtitle={errorSubtitle}
          onClick={() => setStep(0)}
          button="Try Again"
          type="error"
        />
      );
  }
};

const SelectGGcard = props => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '25px',
        }}
      >
        <p style={{ color: '#67A948', fontSize: '16px' }}>Step 1</p>

        <h2
          style={{
            color: '#14290A',
            fontSize: '22px',
            fontWeight: '600',
          }}
        >
          Select Card Type
        </h2>

        <p style={{ color: '#2B5219', fontSize: '13px' }}>
          Choose the type of card you want from our variety of options
        </p>
      </div>

      <div className="selectcard-container">
        <Carousel active={props.cardtype} setActive={props.setCard}>
          {props.physical.map((item, index) => (
            <CarouselItem key={index}>
              <div className="selectcard-inner">
                <div className="selectcard-inner center">
                  <img src={item.image} alt="Card SVG" />
                  <div className="center-controls">
                    {props.physical.map((item, index) => (
                      <div
                        style={{
                          background: '#67A948',
                          width: '56px',
                          height: '12px',
                          margin: '10px auto 0px auto',
                          borderRadius: '10px',
                        }}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </CarouselItem>
          ))}
        </Carousel>

        <div
          className=""
          style={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '20px',
            gap: '6px',
          }}
        >
          <p
            style={{
              color: '#62943B',
              fontSize: '16px',
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
            Vesti GlobalGeng Card (MasterCard)
          </p>
          <p style={{ textAlign: 'center', color: '#2B5219' }}>
            This is a Physical Naira Card that works everywhere you go.
          </p>
        </div>
        <Platformbutton
          type="normal"
          name={'Request Physical Card'}
          click={() => {
            props.NgnWallet?.bank?.number ? props.setStep(3) : props.setStep(1);
          }}
        />
      </div>
    </div>
  );
};

const RequestAccountNumber = props => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          left: '0px',
          top: '0px',
          height: '8rem',
        }}
      >
        <img src={Sad} alt="" />
      </div>

      <div className="requestaccountArticle">
        <p style={{ color: '#14290A', fontSize: '20px', fontWeight: '600' }}>
          You don’t have an account number
        </p>
        <p
          style={{
            color: '#2B5219',
            fontSize: '13px',
          }}
        >
          You need to have an account number to enable you put in a request for
          your Vesti Globalgeng Physical Card.
        </p>

        <p style={{ color: '#2B5219', fontSize: '13px' }}>
          Click the button below to request for an account number, after which
          you can proceed to request for your GlobalGeng Card
        </p>
      </div>

      <div className="flexButton">
        <div className="flexButton__cancel">
          <Platformbutton
            type="secondary"
            name={'Cancel'}
            click={() => {
              props.setStep(0);
            }}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Platformbutton
            type="normal"
            name={'Request Account Number'}
            click={() => {
              props.handleRequestAccount();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const RequestAccountNumberSuccess = props => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <img src={successPng} alt="" />
      </div>

      <div className="">
        <p
          style={{
            color: '#62943B',
            fontSize: '20px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Request Sent
        </p>
        <p
          style={{
            color: '#2B5219',
            fontSize: '13px',
            textAlign: 'center',
          }}
        >
          You have successfully put in a request for your account number. You
          can now click on the button below to request for your GlobalGeng Card
        </p>
      </div>

      <div className="flexButton">
        <div className="flexButton__cancel">
          <Platformbutton
            type="secondary"
            name={'Cancel'}
            click={() => {
              props.setStep(0);
            }}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Platformbutton
            type="normal"
            name={'Request Card'}
            click={() => {
              props.setStep(3);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const VestiGGcard = props => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          left: '0px',
          top: '0px',
          height: '8rem',
        }}
      >
        <img src={GGcardbg} alt="" />
      </div>

      <div className="requestaccountArticle">
        <p style={{ color: '#14290A', fontSize: '20px', fontWeight: '600' }}>
          Vesti GlobalGeng Card
        </p>
        <p
          style={{
            color: '#2B5219',
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          The process of getting a Vesti GlobalGeng Card involves a proper
          verification of your identity.
        </p>

        <ul>
          <li
            style={{
              display: 'flex',
              gap: '5px',
              color: '#2B5219',
              fontWeight: '400',
            }}
          >
            <img
              src={tick}
              alt=""
              style={{ width: '15px', height: '15px', marginTop: '5px' }}
            />
            Kindly Ensure your Vesti Account Full Name Matches the Name on the
            KYC Document Submitted for GlobalGeng Card Creation.
          </li>
          <li
            style={{
              display: 'flex',
              gap: '5px',
              color: '#2B5219',
              fontWeight: '400',
            }}
          >
            <img
              src={tick}
              alt=""
              style={{ width: '15px', height: '15px', marginTop: '5px' }}
            />
            The Verification and Card Creation Process can take up to 2 Minutes,
            Kindly be patient with us.
          </li>
        </ul>

        <div>
          <p>Note:</p>
          <ul>
            <li
              style={{
                color: '#2B5219',
                fontSize: '13px',
                fontWeight: '600',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  background: '#2B5219',
                  width: '6px',
                  height: '6px',
                  borderRadius: '12px',
                }}
              ></p>
              You will not be charged an issuing fee.
            </li>
            <li
              style={{
                color: '#2B5219',
                fontSize: '13px',
                fontWeight: '600',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  background: '#2B5219',
                  width: '6px',
                  height: '6px',
                  borderRadius: '12px',
                }}
              ></p>
              Delivery is completely free to any location.
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-4"></div>
      <Platformbutton
        type="normal"
        name={'Proceed'}
        click={() => {
          props.setStep(4);
        }}
      />
    </div>
  );
};

const DeliveryOption = props => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '25px',
        }}
      >
        <p style={{ color: '#67A948', fontSize: '16px' }}>Step 2 of 3</p>

        <h2
          style={{
            color: '#14290A',
            fontSize: '22px',
            fontWeight: '600',
          }}
        >
          Choose Delivery Option{' '}
        </h2>

        <p style={{ color: '#2B5219', fontSize: '13px' }}>
          Choose your preferred means of delivery.{' '}
        </p>
      </div>

      <div className="">
        <RadioOption
          image={''}
          changed={props.setPaymentOption}
          id="1"
          isSelected={props.option === 'HOME-DELIVERY'}
          label="Home Delivery"
          sublabel="Get your GlobalGeng Card delivered directly to your home."
          value="HOME-DELIVERY"
        />
        <div className="mb-4"></div>

        <RadioOption
          image={''}
          changed={props.setPaymentOption}
          id="2"
          isSelected={props.option === 'PICK-UP'}
          label="Pickup (Lagos Residents Only)"
          sublabel="Pickup your GlobalGeng Card at a specified pickup station."
          value="PICK-UP"
        />
        <div className="mb-4"></div>
      </div>

      <div className="flexButton">
        <div className="flexButton__cancel">
          <Platformbutton
            type="secondary"
            name={'Go Back'}
            click={() => {
              props.setStep(3);
            }}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Platformbutton
            type="normal"
            name={'Proceed'}
            click={() => {
              props.setStep(5);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const GGDeliveryRequest = props => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '25px',
        }}
      >
        <p style={{ color: '#67A948', fontSize: '16px' }}>Step 3 of 3</p>

        <h2
          style={{
            color: '#14290A',
            fontSize: '22px',
            fontWeight: '600',
          }}
        >
          Vesti GlobalGeng Card
        </h2>

        <p style={{ color: '#2B5219', fontSize: '13px' }}>
          Fill in the field below to request for your physical card and have it
          delivered to you{' '}
        </p>
      </div>

      <div>
        {/* <Inputfloat
          label="Date of Birth"
          type="text"
          name="Date of Birth"
          value={props.formState.dateOfBirth}
          disabled={false}
          placeholder="MM/DD/YYYY"
          onChange={e => props.handleInputChange('dateOfBirth', e.target.value)}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
        <div style={{ marginBottom: '10px' }}></div> */}

        <div style={{ position: 'relative', zIndex: '999', width: '100%' }}>
          <ModernDatepicker
            date={props.formState.dob?.value}
            className="dob"
            format={'YYYY-MM-DD'}
            showBorder
            onChange={date =>
              props.setFormState({
                ...props.formState,
                dob: { ...props.formState.dob, value: date },
              })
            }
            placeholder={'Date of Birth'}
            primaryColor={'#67A948'}
          />
        </div>
        <div style={{ marginBottom: '10px' }}></div>

        <Inputfloat
          label="Othername"
          type="text"
          name="Othername"
          value={props.formState.otherName}
          disabled={false}
          placeholder="Enter your Othername"
          onChange={e => props.handleInputChange('otherName', e.target.value)}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
        <div style={{ marginBottom: '10px' }}></div>

        <Inputfloat
          label="State of Residence"
          type="text"
          name="State of Residence"
          value={props.formState.stateOfResidence}
          disabled={false}
          placeholder="Enter your State of Residence"
          onChange={e =>
            props.handleInputChange('stateOfResidence', e.target.value)
          }
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
        <div style={{ marginBottom: '10px' }}></div>

        <Inputfloat
          label="Address"
          type="text"
          name="Address"
          value={props.formState.address}
          disabled={false}
          placeholder="Enter your Address"
          onChange={e => props.handleInputChange('address', e.target.value)}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
        <div style={{ marginBottom: '10px' }}></div>

        <Inputfloat
          label="Postal Code"
          type="text"
          name="Postal Code"
          value={props.formState.postalCode}
          disabled={false}
          placeholder="Enter your Postal Code"
          onChange={e => props.handleInputChange('postalCode', e.target.value)}
          invalid={'true'}
          error={''}
        >
          <div className="mb-[10px]"></div>
        </Inputfloat>
      </div>

      <div className="flexButton">
        <div className="flexButton__cancel">
          <Platformbutton
            type="secondary"
            name={'Go Back'}
            click={() => {
              props.setStep(4);
            }}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Platformbutton
            type="normal"
            disabled={
              props.formState.stateOfResidence === '' ||
              props.formState.address === '' ||
              props.formState.postalCode === ''
                ? true
                : false
            }
            name={'Request Physical Card'}
            click={() => {
              // props.setStep(6);
              props.handleRequestGGcard();
              // console.log('Form Data:', props.formState);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const RequestGGcardSuccess = props => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <img src={successPng} alt="" />
      </div>

      <div className="">
        <p
          style={{
            color: '#62943B',
            fontSize: '20px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Successful
        </p>
        <p
          style={{
            color: '#2B5219',
            fontSize: '13px',
            textAlign: 'center',
          }}
        >
          Your request was successful and your GlobalGeng Card is being
          processed. It will take 10-20 days for you to get your card.
        </p>
      </div>

      <div style={{ width: '100%' }}>
        <Platformbutton
          type="normal"
          name={'Okay, Thank you'}
          click={() => {
            navigate('/bank');

            // props.setRequestGGcard(false);
          }}
        />
      </div>
    </div>
  );
};
