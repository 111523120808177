import {
  OPEN_ABOUT,
  OPEN_FEEDS,
  OPEN_MEMBERS,
  OPEN_EVENTS,
  FETCH_ALL_GROUPS,
} from 'appRedux/constants';
import api from 'appRedux/api';

export const openAboutBox = () => ({
  type: OPEN_ABOUT,
});

export const openFeedsBox = () => ({
  type: OPEN_FEEDS,
});

export const openMembersBox = value => ({
  type: OPEN_MEMBERS,
});

export const openEventsBox = () => ({
  type: OPEN_EVENTS,
});

export const fetchGroups = () => async dispatch => {
  try {
    const response = await api.get('/group/all-groups');
    const data = response.data.data.data;

    dispatch({
      type: FETCH_ALL_GROUPS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
