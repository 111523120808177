import React, { useState, useEffect } from 'react';
import { Note } from '../../note';
import uploadProgress from 'assets/viasForm/uploadP.svg';
import paymentIconProgress from 'assets/viasForm/visaPaymentProgress.svg';
import patternBg from 'assets/viasForm/Pattern.svg';
import { FaArrowRightLong } from 'react-icons/fa6';
import reviewNotStartedIcon from 'assets/viasForm/notStartedReview.svg';
import {
  SinglePathways,
  AllSinglePathways,
} from 'containers/Admin/Pathways/AllPathways';
import { Largemodal } from 'components/common/simplifiedmodal';
import { fetchGroups } from 'appRedux/actions/feeds';
import { useDispatch, useSelector } from 'react-redux';
import groupmembergrowth from 'assets/pathways/groupusergrowth.svg';
import { NewGrowthcommunity } from 'containers/Admin/Pathways/growthcommunity';
import { navigate } from '@reach/router';
import { useLocation } from '@reach/router';
import { updatePrevLocation } from 'appRedux/actions/location';

export const Info = props => {
  const dispatch = useDispatch();

  const [myGroups, setMyGroups] = useState();

  const [seeAllPathways, setSeeAllPathways] = useState(false);
  const [seeAllGroups, setSeeAllGroups] = useState(false);

  const myGname = 'F1 Student VISA to the U.S';

  const allGroups = useSelector(state => state.feeds.groups);

  useEffect(() => {
    dispatch(fetchGroups());
  }, []);

  const toWholeCurrency = num =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  const accessCommunity = allGroups?.find(group => {
    return group.name === myGname;
  });

  const location = useLocation();
  const [previousPath, setPreviousPath] = useState();

  useEffect(() => {
    setPreviousPath(location.pathname); // Store current pathname on mount
  }, [location]);

  const handleSetPreviousPath = id => {
    dispatch(updatePrevLocation(previousPath));
    navigate(`/group/${id}`);
  };

  // export const updatePrevLocation = prevpathname => ({
  //   type: PREV_LOCATION,
  //   payload: prevpathname,
  // });
  //  console.log(previousPath);
  const pid = 'a83e651f-7877-471d-873e-fa568bc11b04';
  // a0e85207-895d-40b5-9d82-c0620da14992
  return (
    <>
      <Largemodal
        open={seeAllPathways}
        onClick={() => setSeeAllPathways(false)}
      >
        <AllSinglePathways />
      </Largemodal>{' '}
      <Largemodal open={seeAllGroups} onClick={() => setSeeAllGroups(false)}>
        <NewGrowthcommunity
          allGroups={allGroups}
          previousPath={previousPath}
          // username={data.username}
          // type={data.type}
          // isActive={isActive}
          // planType={planType}
        />
      </Largemodal>
      <div className="rightSectionRequirement">
        {props.submitted ? (
          <></>
        ) : (
          <p
            style={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#67A948',
              textAlign: 'center',
              paddingBottom: '0.75rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              props.setReportModal(true);
            }}
          >
            Click here to report an issue with this form
          </p>
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.75rem',
            marginBottom: '0.75rem',
            flexDirection: 'column',
          }}
        >
          {/* if the form has not been finally submitted, display this  */}

          <>
            <UploadAndReviewBar
              submitted={props.submitted}
              paymentProgress={props.paymentProgress}
              reviewStatus={props.reviewStatus}
              uploadPercentage={props.uploadPercentage}
            />
          </>

          {/* if the form has not been finally submitted, display ends here */}

          <div className="visaSmallBox" style={{ background: '#F6F8FF' }}>
            {' '}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                marginBottom: '0.75rem',
              }}
            >
              <img src={paymentIconProgress} alt="" />
              <p
                style={{
                  color: '#060E42',
                  fontSize: '13px',
                  marginBottom: '20px',
                  fontWeight: '500',
                }}
              >
                PAYMENT STATUS
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {props.paymentProgress == 100 ? (
                <p
                  style={{
                    color: '#060E42',
                    fontSize: '16px',
                    fontWeight: '700',
                  }}
                >
                  Fully Paid ($ {toWholeCurrency(props.amount)})
                </p>
              ) : (
                <p
                  style={{
                    color: '#060E42',
                    fontSize: '20px',
                    fontWeight: '700',
                  }}
                >
                  {props.paymentProgress}% PAID
                </p>
              )}
              {props.paymentProgress == 100 ? (
                <></>
              ) : (
                <p
                  style={{
                    color: '#303D94',
                    fontSize: '12px',
                    fontWeight: '500',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    props.setPaymentModal(true);
                  }}
                >
                  Complete Payment <FaArrowRightLong />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.paymentProgress == 100 ? (
        <div style={{ width: '100%', padding: '0px 6px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              width: '100%',
            }}
          >
            <p
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#67A948',
              }}
            >
              Explore pathways
            </p>
            <p
              style={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#67A948',
                cursor: 'pointer',
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
              }}
              onClick={() => setSeeAllPathways(true)}
            >
              See more <FaArrowRightLong />
            </p>
          </div>
          <SinglePathways pid={pid} />

          <div
            style={{ width: '100%', padding: '0px 6px', marginTop: '0.75rem' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '0.25rem',
                paddingBottom: '0.5rem',
                width: '100%',
              }}
            >
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#67A948',
                }}
              >
                Growth communities{' '}
              </p>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#67A948',
                  cursor: 'pointer',
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                }}
                onClick={() => setSeeAllGroups(true)}
              >
                See more <FaArrowRightLong />
              </p>
            </div>

            <div style={{ background: '#ECF8FF', padding: '12px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '0.75rem',
                }}
              >
                <p
                  style={{
                    color: '#1481BA',
                    fontSize: '15px',
                    fontWeight: '700',
                  }}
                >
                  {accessCommunity?.name}
                </p>
                <p
                  style={{
                    color: '#1481BA',
                    fontSize: '12px',
                    fontWeight: '700',
                    border: '1px solid #1481BA',
                    background: '#C0E9FF',
                    padding: '4px 12px',
                    borderRadius: '6px',
                  }}
                >
                  {accessCommunity?.type}
                </p>
              </div>

              <p style={{ color: '#1481BA', fontSize: '15px' }}>
                {accessCommunity?.description}
              </p>

              <div
                style={{
                  marginTop: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    // marginTop: '0.75rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                  }}
                >
                  <img
                    src={groupmembergrowth}
                    alt=""
                    style={{ height: '40%', width: '5rem' }}
                  />
                  <p style={{ fontSize: '13px' }}>5K+ Members</p>
                </div>

                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#1481BA',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    handleSetPreviousPath(accessCommunity?.id);
                  }}
                >
                  Join now <FaArrowRightLong />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="VisaFormMainPage__quickreadSubsection__sub">
          <Note title={props.checkVisaType} submitted={props.submitted} />
        </div>
      )}
    </>
  );
};

const UploadAndReviewBar = props => {
  

  if (!props.submitted) {
    return (
      <div className="visaSmallBox" style={{ background: '#FCF7FF' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginBottom: '0.75rem',
          }}
        >
          <img src={uploadProgress} alt="" />
          <p
            style={{
              color: '#3F1951',
              fontSize: '13px',
              marginBottom: '20px',
              fontWeight: '500',
            }}
          >
            UPLOAD PROGRESS
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
              height: '5px',
            }}
          >
            <div
              style={{
                width: `${
                  props.uploadPercentage ? props.uploadPercentage : 0
                }%`,
                height: '100%',
                backgroundColor: '#3F1951',
                borderRadius: '4px',
                transition: 'width 0.1s ease-in-out',
              }}
            ></div>
          </div>
          <p
            style={{
              color: '#3F1951',
              fontSize: '20px',
              fontWeight: '700',
            }}
          >
            {props.uploadPercentage ? props.uploadPercentage : 0}%
          </p>
        </div>
      </div>
    );
  } else if (
    (props.submitted && props.paymentProgress == 50) ||
    (props.submitted && props.paymentProgress == 75)
  ) {
    return (
      <div className="visaSmallBox" style={{ background: '#F2F4F7' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginBottom: '0.75rem',
          }}
        >
          <img src={reviewNotStartedIcon} alt="" />
          <p
            style={{
              color: '#344054',
              fontSize: '14px',
              marginBottom: '10px',
              fontWeight: '600',
            }}
          >
            REVIEW STATUS
          </p>
        </div>

        <p
          style={{
            color: '#98A2B3',
            fontSize: '20px',
            fontWeight: '700',
          }}
        >
          NOT STARTED
        </p>
      </div>
    );
  } else if (props.submitted && props.paymentProgress == 100) {
    return (
      <div
        className="visaSmallBox"
        style={{
          background: `${
            props.reviewStatus === 'COMPLETED' ? '#F7FFF3' : '#FFFCF5'
          } `,
          border: `${
            props.reviewStatus === 'COMPLETED'
              ? '1px solid #C7FFAD'
              : '1px solid #FEF0C7'
          }`,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginBottom: '0.5rem',
          }}
        >
          <img src={reviewNotStartedIcon} alt="" />
          <p
            style={{
              color: `${
                props.reviewStatus === 'COMPLETED' ? '#2B5219' : '#7A2E0E'
              }`,
              fontSize: '13px',
              fontWeight: '600',
            }}
          >
            REVIEW STATUS
          </p>
        </div>

        {props.reviewStatus === 'COMPLETED' ? (
          <p
            style={{
              color: '#67A948',
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            COMPLETED{' '}
          </p>
        ) : (
          <p
            style={{
              color: '#B54708',
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            IN PROGRESS{' '}
          </p>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};
