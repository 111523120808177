import React, { useEffect, useState } from 'react';
import '../Admin.css';
import { navigate } from '@reach/router';
// import Loader from 'components/Loader';
import Layout from 'components/common/DashboardLayout';
// import { Form } from 'antd';
import api from 'appRedux/api';
import { getCurrency, getCurrencyCode } from 'helpers/utils';
import MerchantPayment from './MerchantPayment';
import './services.scss';
import { Paymentoption } from 'components/common/paymentoption/paymentoption';
// import { useUserData } from 'helpers/hooks';
// eslint-disable-next-line
import { connect, useSelector } from 'react-redux';
import { openUpdateBox } from 'appRedux/actions/update';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Simplemodal } from '../../../components/common/simplifiedmodal';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { merchantPay, merchantPayViaCard } from 'appRedux/actions/merchants';
import { TourGuidemodal } from 'components/common/simplifiedmodal';
import { MigrationFeesTour } from 'TourGuide/PathwayTourGuide/MigrationFees/MigrationFees';

const Merchants = props => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [customamount, setCustom] = useState('');
  const [current, setCurrent] = useState('');
  const [paymentTitle, setPaymentTitle] = useState('checking');

  const [passport, setPassport] = useState('');
  const [school, setSchool] = useState('');
  const [transactionOtp, setTransactionOtp] = useState('');
  // eslint-disable-next-line
  const [loading] = useState(false);

  // const userD = useSelector(state => state.auth?.userData);
  // console.log(userD.country);

  // const storedCurrency = localStorage.getItem('currency');

  const country = JSON.parse(localStorage?.getItem('userData')).country;
  const storedCurrency = getCurrencyCode(country);

  // console.log(storedCurrency)

  // eslint-disable-next-line
  const [prices, setPrices] = useState(null);
  // eslint-disable-next-line
  const [pricesRate, setPricesRate] = useState(null);
  const [MigrationData, setMigrationData] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const IsPathwayTourTrue = useSelector(
    state => state.Tourguide.openCardTourModal,
  );

  const [MigrationTour, setMigrationTour] = useState(IsPathwayTourTrue);

  useEffect(() => {
    const fetchpriceData = async () => {
      setIsSuccess(false);
      try {
        const response = await api.get(
          `/vesti-fx/lists?currency=${storedCurrency}
           `,
        );
        // console.log(response);
        const myprice = response.data;
        setPricesRate(myprice);
        setIsSuccess(true);
      } catch (error) {
        // console.log(error);
        setIsSuccess(false);
      }
    };

    fetchpriceData();
  }, [storedCurrency]);

  //

  useEffect(() => {
    const fetchMerchantNprices = async () => {
      setIsSuccess(false);
      try {
        const response = await api.get(
          `/migration-fees/list?currency=${storedCurrency}
           `,
        );
        // console.log(response.data.data);
        setMigrationData(response.data.data);
        setIsSuccess(true);
      } catch (error) {
        // console.log(error);
        setIsSuccess(false);
      }
    };

    fetchMerchantNprices();
  }, [storedCurrency]);

  const { search } = useLocation();
  const values = queryString.parse(search);
  // eslint-disable-next-line
  var tab = values.merchant && values.merchant.toUpperCase();

  // eslint-disable-next-line
  const handlePayment = (amount, price, serviceName, title, value) => {
    setFormData({
      amount: serviceName,
      currency: storedCurrency,
      service: price,
      serviceId: title,
      method: 'wallet',
    });

    // console.log(JSON.stringify(formData));
    // setShowModal(true);
    //userData.verifiedKyc === "APPROVED" || userData.verifiedKyc === true || JSON.parse(localStorage.getItem('userData')).hasVerifiedKyc === true ? setShowModal(true) :openUpdateModal();
  };

  const handleCompleteTransaction = async cb => {
    let formattedData = '';
    formattedData = { ...formData, transactionOtp };

    // console.log(formattedData);
    const url = `merchant-service/payment`;
    props.merchantPay(formattedData, url, cb);
  };

  const toWholeCurrency = num =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return (
    <>
      <Layout>
        <div>
          <TourGuidemodal
            onClick={() => setMigrationTour(false)}
            open={MigrationTour}
          >
            <MigrationFeesTour />
          </TourGuidemodal>

          <Simplemodal onClick={() => setShowModal(false)} open={showModal}>
            <Paymentoption
              exchangeRate={1023}
              handleCompleteTransaction={handleCompleteTransaction}
              viaCard={props.merchantPayViaCard}
              paymentTitle={paymentTitle}
              formData={formData}
              loading={loading}
              setTransactionOtp={setTransactionOtp}
              amount={current}
              setCurrent={setCurrent}
              current={current}
              school={school}
              fee={customamount}
              setCustom={setCustom}
              setSchool={setSchool}
              passport={passport}
              setPassport={setPassport}
              toWholeCurrency={toWholeCurrency}
              rate={prices && prices['MERCHANT_RATE'] / 100}
              closeModal={() => setShowModal(false)}
              loader={props.loading}
              message={props.message}
            />
          </Simplemodal>

          <div className=" " style={{ width: '100%' }}>
            <div className="pt-4" id="savings">
              <Titlesubtitle
                title="Migration Fees"
                subtitle="See all merchants service available on Vesti."
              />
              {isSuccess && (
                <div className=" mt-4 mygrid mb-5">
                  {MigrationData.map((item, index) => (
                    <MerchantPayment
                      amount={toWholeCurrency(item.calculatedAmount)}
                      title={item.title}
                      onClickHandler={handlePayment}
                      price={item.calculatedAmount}
                      serviceName={item.serviceName}
                      setShowModal={setShowModal}
                      setPaymentTitle={setPaymentTitle}
                      abbreviation={''}
                      description={''}
                      paymentHandler={() => handlePayment(item.serviceName)}
                      setCurrent={setCurrent}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

//eslint-disable-next-line no-unused-vars
const ServiceCard = ({
  id,
  image,
  amount,
  service,
  description,
  currency,
  username,
  user,
  //eslint-disable-next-line no-unused-vars
  handlePayment,
  data,
}) => (
  <div
    // style={{ boxShadow: 'rgb(245 245 245) 0 0 2px 2px' }}
    // className="card border-1 for-hover"
    className="for-hover"
  >
    <div
      className="for-hover-body"
      // className="card-body for-hover-body"
    >
      <div
        className="for-hover-body-container"
        style={{ height: '120px' }}
        // className="rounded bg-dark mb-3 for-hover-body-container"
      >
        <img
          src={image}
          alt="service logo"
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
        />
      </div>
      <div className="px-2 for-hover-content">
        <p className="mb-2" style={{ fontSize: '18px', fontWeight: 600 }}>
          {service}
        </p>
        <p className="mb-3 long-text-description" style={{ fontWeight: 300 }}>
          {description}
        </p>
        <div className="d-flex justify-content-between mb-3">
          <div>
            <span style={{ fontWeight: 600, fontSize: 18 }}>
              {getCurrency(currency)}
              {amount}
            </span>
          </div>
          <div>0 reviews</div>
        </div>
        <div>
          <span
            style={{ cursor: 'pointer' }}
            // onClick={() => handlePayment('service')}
            className="default-btn py-2"
            onClick={e => {
              e.preventDefault();
              navigate(`/service/${id}`, {
                state: {
                  image: image,
                  amount: amount,
                  description: description,
                  currency: currency,
                  service: service,
                  username: username,
                  user: user,
                  data: data,
                },
              });
            }}
          >
            Learn More
          </span>
        </div>
      </div>
    </div>
  </div>
);
const mapStateToProps = ({ auth, merchants }) => {
  const { authUser } = auth;
  const { message, loading } = merchants;
  return {
    authUser,
    message,
    loading,
  };
};

const mapDispatchToProps = {
  openUpdateBox,
  merchantPayViaCard,
  merchantPay,
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchants);
