import api from 'appRedux/api';
import React, { useEffect, useState } from 'react';
import './vestirate.scss';

export const Vestirate = props => {
  // console.log('Vestirate props : ', props);
  const [rate, setRate] = useState({});
  // eslint-disable-next-line no-unused-vars

  var rates = () => {
    api
      .get(`/vesti-fx/lists?currency=${props.currency}`)
      .then(res => {
        // console.log(res);
        setRate(res.data);
      })
      .catch(err => {
        //console.log(err)
      });
  };

  useEffect(() => {
    rates();
  }, [props.currency]);

  return (
    <span>
      <span>
        {props.reverse ? (
          <p className="vestirate">
            {rate
              ? `1 USD = ${rate?.cardRate / 100} ${props.currency}`
              : 'rate loading...'}
          </p>
        ) : (
          <p className="vestirate">
            {rate
              ? `1 USD = ${rate?.cardRate / 100} ${props.currency}`
              : 'rate loading...'}
          </p>
        )}
        {props.before && <p className="vestirate__before">{props.before} </p>}
      </span>
    </span>
  );
};
