import React, { useEffect, useState } from 'react';
import '../Admin.css';
import Layout from 'components/common/DashboardLayout';
import { Link } from '@reach/router';
import Modal from 'components/common/Modal';
import { Growthcommunity } from './growthcommunity';
import 'components/pathway/becomeprovider.scss';
import PinNote from 'assets/pin_note.svg';
import sevisserv from 'assets/sevisserv.png';
import usdepartment from 'assets/usdepartment.png';
import tef from 'assets/tefnew.png';
import ielts from 'assets/ielts.png';
import wesservice from 'assets/wesservice.png';
import ukvisa from 'assets/ukvisa.png';
import dummy from 'assets/dummy-avatar.png';
import pathwayusers from 'assets/pathways/pathwayusers.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowDown, FaArrowUp, FaTimes } from 'react-icons/fa';
import CanadaStudyPermit from 'assets/pathways/video/CanadaStudyPermit.mp4';
import KenyaVisitingVisa from 'assets/pathways/video/KenyaVisitingVisa.mp4';
import CanadaPermanentResidencyVisa from 'assets/pathways/video/CanadaPermanentResidencyVisa.mp4';
import NetherlandHighlyskilledMigrantVisa from 'assets/pathways/video/NetherlandHighlyskilledMigrantVisa.mp4';
import webinarcalendar from 'assets/pathways/webinarcalendar.svg';
// import { Feespayment } from 'components/bank/feespayment/feespayment';
import {
  Largemodal,
  Videomodal,
  Simplemodal,
} from 'components/common/simplifiedmodal';
import { openUpgradeBox } from 'appRedux/actions/update';
import { connect } from 'react-redux';

import 'components/bank/domore/domore.scss';

import { useDispatch } from 'react-redux';
import {
  openReward,
  openAccWait,
  handleFees,
  //openFees,
  openLoan,
  closeLoan,
} from 'appRedux/actions/domore';
import { opennReg } from 'appRedux/actions/auth';
import api from 'appRedux/api';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import { useUserData } from 'helpers/hooks';
import { navigate } from '@reach/router';
import { Empty } from 'components/common/empty/empty';
import './pathways.scss';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Platformbutton } from 'components/common/button/button';
import { Passportservices } from './Passportservices';
import { TourGuidemodal } from 'components/common/simplifiedmodal';
import { useSelector } from 'react-redux';
import { IntroPathwayGuide } from 'TourGuide/PathwayTourGuide/Intro/Intro';
import visabannerLogo from 'assets/viasForm/pathwayVisaLogo.svg';
import _ from 'lodash';

const localUserData = localStorage.getItem('userData');

export let userId;
if (localUserData) {
  userId = localStorage.getItem('userData').id;

  //Only business class user can access this group
}

export const videoDataPathay = [
  {
    country: 'canada',
    link: { CanadaStudyPermit },
  },

  {
    country: 'kenya',
    link: { KenyaVisitingVisa },
  },

  {
    country: 'netherland',
    link: { CanadaPermanentResidencyVisa },
  },

  {
    country: 'usa',
    link: { NetherlandHighlyskilledMigrantVisa },
  },
];

export const webinarDataOld = [
  {
    id: '10',
    title: ' Practical Guide to Move to the USA in 2024',
    description: 'With Ayomiposi Adewale, Maryam Akanbi, and Maryam Yusuff',
    date: 'Sat., Jul 30 | 11:00 PM CAT ',
    category: 'Paid',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',
    url:
      'https://www.youtube.com/watch?v=CI1kgSoctyc&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun',
  },
];

export const webinarData = [
  {
    id: '18',
    link:
      'https://www.youtube.com/watch?v=NTK85lm4NTk&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=12',
    title: 'How to Move Abroad: The Legal Details',
    featuring:
      'Featuring:  Ifeoluwa Ariyo-Agbaje, Damola Oni, Ayeni Paula, Matilda Vera-Cruz',

    date: '5th June 2024',
    subtitle: 'How to Move Abroad: The Legal Details',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '17',
    link:
      'https://www.youtube.com/watch?v=JjBv-h-twus&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=11',
    title:
      'Navigating the Extraordinary Ability Visa Process and Relocating to the US with Your Family',
    featuring: 'Featuring: 	Ifeoluwa Ariyo-Agbaje, Olusola Amusan',
    date: '4th June 2024',
    subtitle:
      'Navigating the Extraordinary Ability Visa Process and Relocating to the US with Your Family',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '16',
    link:
      'https://www.youtube.com/watch?v=Meatgyh5AAo&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=10',
    title: 'How I Navigated the Extraordinary Ability Visa',
    featuring:
      'Featuring: 	Ifeoluwa Ariyo-Agbaje,  Olubunmi Opadoyin, Olusola Amusan, Abimbola Amusan',
    date: '22nd May 2024',
    subtitle: 'How I Navigated the Extraordinary Ability Visa',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '15',
    link:
      'https://www.youtube.com/watch?v=ZEB9vE0UvvI&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=9',
    title: '3 "JAPA" Loans That Will Supercharge Your Move Abroad!',
    featuring:
      'Featuring: 	Ifeoluwa Ariyo-Agbaje, Oludele Gbenga, Olubunmi Opadoyin, Olusola Amusan, Abimbola Amusan',
    date: '16th May 2024',
    subtitle: '3 "JAPA" Loans That Will Supercharge Your Move Abroad!',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '14',
    link:
      'https://www.youtube.com/watch?v=YTfUe233ZFE&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=8',
    title: 'How I ended up in a top University in the USA with ZERO tuition',
    featuring:
      'Featuring: 	Ifeoluwa Ariyo-Agbaje, Olubunmi Opadoyin, Olusola Amusan, Abimbola Amusan',
    date: '17th Apr 2024',
    subtitle: 'How I ended up in a top University in the USA with ZERO tuition',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '13',
    link:
      'https://www.youtube.com/watch?v=LgRmwKq8C1E&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=7',
    title: 'How to Fund Your Masters in STEM or MBA in the USA & Canada!',
    featuring:
      'Featuring: 	Ifeoluwa Ariyo-Agbaje, Olubunmi Opadoyin, Olusola Amusan, Seyi Folarin-Ottun, Abimbola Amusan',
    date: '11th Apr 2024',
    subtitle: 'How to Fund Your Masters in STEM or MBA in the USA & Canada!',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '12',
    link:
      'https://www.youtube.com/watch?v=9HlaOLrdsYQ&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=6',
    title: "Your Master's Degree: The Gateway to a 10-Year Green Card",
    featuring:
      'Featuring: 	Ifeoluwa Ariyo-Agbaje, Olubunmi Opadoyin, Olusola Amusan, Abimbola Amusan',
    date: '25th Mar 2024',
    subtitle: "Your Master's Degree: The Gateway to a 10-Year Green Card",
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '11',
    link:
      'https://www.youtube.com/watch?v=xu42y0pkygQ&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=5',
    title: 'Move to the US in 12 Weeks with Talent Visa',
    featuring:
      'Featuring: Laura Alagbada, Olubunmi Opadoyin, Olusola Amusan, Abimbola Amusan',
    date: '18th Mar 2024',
    subtitle: 'Move to the US in 12 Weeks with Talent Visa',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '10',
    link:
      'https://www.youtube.com/watch?v=9g-RxZFt3YM&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=4',
    title:
      'How to Move to the USA with Less Than 5 Million Naira and a Migration Loan',
    featuring:
      'Featuring: Laura Alagbada, Hilda Acheampong, Olubunmi Opadoyin,  Olusola Amusan, Abimbola Amusan',
    date: '11th Mar 2024',
    subtitle:
      'How to Move to the USA with Less Than 5 Million Naira and a Migration Loan',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },

  {
    id: '9',
    link:
      'https://www.youtube.com/watch?v=-4pqJVZaV3Q&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=3',
    title: 'GOING GLOBAL with Adedamola Oni (Corporate and Legal Affairs Lead)',
    featuring: 'Featuring: Adedamola Oni',
    date: '5th Mar 2024',
    subtitle:
      'GOING GLOBAL with Adedamola Oni (Corporate and Legal Affairs Lead)',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '8',
    link:
      'https://www.youtube.com/watch?v=swwTgeLAg0Q&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun&index=2',
    title: 'Move to the USA with your UK Masters',
    featuring:
      'Featuring: Laura Alagbada, Damola Oni,  Olubunmi Opadoyin,  Olusola Amusan, Abimbola Amusan',
    date: '17th Jan 2024 • 2:00PM WAT',
    subtitle: 'Move to the USA with your UK Masters',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '7',
    link:
      'https://www.youtube.com/watch?v=CI1kgSoctyc&list=PLAwUqFzh4aL_99m5_vbnqgS2DFVfXpCun',
    title: 'Practical Guide to Move to the USA in 2024',
    featuring:
      'Featuring: Laura Alagbada, Hilda Acheampong, Oyewale Okeyode, Olubunmi Opadoyin, Fredrick Adjapong, Olusola Amusan, Abimbola Amusan',
    date: '15th Jan 2024 • 2:00PM WAT',
    subtitle: 'Practical Guide to Move to the USA in 2024',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '4',
    link: 'https://youtu.be/Sl7bBw3PBXg',
    title: 'How to Ace Your US Visa Interview',
    featuring:
      'Featuring: Laura Alagbada, Hilda Acheampong, Oyewale Okeyode, Olubunmi Opadoyin, Chinemerem Wilson',
    date: '2nd Nov 2023 • 3:00PM WAT',
    subtitle: 'Learn how to ace your visa interview',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },

  {
    id: '1',
    link: 'https://www.youtube.com/embed/kfHSxEanYE0',
    title: 'How to Migrate to Germany',
    featuring: 'Featuring: Olusola Amusan, Yewande Oyebo and Tobi Wole Fasanya',
    date: '30th Jul 2023 • 11:00PM CAT',
    subtitle:
      ' How to Migrate to Germany with Olusola Amusan,Yewande Oyebo and Tobi Wole Fasanya.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '2',
    link: 'https://www.youtube.com/embed/FPD5FbB3uvg',
    title: 'How to Migrate to Ireland ',
    featuring: 'Featuring: Olusola and Dr. Segun Seriki',
    date: '30th Jul 2023 • 11:00PM CAT',
    subtitle:
      'How to Migrate to Ireland with Olusola Amusan and Dr. Segun Seriki.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '3',
    link: 'https://www.youtube.com/embed/Wn9_PrN3eSs',
    title: 'How to Migrate to France',
    featuring: 'Featuring: Olusola Amusan & Tobiloba Ricketts',
    date: '30th Jul 2023 • 11:00PM CAT',
    subtitle:
      'How to Migrate to France with Olusola Amusan & Tobiloba Ricketts.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },

  {
    id: '5',
    link: 'https://www.youtube.com/embed/ow2UoI8fI6o',
    title: 'Global Scholarships Information Session',
    featuring: 'Featuring: Olusola Amusan & PHD Scholar Isaiah Adelabu',
    date: '30th Jul 2023 • 11:00PM CAT',
    subtitle:
      'Global Scholarships Information Session with PHD Scholar Isaiah Adelabu.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
  {
    id: '6',
    link: 'https://www.youtube.com/embed/xK39Pm9bZdA',
    title: 'Accessing Global Scholarship opportunities',
    featuring: 'Featuring: Olusola Amusan Ommoby Awoyemi',
    date: '30th Jul 2023 • 11:00PM CAT',
    subtitle:
      'Accessing Global Scholarship opportunities with PHD Scholars Isaiah Adelabu and Maj. Adebayo Adeleke.',
    image:
      'https://substackcdn.com/image/fetch/w_320,h_213,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_center/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13466896-c9a7-42ec-bb06-eb700a573752_2500x2500.jpeg',

    type: 'FREE',
  },
];

export const blogData = [
  {
    id: '1',
    title: 'Acing your US Visa Interview: What not to do',
    description:
      'When opportunities meet preparedness, success occurs. Being prepared for your visa interview with your documents and credentials shows that you are a responsible and efficient individual. Do not be caught sorting out your papers in the middle of your visa interview.',
    date: '24, Jul, 2023',
    category: 'Migration',
    image:
      'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F88effc9e-0ffc-413e-afac-f7282670ea9c_2500x2500.png',
    url:
      'https://migrationinsightswithvesti.substack.com/p/acing-your-us-visa-interview-what',
  },

  {
    id: '2',
    title: 'Succeeding in the US Job Market',
    description:
      'In a recent blog post by CNBC , we notice that there are 3 types of employees in the US job market today. They are the thrivers, the coasters and the struggling. The thrivers are those who are really happy with their jobs, are loyal to their employers and do not plan on quitting those jobs any time soon. The strugglers are those who aren’t happy with their jobs or are the complete opposite of the thrivers. The coasters fall somewhere in between these two.',
    date: '24, Jul, 2023',
    category: 'Migration',
    image:
      'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F13d748b0-2575-4a16-b89a-41d004f1166b_2500x2500.png',
    url:
      'https://migrationinsightswithvesti.substack.com/p/succeeding-in-the-us-job-market',
  },

  {
    id: '3',
    title: 'Top 7 Tips to become financially free in America',
    description:
      'Does it seem like the money you earn never makes it to the end of the month? I mean, if it hardly makes it to the end of the month, what’s the guarantee that it will make it into a savings account let alone get you to be financially free?',
    date: '24, Jul, 2023',
    category: 'Migration',
    image:
      'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Ff11b10a2-7b21-4986-a988-e6ab724bf72c_2500x2500.png',
    url:
      'https://migrationinsightswithvesti.substack.com/p/top-7-tips-to-become-financially',
  },

  {
    id: '4',
    title: 'Tips for Crowdfunding your Education',
    description:
      'Funding a project or venture through crowdfunding involves raising money from a large number of people who each contribute a small amount; this generally happens online. Fundraising involves donations or investments from individuals and organizations in return for potential profit or rewards.',
    date: '24, Jul, 2023',
    category: 'Migration',
    image:
      'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fff2520d2-fae2-4ab9-bdf7-73225121786c_2500x2500.png',
    url:
      'https://migrationinsightswithvesti.substack.com/p/tips-for-crowdfunding-your-education',
  },

  {
    id: '5',
    title: 'How to Migrate to the United States Through Education',
    description:
      'Firstly, you have to learn about the variety of options available and how to identify an institution that best fits your needs. Finding these institutions are but not limited to: Select a course of study you are interested in You will probably choose to build a career in the field you choose to study, so your best bet will be to find something you are passionate about. You will stay enthusiastic and regular if you do this.',
    date: '24, Jul, 2023',
    category: 'Migration',
    image:
      'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F6057346a-da58-45d0-b68f-2ab144e3de9e_2500x2500.png',
    url:
      'https://migrationinsightswithvesti.substack.com/p/how-to-migrate-to-the-united-states',
  },

  {
    id: '6',
    title: 'Moving to the United Kingdom through the Tech Nation Visa',
    description:
      'A Tech Nation Visa is a visa program that allows the world leading tech talent to work in the UK digital sector, contributing their knowledge, creativity, and innovation to keep the UK at the top of the world’s digital transformation.',
    date: '24, Jul, 2023',
    category: 'Migration',
    image:
      'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fce9ac25f-f987-4962-86b6-e1043669c31c_2500x2500.png',
    url:
      'https://migrationinsightswithvesti.substack.com/p/moving-to-the-united-kingdom-through',
  },

  {
    id: '8',
    title: 'Migration and Business Expansion',
    description:
      'skilled immigration to a country has been shown to increase economic growth and the quality of life within the home country in the long run,  especially if these skilled workers get productive. In the long run, expanding customer demands for goods and services leads to more business creation and increased investments.',
    date: '24, Jul, 2023',
    category: 'Migration',
    image:
      'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F90b41b30-8469-4776-a318-12c18da3220e_2500x2500.png',
    url:
      'https://migrationinsightswithvesti.substack.com/p/migration-and-business-expansion',
  },
];

export const testimonialData = [
  {
    id: '1',
    name: 'Damola Oni',
    testimony:
      'After the event of 20-10-20, I knew the country wasn’t for me anymore. Thankfully, the team at Vesti was a step ahead of me, I attended a 2 hour webinar organized by Vesti on Saturday, 24th of October 2020 and it changed my orientation forever. Today, I am in the United States of America, and none of it would be possible without “Japa webinar 1.0” and the great work being done by the team at Vesti.',
    date: 'United States • 29, August, 2021',
  },

  {
    id: '2',
    name: 'Tutti Uwanikone',
    testimony:
      'Still feels like a dream that I made it to France for my Masters in Data Science and Artificial Intelligence in Business. Dreams come through with Vesti, take action today!',
    date: 'France • 12, June, 2022',
  },

  {
    id: '3',
    name: 'Olayide Olumeko',
    testimony:
      'I came about Mr. Sola Amusan post on Linkedin I adopted those series and became an addicted follower, read all the post, emails, Youtube channel, all Japa series, I actioned on the webinars put together by the Co-Founder. Then came 2021 now a reality, my entire family and myself are already in the UK. I can not wait to see this become the next slogan on CNN',
    date: 'United Kingdom • 08, January, 2023',
  },

  {
    id: '4',
    name: 'Oluwaloni',
    testimony:
      'Arriving in the UK is a dream come true. Never knew it could be seamless. All thanks to the requirements information and support I got from',
    date: 'United Kingdom • 14, April, 2023',
  },

  {
    id: '5',
    name: 'Arinze N.',
    testimony:
      'I remember joining the very first JAPA webinar. After it I was fired up and determined to find ways possible to join my wife in the uk. Now with my wife in the Uk and life has been amazing ever since.',
    date: 'United Kingdom • 02, March, 2023',
  },

  {
    id: '6',
    name: 'Mr Iyoriobhe O.',
    testimony:
      'Before I joined the Vesti Telegram group, I had no passport, Today, I have not only gotten my passport but owing to the catalytic effort of the Japa 1.0 , I just got my Canada study Visa approved. I want to thank you very much for all you do.',
    date: 'Canada • 11, February, 2023',
  },
  {
    id: '7',
    name: 'Favour C.',
    testimony:
      'My name is Favour. I started following Sola in late 2019. I had always dreamt of relocating from Nigeria but many times I was discouraged and kept procrastinating. When I started following Sola on IG and watching Sola’s posts, I was motivated to apply. First application was refused and though it was discouraging God had a better plan.I was encouraged by following up with Vesti and attending the IT trainings, it prepared me for an opportunity to study IT in UK. I moved in 2020 and by God’s grace I have completed my program and got a job working for the UK government.Thank you Sola, thank you Vesti. The support and encouragement got me to where I am today.',
    date: 'United Kingdom • 11, August, 2023',
  },
];

export const userData = {
  message: 'User data retrieved successfully',
  data: {
    user: {
      id: 'faaf0170-ff07-40db-8108-4199a7cc9313',
      firstName: 'Vesti',
      lastName: 'Test ',
      email: 'test@wevesti.com',
      username: 'vesti Test',
      emailVerified: true,
      verifiedKyc: true,
      factorAuth: false,
      KycPictureURL:
        'https://res.cloudinary.com/wevesti/image/upload/v1664809127/gpenyqlbdqevm4lizhsj.jpg',
      kycLevel: 'Level3',
      passedKyc: true,
      kycDocumentStatus: 'APPROVED',
      hasTransactionPin: true,
      country: 'USA',
      address: null,
      totalRefferralBonus: 0,
      phoneNumber: '+14697347949',
      totalReffered: '0',
      referalCode: '6hanJJker',
      planType: null,
      profilePictureURL: null,
      creditData: 'updated',
      stripeVirtualAccountNumber: '9670004059',
      stripeVirtualAccountRoutingNumber: '084106768',
      stripeVirtualBankName: 'Evolve Bank and Trust',
      stripeVirtualSwiftCode: '084106768',
      userType: 'PROVIDER',
    },
    wallets: [
      {
        id: '99c30c2e-b05e-4400-a56d-181d3867a6f7',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'NGN',
        name: 'Naira wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
      {
        id: '16a2c794-04ff-4839-88b6-9ba8f2adc438',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'USD',
        name: 'USD wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
    ],
    pendingTransfer: [],
  },
};

export const userDatas = {
  message: 'User data retrieved successfully',
  data: {
    user: {
      id: 'faaf0170-ff07-40db-8108-4199a7cc9313',
      firstName: 'Vesti',
      lastName: 'Test ',
      email: 'test@wevesti.com',
      username: 'vesti Test',
      emailVerified: true,
      verifiedKyc: true,
      factorAuth: false,
      KycPictureURL:
        'https://res.cloudinary.com/wevesti/image/upload/v1664809127/gpenyqlbdqevm4lizhsj.jpg',
      kycLevel: 'Level3',
      passedKyc: true,
      kycDocumentStatus: 'APPROVED',
      hasTransactionPin: true,
      country: 'USA',
      address: null,
      totalRefferralBonus: 0,
      phoneNumber: '+14697347949',
      totalReffered: '0',
      referalCode: '6hanJJker',
      planType: null,
      profilePictureURL: null,
      creditData: 'updated',
      stripeVirtualAccountNumber: '9670004059',
      stripeVirtualAccountRoutingNumber: '084106768',
      stripeVirtualBankName: 'Evolve Bank and Trust',
      stripeVirtualSwiftCode: '084106768',
      userType: 'PROVIDER',
    },
    wallets: [
      {
        id: '99c30c2e-b05e-4400-a56d-181d3867a6f7',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'NGN',
        name: 'Naira wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
      {
        id: '16a2c794-04ff-4839-88b6-9ba8f2adc438',
        userId: 'faaf0170-ff07-40db-8108-4199a7cc9313',
        currencyFlag:
          'https://res.cloudinary.com/wevesti/image/upload/v1659440605/wso14k3q6zedqdbjmaeo.jpg',
        balance: '0',
        currency: 'USD',
        name: 'USD wallet',
        active: true,
        bank: {
          number: '9977696809',
          bankName: 'Providus Bank',
          accountHolderName: 'Vesti Test ',
        },
      },
    ],
    pendingTransfer: [],
  },
};

const SliderSettings = {
  dots: true, // Display navigation dots
  infinite: true, // Enable infinite looping
  slidesToShow: 1, // Number of slides to show at a time
  slidesToScroll: 1, // Number of slides to scroll at a time
  autoplay: true, // Enable automatic scrolling
  autoplaySpeed: 4000, // Delay between slides in milliseconds
};

export const PathwaysCard = ({ description, amount, about }) => (
  <div className="col-12 col-sm-6 col-md-4 mb-3">
    <div className="p-4 dashboard-card">
      <div className="d-flex align-items-center">
        <span
          className="d-inline-block mr-3"
          style={{
            height: '50px',
            width: '50px',
            borderRadius: '50vw',
            backgroundColor: '#DFFFD0',
          }}
        ></span>
        <div
          className="text-uppercase font-light long-text-description"
          style={{ fontSize: '1rem', fontWeight: 300, maxWidth: '150px' }}
        >
          {description}
        </div>
      </div>
      <div style={{ fontWeight: 600, fontSize: '4rem' }}>{amount}</div>
      <div>
        <Link
          style={{ color: '#67A948' }}
          className="bg-transparent px-0 text-left"
          to="/dashboard"
        >
          See My {about}
          <span className="ml-2 d-inline-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.219 10.7797C5.28867 10.8495 5.37143 10.9049 5.46255 10.9427C5.55367 10.9805 5.65135 11 5.75 11C5.84865 11 5.94633 10.9805 6.03745 10.9427C6.12857 10.9049 6.21133 10.8495 6.281 10.7797L10.781 6.27966C10.8508 6.20999 10.9063 6.12723 10.9441 6.03611C10.9819 5.94499 11.0013 5.84731 11.0013 5.74866C11.0013 5.65001 10.9819 5.55233 10.9441 5.46121C10.9063 5.37009 10.8508 5.28733 10.781 5.21766L6.281 0.717661C6.14017 0.576831 5.94916 0.497714 5.75 0.497714C5.55083 0.497714 5.35983 0.576831 5.219 0.717661C5.07817 0.858491 4.99905 1.0495 4.99905 1.24866C4.99905 1.44782 5.07817 1.63883 5.219 1.77966L9.1895 5.74866L5.219 9.71766C5.14915 9.78733 5.09374 9.87009 5.05593 9.96121C5.01812 10.0523 4.99866 10.15 4.99866 10.2487C4.99866 10.3473 5.01812 10.445 5.05593 10.5361C5.09374 10.6272 5.14915 10.71 5.219 10.7797Z"
                fill="#67A948"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 5.74854C0 5.94745 0.0458074 6.13821 0.127345 6.27887C0.208883 6.41952 0.319471 6.49854 0.434783 6.49854L9.56522 6.49854C9.68053 6.49854 9.79112 6.41952 9.87265 6.27887C9.95419 6.13821 10 5.94745 10 5.74854C10 5.54962 9.95419 5.35886 9.87265 5.2182C9.79112 5.07755 9.68053 4.99854 9.56522 4.99854L0.434783 4.99854C0.319471 4.99854 0.208883 5.07755 0.127345 5.2182C0.0458074 5.35886 0 5.54962 0 5.74854Z"
                fill="#67A948"
              />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  </div>
);

export const PathwayCard = ({
  id,
  title,
  logo,
  description,
  stagesObject,
  creator,
  pathwayCost,
  country,
  month,
  pathwayVideo,
  duration,
  unit,
}) => {
  const [monthsRemaining, setMonthsRemaining] = useState(0);
  const createdAt = '2023-06-01T00:00:00.000Z';

  useEffect(() => {
    const startDate = new Date(createdAt);
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 8); // Add 8 months to the start date

    const updateRemainingMonths = () => {
      const currentDate = new Date();
      const timeDiff = Math.abs(currentDate.getTime() - endDate.getTime());
      const months = Math.ceil(timeDiff / (1000 * 60 * 60 * 24 * 30));
      const remainingMonths = months > 0 ? months : 0;
      setMonthsRemaining(remainingMonths);
    };

    updateRemainingMonths();

    const interval = setInterval(() => {
      updateRemainingMonths();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt]);

  const [subPathwayObject, setSubPathwayObject] = useState([]);
  const [subId, setSubId] = useState([]);
  const [planType, setPlanType] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    api

      .get(`pathway/plan-and-join`)
      .then(res => {
        const myObject =
          res.data.data.plan === null || res.data.data.plan.active === false
            ? null
            : res.data.data.subscriptions;
        setPlanType(res.data?.data?.plan?.type);
        setIsActive(
          res.data.data.plan === null ? false : res.data.data.plan.active,
        );
        setSubPathwayObject(myObject);
      })
      .catch(err => err);
  }, []);
  return (
    <div
      className="pathway-card"
      onClick={() =>
        navigate(`/pathwaydesc/${id}`, {
          state: {
            id,
            title,
            logo,
            description,
            stagesObject,
            creator,
            pathwayCost,
            country,
            month,
            pathwayVideo,
          },
        })
      }
    >
      <div className="card-body p-2">
        <div className="rounded bg-dark mb-3" style={{ height: '120px' }}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              src={logo}
              alt="pathway logo"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
            <div
              className="duration-pathway"
              style={{ position: 'absolute', bottom: 0 }}
            >
              {subPathwayObject === null
                ? ''
                : subPathwayObject.some(item => item.pathwayTemplateId === id)
                ? 'Joined . '
                : ''}
              {`${duration}`}
              Month(s) duration
              {/*remaining */}
            </div>
          </div>
        </div>
        <div className="px-2">
          <div
            className="mb-4 pathway-title"
            style={{ fontSize: '18px', fontWeight: 600 }}
          >
            {title}
          </div>
          <p className="mb-3 long-text-description" style={{ fontWeight: 300 }}>
            {description}
          </p>

          <img
            src={pathwayusers}
            alt="pathway user"
            style={{
              height: '100%',
              width: '90%',
              objectFit: 'cover',
            }}
          />

          <div className="d-flex justify-content-between"></div>
        </div>
      </div>
    </div>
  );
};

export const BlogCard = ({
  title,
  image,
  description,
  date,
  category,
  url,
}) => (
  <div className="pathway-card">
    <div className="card-body p-2">
      <div className="rounded bg-dark mb-3" style={{ height: '120px' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <img
            src={image}
            alt="pathway logo"
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </div>
      </div>
      <div className="px-2">
        <div className="mb-4">
          <span className="migration-date">{category}</span>
          <span className="grey-date">{date}</span>
        </div>

        <div
          className="mb-4 pathway-title"
          style={{ fontSize: '18px', fontWeight: 600 }}
        >
          {title}
        </div>
        <p className="mb-3 long-text-description" style={{ fontWeight: 300 }}>
          {description}
        </p>
      </div>

      <div className="mt-auto">
        <div>
          <p className="mb-0">
            <a href={url} target="_blank">
              Read more <i className="fas fa-arrow-right" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export const Pathways = props => {
  let dollarUSLocale = Intl.NumberFormat('en-US');

  const userD = useSelector(
    state => (state.auth.userData ? state.auth.userData : []),
    _.isEqual,
  );

  const [isOpen, setOpen] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [myPathways, setMyPathways] = useState({});

  const [videoUrl, setVideoUrl] = useState(null);

  const handleSeeVideoClick = link => {
    setVideoUrl(link);
  };

  const handleVideoClosePopup = () => {
    setVideoUrl(null);
  };
  const retrievedpassportMessage = localStorage.getItem('passportMessage');

  const [passportMessage, setPassportmessage] = useState(
    retrievedpassportMessage ? retrievedpassportMessage : '',
  );

  const [isPathwayPopupOpen, setPathwayPopupOpen] = useState(false);

  const [isGroupPopupOpen, setGroupPopupOpen] = useState(false);

  const [isTestimonialPopupOpen, setTestimonialPopupOpen] = useState(false);

  const [isBlogPopupOpen, setBlogPopupOpen] = useState(false);

  const [isWebinarPopupOpen, setWebinarPopupOpen] = useState(false);

  const [isMerchantPopupOpen, setMerchantPopupOpen] = useState(false);

  const [planType, setPlanType] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    api

      .get(`pathway/plan-and-join`)
      .then(res => {
        setPlanType(res.data?.data?.plan?.type);
        setIsActive(
          res.data.data.plan === null ? false : res.data.data.plan.active,
        );
      })
      .catch(err => err);
  }, []);

  const handleSeeAllPathwayClick = () => {
    setPathwayPopupOpen(true);
  };

  const handleClosePathwayPopup = () => {
    setPathwayPopupOpen(false);
  };

  const handleSeeAllGroupClick = () => {
    setGroupPopupOpen(true);
  };

  const handleCloseGroupPopup = () => {
    setGroupPopupOpen(false);
  };

  const handleSeeAllTestimonialClick = () => {
    setTestimonialPopupOpen(true);
  };

  const handleCloseTestimonialPopup = () => {
    setTestimonialPopupOpen(false);
  };

  const handleSeeAllBlogClick = () => {
    setBlogPopupOpen(true);
  };

  const handleCloseBlogPopup = () => {
    setBlogPopupOpen(false);
  };

  const handleSeeAllWebinarClick = () => {
    setWebinarPopupOpen(true);
  };

  const handleCloseWebinarPopup = () => {
    setWebinarPopupOpen(false);
  };

  const handleSeeAllMerchantClick = () => {
    setMerchantPopupOpen(true);
  };

  const handleCloseMerchantPopup = () => {
    setMerchantPopupOpen(false);
  };

  // Get a random selection of 4 items from myData
  const getRandomItemsmyPathways = () => {
    const shuffled = myPathways.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getAllItemsmyPathways = () => {
    const shuffled = myPathways.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const getRandomItemsblogData = () => {
    const shuffled = blogData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomAllblogData = () => {
    const shuffled = blogData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const merchantData = [
    {
      name: 'SEVIS FEE',
      description: 'Easily Pay your SEVIS fees without hassle',
      price: 'SEVIS',
      image: sevisserv,
    },

    {
      name: 'WES FEE',
      description: ' Easily Pay your WES fees without hassle',
      price: 'WES',
      image: wesservice,
    },

    {
      name: 'US VISA',
      description: 'Easily Pay your USA VISA without hassle',
      price: 'USVISADS160',
      image: usdepartment,
    },

    {
      name: 'IELTS',
      description: 'Easily Pay your IELTS without hassle',
      price: 'IELTS',
      image: ielts,
    },

    {
      name: 'TEF',
      description: 'Easily Pay your TEF without hassle',
      price: 'TEF',
      image: tef,
    },

    {
      name: 'UK STUDENT',
      description: 'Easily Pay your UK VISA without hassle',
      price: 'UKSTUDENTVISA',
      image: ukvisa,
    },

    // ...
  ];

  // const videoData = [
  //   {
  //     hashtag: 'Canada',
  //     description1: 'This Pathway is designed to help ',
  //     description2: 'individuals who wish to visit Canada',
  //     link: { CanadaStudyPermit },
  //     className: 'pathway-signup-background pathway-white',
  //   },

  //   {
  //     hashtag: 'KENYA',
  //     description1: 'This Pathway is designed to help ',
  //     description2: 'individuals who wish to visit Kenya',
  //     link: { KenyaVisitingVisa },
  //     className: 'pathway-signup-background pathway-white',
  //   },

  //   {
  //     hashtag: 'CANADA VISA',
  //     description1: 'How to Apply for Canada',
  //     description2: 'Permanent residency Visa.',
  //     link: { CanadaPermanentResidencyVisa },
  //     className: 'pathway-signup-background pathway-white',
  //   },

  //   {
  //     hashtag: 'NETHERLAND',
  //     description1: 'How to Apply for Netherland',
  //     description2: 'Highly Skilled Migrant Visa',
  //     link: { NetherlandHighlyskilledMigrantVisa },
  //     className: 'pathway-signup-background pathway-white',
  //   },
  // ];

  const videoData = [
    {
      hashtag: 'U.S. 0-1 Visa',
      description1: 'Individuals with Extraordinary',
      description2: 'Ability or Achievement',
      link: 'https://storage.googleapis.com/storagefileforpathway/O-1.mp4',
      className: 'pathway-signup-background pathway-white',
    },

    {
      hashtag: 'U.S. EB-1 Visa ',
      description1: 'Employment Based Green Card Visa',
      description2: '(Extraordinary Ability)',
      link: 'https://storage.googleapis.com/storagefileforpathway/EB-1.mp4',
      className: 'pathway-signup-background pathway-white',
    },

    {
      hashtag: 'U.S. EB-2 NIW Visa',
      description1: 'Employment Based Green Card',
      description2: '(National Interest Waiver)',
      link: 'https://storage.googleapis.com/storagefileforpathway/EB2-NIW.mp4',
      className: 'pathway-signup-background pathway-white',
    },

    {
      hashtag: 'U.S. H1B Visa',
      description1: 'Foreign workers with',
      description2: 'specialized  skills',
      link: 'https://storage.googleapis.com/storagefileforpathway/H-1B.mp4',
      className: 'pathway-signup-background pathway-white',
    },
  ];

  const getRandomItemsVideoData = () => {
    const shuffled = videoData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const getRandomItemsMerchantData = () => {
    const shuffled = merchantData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 5);
  };

  const getRandomAllMerchantData = () => {
    const shuffled = merchantData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const [videoPlaying, setVideoPlaying] = useState(false);

  const handleVideoPlay = () => {
    setVideoPlaying(true);
  };

  const handleVideoPause = () => {
    setVideoPlaying(false);
  };

  let providerId = userId;

  const { data, isSuccess, isLoading } = useQuery('my-pathways', () =>
    api
      .get(`/provider/fetchEachProviderPathways/${providerId}?userId=${userId}`)
      .then(data => data.data.data)
      .catch(err => err),
  );

  useEffect(() => {
    api
      // .get(`https://elementapi.wevesti.com/api`)
      .get(`/pathway/active/search`, null, 'pathway')
      .then(res => {
        setLoading(false);
        setMyPathways(res.data.data.data);
        setSuccess(true);
      })
      .catch(err => err);
  }, []);

  const isProvider = useUserData().userData.userType === 'PROVIDER';

  // const isProvider = true;

  const [status, setStatus] = useState();

  const goToNova = () => {
    // window.open('https://hello.novacredit.com/cards?country=NGA');
    navigate('/credithistory');
  };

  const goToBank = () => {
    // window.open('https://hello.novacredit.com/cards?country=NGA');
    navigate('/bank');
  };

  // console.log('setStatus', setStatus);
  var goTo = value => {
    navigate(`/${value}`);
  };

  const dispatch = useDispatch();
  var Reward = () => {
    dispatch(openReward());
  };

  // var Loan = ()=> {
  //     dispatch(openLoan())
  // }
  // eslint-disable-next-line
  var openWaitlist = () => {
    dispatch(openAccWait());
  };

  const getRandomItemstestimonialData = () => {
    const shuffled = testimonialData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomAlltestimonialData = () => {
    const shuffled = testimonialData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  const getRandomItemswebinarData = () => {
    const shuffled = webinarData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomAllwebinarData = () => {
    const shuffled = webinarData.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  //props.userData.

  // const userData =  useUserData().userData;

  var filt = {
    address: 'England',
    name: 'Mark',
  };

  var exclusive = [
    '0-1 Candidate US',
    'Business Class',
    'First Class',
    '1000 Students',
  ];

  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/group/all-groups`);
        const data = response.data.data.data;
        // console.log(response);
        setGroupData(data);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const getRandomItemsgroupData = () => {
    if (groupData) {
      const shuffled = groupData?.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 3);
    } else {
      return groupData;
    }
  };

  const getAllItemsgroupData = () => {
    if (groupData) {
      const shuffled = groupData?.sort(() => 0.5 - Math.random());
      return shuffled;
    } else {
      return groupData;
    }
  };

  const goToProfile = () => {
    // console.log(userData.firstName);
    userData.firstName === ' ' || userData.firstName === 'User'
      ? dispatch(opennReg(true))
      : navigate('/myprofile?tab=kyc');
  };
  var upload =
    userData.firstName === ' ' || userData.firstName === 'User'
      ? 'Update Your Profile'
      : 'Upload your Kyc';

  const IsPathwayTourTrue = useSelector(
    state => state.Tourguide.openCardTourModal,
  );
  const [pathwayTour, setPathwayTour] = useState(IsPathwayTourTrue);

  const [passportModal, setPassportModal] = useState(false);

  useEffect(() => {
    const notification = userD?.notification?.passportCreationNotification;

    if (notification === false) {
      setPassportModal(true);
    } else if (notification === true) {
      setPassportModal(false);
    } else if (notification === undefined && userD?.notification) {
      setPassportModal(true);
    } else {
      setPassportModal(false);
    }
  }, [userD]);

  const [isChecked, setIsChecked] = useState(false);

  const handlePassportPopUp = async () => {
    setIsChecked(!isChecked);

    const payload = {
      key: 'passportCreationNotification',
      value: true,
    };

    api
      .patch('customer/notification', payload)
      .then(res => {
        setPassportModal(false);
      })
      .catch(err => {
        console.error('Error:', err);
        setPassportModal(false);
      });
  };

  return (
    <>
      {IsPathwayTourTrue && (
        <TourGuidemodal
          onClick={() => setPathwayTour(false)}
          open={pathwayTour}
        >
          <IntroPathwayGuide />
        </TourGuidemodal>
      )}

      <Simplemodal onClick={() => setPassportModal(false)} open={passportModal}>
        <Passportservices
          setPassportModal={setPassportModal}
          handlePassportPopUp={handlePassportPopUp}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        />
      </Simplemodal>

      <Layout>
        <Modal isOpen={isOpen} onClose={() => setOpen(false)} maxWidth="480px">
          <div className="d-flex justify-content-center">
            <img
              src={PinNote}
              alt=""
              style={{ maxWidth: '200px', width: '80%' }}
              className="pb-5 pt-4"
            />
          </div>
          <div className="px-4">
            <div
              style={{
                fontSize: '24px',
                fontWeight: 500,
                marginBottom: '1rem',
              }}
            >
              Create Pathway
            </div>
            <p className="mb-4" style={{ fontWeight: 300 }}>
              The pathway template you create is subject to review and approval
              from the Vesti team, do not worry, it won’t take long to review
              and approve your template, contact us at info@wevesti.com for more
              information.
            </p>
            <Link className="default-btn w-100" to="/create-pathway">
              Proceed
            </Link>
          </div>
        </Modal>
        <div>
          <div
            className=" isw-container"
            style={{
              height: '85vh',
              width: '100%',
              overflow: 'scroll',
            }}
          >
            <div className=" flex_page_container d-flex justify-content-center">
              <div className=" px-3 px-md-4 px-lg-5 w-100">
                <section className={'services'}>
                  <div className="w-100 pathway-white">
                    <div
                      className={`carousel-container ${
                        videoPlaying ? 'carousel-paused' : ''
                      }`}
                    >
                      <Slider {...SliderSettings}>
                        {getRandomItemsVideoData().map((item, index) => (
                          <div key={index}>
                            <a
                              href="#"
                              onClick={() => handleSeeVideoClick(item.link)}
                            >
                              <div className={item.className}>
                                <div className="hashtagbanner">
                                  {' '}
                                  {item.hashtag}
                                </div>
                                <p className="pathway-white bottom-left pathwayvideodescription">
                                  {item.description1}
                                  <br />
                                  {item.description2}
                                </p>
                              </div>
                            </a>
                          </div>
                        ))}
                      </Slider>

                      {videoUrl && (
                        <Videomodal
                          open={videoUrl}
                          onClick={handleVideoClosePopup}
                        >
                          <div className="video-container">
                            <video controls width="100%" height="auto">
                              <source
                                // src={videoUrl[Object.keys(videoUrl)[0]]}
                                src={videoUrl}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </Videomodal>
                      )}
                    </div>
                  </div>

                  {/* duplicate here */}
                  <div className="loancredit">
                    <div
                      className="loanpathways"
                      onClick={() => dispatch(handleFees())}
                      style={{ cursor: 'pointer' }}
                    >
                      <p>Need a Loan for School?</p>
                      <p>Apply Now!</p>
                    </div>

                    {userD?.email === 'emenikeaustine36@gmail.com' ||
                    userD?.email === 'ebenezer@wevesti.com' ||
                    userD?.email === 'foyemc@gmail.com' ||
                    userD?.email === 'adefisolabamisile@gmail.com' ||
                    userD?.email === 'adegbokanpaul@gmail.com' ||
                    userD?.email === 'olu@wevesti.com' ||
                    userD?.email === 'Damite2ky@gmail.com' ||
                    userD?.email === 'aisosaanike243@gmail.com' ||
                    userD?.email === 'ejeherimartins@gmail.com' ||
                    userD?.email === 'abimbolamusan@gmail.com' ||
                    userD?.email === 'tolulopevogue05@gmail.com' ||
                    userD?.email === 'balogunopeyemi67@gmail.com' ||
                    userD?.email === 'bunmiomotolani6@gmail.com' ||
                    userD?.email === 'walexdok@yahoo.com' ||
                    userD?.email === 'solamusan@gmail.com' ? (
                      <div
                        className="creditjourneypathway"
                        onClick={() => {
                          navigate('/pathways/visa');
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {' '}
                        <div>
                          <p>Submit Your Visa </p>
                          <p>Requirement Documents</p>
                        </div>
                        <img src={visabannerLogo} alt="" />
                      </div>
                    ) : (
                      // <div
                      //   className="oldcreditjourneypathway"
                      //   onClick={goToNova}
                      //   style={{ cursor: 'pointer' }}
                      // >
                      //   <p>Start Your Credit Journey</p>
                      //   <p>by Importing Your Credit Score</p>
                      // </div>
                      <div
                        className="creditjourneypathway"
                        onClick={() => {
                          navigate('/pathways/visa');
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {' '}
                        <div>
                          <p>Submit Your Visa </p>
                          <p>Requirement Documents</p>
                        </div>
                        <img src={visabannerLogo} alt="" />
                      </div>
                    )}

                    {/* </div> */}
                  </div>

                  <div className="d-flex justify-content-between mt-20">
                    <div className="titlesubtitle-left">
                      <Titlesubtitle
                        title="Available Pathways"
                        subtitle="Select one and start your migration journey"
                      />
                    </div>

                    <div
                      className="seemore-right ml-auto"
                      style={{ marginLeft: 'auto' }}
                    >
                      <p>
                        {/* href="/allpathways" */}
                        <a href="#" onClick={handleSeeAllPathwayClick}>
                          {' '}
                          See All{' '}
                        </a>{' '}
                        <i className="fas fa-arrow-right" />
                      </p>
                    </div>
                  </div>

                  <div
                    className="services-grid"
                    style={{ gap: '30px', marginBottom: '50px' }}
                  >
                    {loading && <Loader />}
                    {success && (
                      <>
                        {getRandomItemsmyPathways().map(
                          ({
                            id,
                            logo,
                            title,
                            description,
                            stagesObject,
                            creator,
                            pathwayCost,
                            country,
                            month,
                            pathwayVideo,
                            duration,
                            unit,
                          }) => (
                            <PathwayCard
                              key={id}
                              {...{
                                id,
                                logo,
                                title,
                                description,
                                stagesObject,
                                creator,
                                pathwayCost,
                                country,
                                month,
                                pathwayVideo,
                                duration,
                                unit,
                              }}
                            />
                          ),
                        )}
                      </>
                    )}

                    {/* Render the popup if isPopupOpen is true */}
                    {isPathwayPopupOpen && (
                      <Largemodal
                        open={isPathwayPopupOpen}
                        onClick={handleClosePathwayPopup}
                      >
                        <Titlesubtitle
                          title="Available Pathways"
                          subtitle="Select one and start your migration journey"
                        />
                        <div className="services-grid">
                          {loading && <Loader />}
                          {success && (
                            <>
                              {getAllItemsmyPathways().map(
                                ({
                                  id,
                                  logo,
                                  title,
                                  description,
                                  stagesObject,
                                  creator,
                                  pathwayCost,
                                  country,
                                  month,
                                  pathwayVideo,
                                  duration,
                                  unit,
                                }) => (
                                  <PathwayCard
                                    key={id}
                                    {...{
                                      id,
                                      logo,
                                      title,
                                      description,
                                      stagesObject,
                                      creator,
                                      pathwayCost,
                                      country,
                                      month,
                                      pathwayVideo,
                                      duration,
                                      unit,
                                    }}
                                  />
                                ),
                              )}
                            </>
                          )}
                        </div>
                      </Largemodal>
                    )}
                  </div>
                </section>

                {/* testimonial section begins */}
                <section className="sectionpathway about-sectionpathway">
                  <div className="d-flex justify-content-between margin">
                    <div className="titlesubtitle-left">
                      <Titlesubtitle
                        title="Testimonials"
                        subtitle="Check out success stories from users who moved abroad."
                      />
                    </div>

                    <div
                      className="seemore-right ml-auto"
                      style={{ marginLeft: 'auto' }}
                    >
                      <p>
                        <a href="#" onClick={handleSeeAllTestimonialClick}>
                          {' '}
                          See More{' '}
                        </a>{' '}
                        <i className="fas fa-arrow-right" />
                      </p>
                    </div>
                  </div>

                  <div className="testimony__inner">
                    <div
                      className="testimony__content widthcardscroll"
                      style={{ overflowY: 'auto' }}
                    >
                      {loading && <Loader />}
                      {success && (
                        <>
                          {getRandomItemstestimonialData().map(
                            ({ id, name, testimony, description, date }) => (
                              <SingleTestimonyCard
                                key={id}
                                {...{
                                  id,
                                  name,
                                  testimony,
                                  description,
                                  date,
                                }}
                              />
                            ),
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* Render the popup if isPopupOpen is true */}
                  {isTestimonialPopupOpen && (
                    <Largemodal
                      open={isTestimonialPopupOpen}
                      onClick={handleCloseTestimonialPopup}
                    >
                      <>
                        <Titlesubtitle
                          title="Testimonials"
                          subtitle="Check out success stories from users who moved abroad."
                        />
                        <div className="testimonymodal">
                          {loading && <Loader />}
                          {success && (
                            <>
                              {getRandomAlltestimonialData().map(
                                ({
                                  id,
                                  name,
                                  testimony,
                                  description,
                                  date,
                                }) => (
                                  // <Testimonycard
                                  //   key={id}
                                  //   {...{
                                  //     id,
                                  //     name,
                                  //     testimony,
                                  //     description,
                                  //     date,
                                  //   }}
                                  // />
                                  <SingleTestimonyCard
                                    key={id}
                                    {...{
                                      id,
                                      name,
                                      testimony,
                                      description,
                                      date,
                                    }}
                                  />
                                ),
                              )}
                            </>
                          )}
                        </div>
                      </>
                    </Largemodal>
                  )}
                </section>
                {/* testimonial section ends */}

                {/* growth communities section begins*/}
                <section className="sectionpathway ">
                  <div className="about-sectionpathway">
                    <div className="titlesubtitle-left">
                      <Titlesubtitle
                        title="Growth Communities"
                        subtitle="Join and start your migration journey"
                      />
                    </div>

                    <div className="seemore-right ml-auto">
                      <p>
                        {/* /feeds */}
                        <a href="#" onClick={handleSeeAllGroupClick}>
                          {' '}
                          See All{' '}
                        </a>{' '}
                        <i className="fas fa-arrow-right" />
                      </p>
                    </div>
                  </div>

                  <div className="growthcom__inner">
                    <div className="growthcom__content">
                      {userDatas.planType === 'BASIC_USER' ||
                      userDatas.planType === null
                        ? (getRandomItemsgroupData() ?? []).filter(function(
                            item,
                          ) {
                            // eslint-disable-next-line
                            for (var key in filt) {
                              if (
                                item.name === '0-1 Candidate US' ||
                                item.name === 'Business Class' ||
                                item.name === 'First Class' ||
                                item.name === '1000 Students'
                              )
                                return true;
                            }
                            // console.log('item', item);
                            return true;
                          })
                        : (
                            getRandomItemsgroupData() ?? []
                          ).map((data, index) => (
                            <Growthcommunity
                              image={data.profileUrl}
                              description={data.description}
                              id={data.id}
                              key={index}
                              name={data.name}
                              username={data.username}
                              type={data.type}
                              isActive={isActive}
                              planType={planType}
                              userData={data}
                            />
                          ))}
                    </div>
                  </div>

                  <div className="seemore-down">
                    <Platformbutton
                      name="See All"
                      type="secondary"
                      click={handleSeeAllGroupClick}
                    />
                  </div>

                  {/* Render the Immigration Webinar popup if isGroupPopupOpen is true */}

                  {isGroupPopupOpen && (
                    <Largemodal
                      open={isGroupPopupOpen}
                      onClick={() => setGroupPopupOpen(false)}
                    >
                      <>
                        <Titlesubtitle
                          title="Growth Communities"
                          subtitle="Join and start your migration journey"
                        />

                        <div className="growthmodal">
                          {userDatas.planType === 'BASIC_USER' ||
                          userDatas.planType === null
                            ? (getAllItemsgroupData() ?? []).filter(function(
                                item,
                              ) {
                                // eslint-disable-next-line
                                for (var key in filt) {
                                  if (
                                    item.name === '0-1 Candidate US' ||
                                    item.name === 'Business Class' ||
                                    item.name === 'First Class' ||
                                    item.name === '1000 Students'
                                  )
                                    return true;
                                }
                                // console.log('item', item);
                                return true;
                              })
                            : (
                                getAllItemsgroupData() ?? []
                              ).map((data, index) => (
                                <Growthcommunity
                                  image={data.profileUrl}
                                  description={data.description}
                                  id={data.id}
                                  key={index}
                                  name={data.name}
                                  username={data.username}
                                  type={data.type}
                                  isActive={isActive}
                                  planType={planType}
                                />
                              ))}
                        </div>

                        <button className="py-4 cursor-pointer w-full flex text-center mx-auto ">
                          <p className="scrollText">scroll </p>
                          <FaArrowDown className="ml-2 mr-1 text-li " />{' '}
                          <FaArrowUp />
                        </button>
                      </>
                    </Largemodal>
                  )}
                </section>
                {/* growth communities section ends*/}

                {/* Immigration webinar section begins */}
                <section className="sectionpathway">
                  <div className="d-flex justify-content-between mt-20">
                    <div className="titlesubtitle-left">
                      <Titlesubtitle
                        title="Immigration Webinar"
                        subtitle="Register and start your migration journey"
                      />
                    </div>

                    <div className="seemore-right ml-auto">
                      <p>
                        <a href="#" onClick={handleSeeAllWebinarClick}>
                          {' '}
                          See All{' '}
                        </a>{' '}
                        <i className="fas fa-arrow-right" />
                      </p>
                    </div>
                  </div>

                  <div className="webinar__inner">
                    <div className="webinar__content ">
                      {loading && <Loader />}
                      {success && (
                        <>
                          {webinarData.map(
                            ({
                              id,
                              title,
                              featuring,
                              date,
                              type,
                              image,
                              link,
                            }) => (
                              <Webinarcard
                                key={id}
                                {...{
                                  id,
                                  title,
                                  featuring,
                                  date,
                                  type,
                                  image,
                                  link,
                                }}
                              />
                            ),
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Render the popup if isPopupOpen is true */}
                  <Largemodal
                    open={isWebinarPopupOpen}
                    onClick={() => setWebinarPopupOpen(false)}
                  >
                    <div className="webinarmodal">
                      <Titlesubtitle
                        title="Immigration Webinar"
                        subtitle="Register and start your migration journey"
                      />
                      <div className="webinarmodal__container">
                        {loading && <Loader />}
                        {success && (
                          <>
                            {webinarData.map(
                              ({
                                id,
                                title,
                                featuring,
                                date,
                                type,
                                image,
                                link,
                              }) => (
                                <Webinarcard
                                  key={id}
                                  {...{
                                    id,
                                    title,
                                    featuring,
                                    date,
                                    type,
                                    image,
                                    link,
                                  }}
                                />
                              ),
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Largemodal>
                </section>
                {/* Immigration webinar section ends */}

                {/* Immigration blog section begins */}
                <section className="sectionpathway">
                  <div className="d-flex justify-content-between mt-20">
                    <div className="titlesubtitle-left">
                      <Titlesubtitle
                        title="Immigration Blog"
                        subtitle="Catch up on what you have missed."
                      />
                    </div>

                    <div className="seemore-right ml-auto">
                      <p>
                        {/* https://vesti.substack.com/ */}
                        <a href="#" onClick={handleSeeAllBlogClick}>
                          {' '}
                          See More{' '}
                        </a>
                        <i className="fas fa-arrow-right" />
                      </p>
                    </div>
                  </div>
                  <div
                    className="services-grid"
                    style={{
                      gap: '30px',
                      marginBottom: '50px',
                      width: '100%',
                      overflowY: 'auto',
                    }}
                  >
                    {loading && <Loader />}
                    {success && (
                      <>
                        {getRandomItemsblogData().map(
                          ({
                            id,
                            title,
                            image,
                            description,
                            date,
                            category,
                            url,
                          }) => (
                            <BlogCard
                              key={id}
                              {...{
                                title,
                                image,
                                description,
                                date,
                                category,
                                url,
                              }}
                            />
                          ),
                        )}
                      </>
                    )}
                  </div>

                  {/* Render the popup if isPopupOpen is true */}
                  {isBlogPopupOpen && (
                    <Largemodal
                      open={isBlogPopupOpen}
                      onClick={handleCloseBlogPopup}
                    >
                      <Titlesubtitle
                        title="Immigration Blog"
                        subtitle="Catch up on what you have missed."
                      />
                      <div className="services-grid">
                        {loading && <Loader />}
                        {success && (
                          <>
                            {getRandomAllblogData().map(
                              ({
                                id,
                                title,
                                image,
                                description,
                                date,
                                category,
                                url,
                              }) => (
                                <BlogCard
                                  key={id}
                                  {...{
                                    title,
                                    image,
                                    description,
                                    date,
                                    category,
                                    url,
                                  }}
                                />
                              ),
                            )}
                          </>
                        )}
                      </div>
                    </Largemodal>
                  )}
                </section>
                {/* Immigration blog section ends */}

                {/* Migration fees section begins */}
                <section className="sectionpathway">
                  <div className="about-sectionpathway">
                    <div className="titlesubtitle-left">
                      <Titlesubtitle
                        title="Migration Fees"
                        subtitle="Vesti provides numerous services."
                      />
                    </div>

                    <div className="seemore-right ml-auto">
                      <p>
                        {/* /merchants */}
                        <a href="#" onClick={handleSeeAllMerchantClick}>
                          {' '}
                          See All{' '}
                        </a>{' '}
                        <i className="fas fa-arrow-right" />
                      </p>
                    </div>
                  </div>

                  <div className="merchant-scroll">
                    <div className="d-flex">
                      {getRandomItemsMerchantData().map((item, index) => (
                        <div
                          // onClick={ navigate(`/merchants?price=${item.price}`)}
                          key={index}
                          className="p-3 d-flex flex-column justify-content-center align-items-center mr-3 merchant-card"
                          style={{
                            background: '#FFFFFF',
                            border: '1px solid #EAEAEA',
                            boxShadow: '0px 0px 5px #F5F5F5',
                            borderRadius: '10px',
                            height: '250px',
                            minWidth: '235px',
                          }}
                        >
                          <a href={`/merchants?price=${item.price}`}>
                            <img
                              src={item.image}
                              alt="profile"
                              style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '50%',
                              }}
                              className="d-block bg-dark mb-1"
                            />
                          </a>

                          <div className="text-uppercase mb-3">{item.name}</div>
                          <p className="text-center mb-3">{item.description}</p>
                          {/* <a
                                              href={`/merchants?price=${item.price}`}
                                              style={{ color: '#67A948', fontWeight: 500 }}
                                              


                                            // >    Pay Now <i className="fas fa-arrow-right" /> </a> */}

                          {/* <div>
                                          <div
                                            className="merchant-card__btn px-0"
                                            onClick={() => {
                                              setCurrent(amount)
                                              paymentHandler(custom)
                                            }
                                            }
                                          >
                                            {!custom ? 
                                              <span className="font-weight-bold mx-1"> Pay <i class="fas fa-arrow-right"></i> Now </span>
                                            : <span className="font-weight-bold mx-1"> {button}<i class="fas fa-arrow-right"></i> </span>}
                                            
                                            
                                          </div>
                                        </div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="merchant-scroll">
                    <div className="d-flex">
                      {/* Render the popup if isPopupOpen is true */}
                      {isMerchantPopupOpen && (
                        <Largemodal
                          open={isMerchantPopupOpen}
                          onClick={handleCloseMerchantPopup}
                        >
                          <div className="services-grids">
                            <>
                              <Titlesubtitle
                                title="Migration Fees"
                                subtitle="Vesti provides numerous services."
                              />
                              {getRandomAllMerchantData().map((item, index) => (
                                <div
                                  // onClick={ navigate(`/merchants?price=${item.price}`)}
                                  key={index}
                                  className="p-3 d-flex flex-column justify-content-center align-items-center mr-3  merchant-card"
                                  style={{
                                    background: '#FFFFFF',
                                    border: '1px solid #EAEAEA',
                                    boxShadow: '0px 0px 5px #F5F5F5',
                                    borderRadius: '10px',
                                    height: '250px',
                                    minWidth: '235px',
                                  }}
                                >
                                  <a href={`/merchants?price=${item.price}`}>
                                    <img
                                      src={item.image}
                                      alt="profile"
                                      style={{
                                        height: '60px',
                                        width: '60px',
                                        borderRadius: '50%',
                                      }}
                                      className="d-block bg-dark mb-1"
                                    />
                                  </a>

                                  <div className="text-uppercase mb-3">
                                    {item.name}
                                  </div>
                                  <p className="text-center mb-3">
                                    {item.description}
                                  </p>
                                </div>
                              ))}
                            </>
                          </div>
                        </Largemodal>
                      )}
                    </div>
                  </div>
                </section>
                {/* Migration fees section ends */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const MerchantPayment = ({
  title,
  description,
  paymentHandler,
  amount = '102,965.50',
  custom = false,
  setCurrent,
  button,
}) => {
  let dollarUSLocale = Intl.NumberFormat('en-US');

  // var perc = amount && (dollarUSLocale.format(amount.ngn.replace(/[^0-9.-]+/g,"") / 10));
  return (
    <div className="col-md-6 col-lg-4 mb-3 merchant-box">
      <div className="merchant-card p-4 textleft mb-3">
        <div className="merchant-card-container">
          <div>
            <h3 className="merchant-card__title">{title}</h3>
            {/* <p className="merchant-card__desc">{description}</p> */}
            {!custom && (
              <div className="mb-5 d-flex align-items-center flex-wrap">
                <svg
                  className="mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M12.2517 16.0171H3.74829C1.67822 16.0171 0 14.339 0 12.2688V3.77954C0 1.70947 1.67822 0.03125 3.74829 0.03125H12.2517C14.3218 0.03125 16 1.70947 16 3.77954V12.2688C16 14.339 14.3218 16.0171 12.2517 16.0171Z"
                      fill="#F2C94C"
                    />
                    <path
                      d="M12.25 0.03125H11C13.071 0.03125 14.75 1.70874 14.75 3.77795V12.2704C14.75 14.3397 13.071 16.0171 11 16.0171H12.25C14.321 16.0171 16 14.3397 16 12.2704V3.77795C16 1.70874 14.321 0.03125 12.25 0.03125Z"
                      fill="#AE8C26"
                    />
                    <path
                      d="M5.60537 7.59496L5.61928 6.99791C5.62478 6.76256 5.80422 6.56798 6.03847 6.54308L8.98037 6.23094C9.25881 6.2014 9.50087 6.42076 9.49856 6.70042L9.45144 12.3754L9.95107 12.4026C10.1997 12.4161 10.3943 12.6214 10.3943 12.8701V13.3644C10.3943 13.6224 10.1854 13.8318 9.92727 13.8327L6.17373 13.8464C5.91189 13.8474 5.7001 13.6339 5.70327 13.3724L5.70925 12.8826C5.71218 12.6357 5.90639 12.4336 6.1531 12.4205L6.68557 12.3924L6.69167 8.09361L6.05935 8.07384C5.80202 8.0659 5.59939 7.85204 5.60537 7.59496Z"
                      fill="white"
                    />
                    <path
                      d="M9.56241 3.66626C9.56241 2.74438 8.81436 1.99707 7.89163 1.99707C6.96902 1.99707 6.22098 2.74438 6.22098 3.66626C6.22098 4.58826 6.96902 5.33557 7.89163 5.33557C8.81436 5.33557 9.56241 4.58826 9.56241 3.66626Z"
                      fill="white"
                    />
                    <path
                      d="M9.95131 12.4023L9.45167 12.3751L9.49879 6.7003C9.50111 6.42052 9.25893 6.20116 8.98048 6.2307L8.06801 6.3275C8.17836 6.41356 8.25001 6.54771 8.24879 6.7003L8.20167 12.3751L8.70131 12.4023C8.94984 12.4159 9.14455 12.6212 9.14455 12.87V13.3642C9.14455 13.6222 8.93568 13.8317 8.6775 13.8325L6.11183 13.8419C6.13222 13.8445 6.15273 13.8463 6.17384 13.8463L9.9275 13.8326C10.1857 13.8317 10.3944 13.6222 10.3944 13.3642V12.87C10.3945 12.6212 10.1998 12.4159 9.95131 12.4023Z"
                      fill="#EAEAEA"
                    />
                    <path
                      d="M7.89174 1.99707C7.67079 1.99707 7.45998 2.04041 7.26674 2.11829C7.87978 2.3656 8.31252 2.96533 8.31252 3.66626C8.31252 4.36731 7.87978 4.96704 7.26674 5.21435C7.45998 5.29224 7.67079 5.33557 7.89174 5.33557C8.81447 5.33557 9.56252 4.58813 9.56252 3.66626C9.56252 2.74438 8.81447 1.99707 7.89174 1.99707Z"
                      fill="#EAEAEA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {/* Includes <span className="font-weight-bold mx-1">$40</span>{' '} */}
                Includes Vesti platform charge of
                <span className="font-weight-bold ml-1">
                  {/* (${amount?.usd_ten_percent}). */}
                  (₦
                  {amount?.usd_ten_percent < 40
                    ? dollarUSLocale.format(
                        amount.ngn.replace(/[^0-9.-]+/g, '') / 10,
                      )
                    : (40 * 600)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  ).
                  {/* {amount && (dollarUSLocale.format(amount.ngn.replace(/[^0-9.-]+/g,"") / 10))} */}
                  {/* {perc} */}
                  {/* (₦{(40 * 585).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}). */}
                </span>
              </div>
            )}
          </div>
          <div>
            <div
              className="merchant-card__btn px-0"
              onClick={() => {
                setCurrent(amount);
                paymentHandler(custom);
              }}
            >
              {!custom ? (
                <span className="font-weight-bold mx-1">
                  {' '}
                  Pay ₦{amount?.ngn} <i class="fas fa-arrow-right"></i> Now{' '}
                </span>
              ) : (
                <span className="font-weight-bold mx-1">
                  {' '}
                  {button}
                  <i class="fas fa-arrow-right"></i>{' '}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Testimonycard = props => {
  return (
    <div className={`domorecard`}>
      {/* {typeof props.loan} */}

      <div className="testimony-inner" style={{ display: 'flex' }}>
        <div className="left-testimonial">
          <img height="40px" width="40px" src={dummy} alt="Testimony" />
        </div>

        <div className="right-testimonial">
          <p className="font-18 testimonialname"> {props.name}</p>
          <p className="font-9 testimonialdate"> {props.date} </p>
        </div>
      </div>
      {/* <p>{props.data.subtitle}</p> */}

      <p className="font-14 font-vesti testimonialdescription">
        {props.testimony}
      </p>
    </div>
  );
};

export const Webinarcard = props => {
  var dispatch = useDispatch();
  var openUpdateModal = () => {
    (userData.planType === 'BASIC_USER' || userData.planType === null) &&
    props.type !== 'FREE' &&
    props.user !== 'premium'
      ? dispatch(openUpgradeBox())
      : (() => {})();
  };

  return (
    <div
      className="webinarcard webinarbackground"
      style={{ maxWidth: '300px', height: '20rem' }}
      onClick={() => openUpdateModal()}
    >
      {/* {typeof props.loan} */}
      <div className="webinar-main">
        <div className="webinar-left-title">
          <p className="" style={{ fontSize: '15px' }}>
            {props.title}
          </p>
        </div>

        <div className="webinar-right-title">
          <p className="webinar-type"> {props.type} </p>
        </div>
      </div>

      <div className="webinar-date"> {props.date} </div>

      <p className="webinar-subtitle" style={{ fontSize: '12px' }}>
        {props.featuring}
      </p>

      <div className="webinar-bottom">
        <div className="watch-now-webinar">
          <a
            className="webinar-left-title"
            href={props.link}
            rel="noopener noreferrer"
            target="_blank"
          >
            Watch Now <i className="fas fa-arrow-right" />
          </a>
        </div>

        <div className="webinar-right-down">
          <img height="100%" width="100%" src={webinarcalendar} alt="do more" />
        </div>
      </div>
    </div>
  );
};

export const SingleTestimonyCard = props => {
  return (
    <>
      <div className="testimonialcard">
        <div className="testimonialcard__top">
          <img
            className="img-fluid"
            src={props.image ? props.image : dummy}
            alt="image"
          />
          <p className="title">
            {props.name}
            <span className="text-muted">{props.date}</span>
          </p>
        </div>
        <p className="testimonialcard__text">{props.testimony}</p>
      </div>
    </>
  );
};

const mapStateToProps = ({
  kycupdate,
  domore,
  alertbox,
  transactions,
  common,
  pathway,
  openUpgradeBox,
  auth,
}) => {
  const { openBox, openUpgrade } = kycupdate;
  const { openFees } = domore;

  return {
    openFees,
  };
};

export default connect(mapStateToProps, {})(Pathways);
