import { WALLET_TRANS, VERIFY_USDACCOUNT, USD_TRANS } from 'appRedux/constants';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from './Common';

export const depositUsdViaCard = (url, payload, cb, cbb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    // const url = 'stripe/checkoutDepositStripe';
    const url = 'wallet/global-deposits';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'Payment link succesfully generated.',
          'Deposit',
          'success',
        );
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        window.open(res.data.data.url);
        setTimeout(() => {
          cb();
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Deposit', 'error');
        setTimeout(() => {
          cbb();
        }, 100);
      });
  };
};

export const depositUsdViaCardZambia = (url, payload, cb, cbb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    // const url = 'stripe/checkoutDepositStripe';
    const url = 'wallet/card/deposit';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'Payment link succesfully generated.',
          'Deposit',
          'success',
        );
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        window.open(res.data.data.trxUrl);
        setTimeout(() => {
          cb();
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Deposit', 'error');
        setTimeout(() => {
          cbb();
        }, 100);
      });
  };
};

export const depositUsdViaNaira = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    const url = 'wallet/conversion-usd';
    api
      .post(url, payload)
      .then(res => {
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          cb(5);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        setTimeout(() => {
          cb(6);
        }, 100);
      });
  };
};

export const depositGbpViaNaira = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    // const url = 'wallet/ngn-to-others';
    const url = 'wallet/ngn-to-other-currencies';
    api
      .post(url, payload)
      .then(res => {
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          cb(5);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        setTimeout(() => {
          cb(6);
        }, 100);
      });
  };
};

export const depositUsdViaCedis = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    const url = 'wallet/conversion-usd';
    api
      .post(url, payload)
      .then(res => {
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          cb(5);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        setTimeout(() => {
          cb(6);
        }, 100);
      });
  };
};

export const depositUsdViaAccount = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    const url = '/fbio/receive_money';
    api
      .post(url, payload)
      .then(res => {
        // openNotificationWithIcon(
        //   'Payment link succesfully generated.',
        //   'Deposit',
        //   'success',
        // );
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data },
        });
        setTimeout(() => {
          cb(res.data.data);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Deposit', 'error');
        // setTimeout(()=> {
        //   cb(5)
        // }, 100)
      });
  };
};

export const withdrawFboUSD = (payload, cb) => {
  return dispatch => {
    dispatch({ type: WALLET_TRANS, payload: { transLoading: true } });
    // const url = '/fbio/send_money';
    const url = '/fbo/initiate-ach';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'A transaction has been initiated',
          'Withdrawal',
          'success',
        );
        dispatch({
          type: WALLET_TRANS,
          payload: { transLoading: false, transMessage: res.data.message },
        });
        setTimeout(() => {
          cb(6);
        }, 100);
      })
      .catch(error => {
        dispatch({
          type: WALLET_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data.message || error.message,
          },
        });
        openNotificationWithIconErr(error.data.message, 'Withdrawal', 'error');
        setTimeout(() => {
          cb(7);
        }, 100);
      });
  };
};

// Verify account
export const verifyUserRouting = (payload, cb) => {
  return dispatch => {
    dispatch({ type: VERIFY_USDACCOUNT, payload: { verLoading: true } });
    api
      .get(`/fbio/verify_routing_number/${payload.routing}`)
      .then(res => {
        setTimeout(() => {
          cb(res.data.data.customer_name);
          //console.log(res.data.data.customer_name)
        }, 100);
        dispatch({
          type: VERIFY_USDACCOUNT,
          payload: { verifyAccount: true, verloading: false },
        });
      })
      .catch(error => {
        dispatch({
          type: VERIFY_USDACCOUNT,
          payload: { verifyAccount: false, verloading: false },
        });
        // dispatch({ type: WALLET_TRANS,payload:{loading:false} });
      });
  };
};

// trans table
export const fetchUsdTransactions = (payload, cb) => {
  return dispatch => {
    dispatch({ type: USD_TRANS, payload: { transLoading: true } });
    // const url = '/fbio/user-transactions';
    const url = '/fbo/transactions';
    api
      .get(url, payload)
      .then(res => {
        // console.log(res.data.data.filter(item => typeof item.fboId === null ))
        // dispatch({ type: USD_TRANS,payload:{usdTransactions:res.data.data.filter(item => !item.fboId),transLoading:false}});
        res.data.data.filter(item => item.fboId);
        dispatch({
          type: USD_TRANS,
          payload: { usdTransactions: res.data, transLoading: false },
        });
      })
      .catch(error => {
        dispatch({
          type: USD_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data?.message || error?.message,
          },
        });
      });
  };
};

export const fetchUsdBeneficiary = (payload, cb) => {
  return dispatch => {
    dispatch({ type: USD_TRANS, payload: { transLoading: true } });
    // const url = '/user/usd/beneficiary';
    const url = '/bank/user/beneficiaries?currency=USD';
    api
      .get(url, payload)
      .then(res => {
        // res.data.data.filter(item => item.fboId)
        dispatch({
          type: USD_TRANS,
          payload: { usdAccounts: res.data.data, transLoading: false },
        });
      })
      .catch(error => {
        // dispatch({ type: USD_TRANS,payload:{transLoading:false, transMessage:error.data?.message || error?.message}});
        return error;
      });
  };
};

export const addUsdBeneficiary = payload => {
  return dispatch => {
    dispatch({ type: USD_TRANS, payload: { transLoading: true } });
    // const url = '/user/usd/beneficiary';
    const url = '/bank/save-beneficiary';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(
          'USD Beneficiary',
          res.data.data.message,
          'success',
        );
      })
      .catch(error => {
        return error;
      });
  };
};

export const fetchFinTransactions = (payload, cb) => {
  return dispatch => {
    dispatch({ type: USD_TRANS, payload: { transLoading: true } });
    const url = `stripe/ListallFinancialAccountTransactions/${payload.id}`;
    api
      .get(url, payload)
      .then(res => {
        // res.data.data.filter(item => item.fboId)
        dispatch({
          type: USD_TRANS,
          payload: {
            finTransactions: res.data.data.transactions,
            transLoading: false,
          },
        });
      })
      .catch(error => {
        dispatch({
          type: USD_TRANS,
          payload: {
            transLoading: false,
            transMessage: error.data?.message || error?.message,
          },
        });
      });
  };
};
