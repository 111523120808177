import React from 'react';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import './depositmodes.scss';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Titlesubtitle } from '../common/titlesubtitle/titlesubtitle';
import { Platformbutton } from 'components/common/button/button';
// import Monoconnect from "components/bank/mono/mono";
import { Shortinfo } from 'components/common/shortinfo/shortinfo';
import { radio_ach, radio_card } from 'assets/assets';
import { Equivalence } from 'components/bank/equivalence';
import { formatterUSD, getNGNRate, removeCommaAmount } from 'helpers/utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export const Depositmodes = props => {
  // console.log('DepositModes props:', props);
  const userWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  // console.log(userWallet);
  const filteredData = userWallet?.filter(
    item =>
      item.bank &&
      //   item.default === true &&
      item.bank.hasOwnProperty('bankName') &&
      item.bank.hasOwnProperty('number'),
  );
  // console.log(filteredData);
  // console.log(filteredData[0]?.bank.number);

  var moveBack = () => {
    props.setStep(0);
  };

  var __renderAction = value => {
    switch (value) {
      case 'Providus':
        return props.depositProvidus();
      case 'Mono':
        return props.setStep(4);
      case 'Paystack':
        return props.depositPaystack(
          {
            amount: removeCommaAmount(props.amount) * 100,
            // charges:
            //   +formatterUSD.format(
            //     getNGNRate(removeCommaAmount(props.amount)),
            //   ) * 100,
            description: 'NGN wallet deposit',
          },
          () => props.cb(),
        );
      default:
        return (() => {})();
    }
  };

  var __renderBtnText = value => {
    switch (value) {
      case 'Providus':
        return 'See Account To Pay';
      case 'Mono':
        return 'Continue';
      case 'Paystack':
        return 'Initiate Deposit';
      default:
        return 'Continue';
    }
  };
  // var linked = false
  return (
    <div className="depositoptions">
      <div className="depositoptions__inner">
        <Titlesubtitle
          steps="Step 2 of 3"
          title="Select Payment Option"
          subtitle="Choose from our selected options"
        />
        <div className="depositoptions__inner center mt-2">
          <RadioOption
            image={radio_card}
            changed={props.setDepositOption}
            id="2"
            isSelected={props.option === 'Paystack'}
            label="Add cash via card"
            sublabel="You can add cash via your debit card."
            value="Paystack"
          />

          <div className="mb-2"></div>
          {props.option === 'Paystack' && (
            <Equivalence
              first="total amount you get"
              second=" "
              amount={''}
              equal={
                // '₦'+ formatterUSD.format(removeCommaAmount(props.amount))+' + ₦'+ formatterUSD.format(getNGNRate(removeCommaAmount(props.amount))) + ' Charge'
                '₦' +
                formatterUSD.format(
                  removeCommaAmount(props.amount) -
                    getNGNRate(removeCommaAmount(props.amount)),
                )
              }
            />
          )}

          <div className="mb-4"></div>

          {filteredData[0]?.bank.number && (
            <RadioOption
              image={radio_ach}
              changed={props.setDepositOption}
              id="3"
              isSelected={props.option === 'Providus'}
              label="Add cash via a bank transfer"
              sublabel="You can add cash via bank transfer to your Vesti Providus account"
              value="Providus"
            />
          )}
          {props.option === 'Linked' && (
            <Shortinfo
              subject={
                props.userData.monoAccountId
                  ? 'This will attract a fee of ₦50 charged from your Vesti wallet.'
                  : 'We do not support mobile banking for these banks- access,'
              }
            />
          )}
          {props.option && (
            <Backcontinue text="Confirm and  Continue" goBack={moveBack}>
              <Platformbutton
                type="normal"
                click={() => __renderAction(props.option)}
                name={__renderBtnText(props.option)}
              />
            </Backcontinue>
          )}
        </div>
      </div>
    </div>
  );
};

export const Depositmodesv2 = props => {
  // console.log('second', props);
  // const userWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  // console.log(userWallet);
  // const filteredData = userWallet?.filter(
  //   item =>
  //     item.bank &&
  //     //   item.default === true &&
  //     item.bank.hasOwnProperty('bankName') &&
  //     item.bank.hasOwnProperty('number'),
  // );
  // console.log('second', filteredData?.bank?.number);
  var __renderBtnAction = value => {
    switch (value) {
      case 'Account':
        return props.setStep(4);
      case 'Card':
        return props.setStep(1);
      case 'Wallet':
        return props.setStep(2);
      default:
        return 'Continue';
    }
  };
  // var linked = false
  return (
    <div className="depositoptions">
      <div className="depositoptions__inner">
        <Titlesubtitle
          steps="Step 1"
          // title={`Add Money (${getCurrencyName( props.currency.toLocaleUpperCase())})`}
          title={`Add Money (${props.currency.toLocaleUpperCase()})`}
          subtitle="Choose from our selected options"
        />
        <div className="depositoptions__inner center mt-2">
          <RadioOption
            image={radio_card}
            changed={props.setDepositOption}
            id="1"
            isSelected={props.option === 'Card'}
            label="Using card"
            sublabel="Add money using either a credit/debit card."
            value="Card"
          />
          <div className="mb-2 my-[20px]"></div>
          {props.currency === 'ngn' && (
            <RadioOption
              image={radio_ach}
              changed={props.setDepositOption}
              id="2"
              isSelected={props.option === 'Wallet'}
              label="From your Wallet"
              sublabel="Add money using either of your wallets."
              value="Wallet"
            />
          )}

          {/* {filteredData[0]?.bank?.number && (
            <RadioOption
              image={radio_ach}
              changed={props.setDepositOption}
              id="3"
              isSelected={props.option === 'GHBANK'}
              label="Add cash via a bank transfer"
              sublabel="You can add cash via bank transfer to your Vesti GHFinancial Account"
              value="GHBANK"
            />
          )} */}

          <div className="mb-2 my-[30px]"></div>
          <Platformbutton
            type="normal"
            disabled={!props.option ? true : false}
            click={() => __renderBtnAction(props.option)}
            name="Continue"
          />
        </div>
      </div>
    </div>
  );
};
