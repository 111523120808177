import { Backcontinue } from 'components/common/backcontinue/backcontinue';
import { Platformbutton } from 'components/common/button/button';
import { Clipboard } from 'components/common/clipboard/clipboard';
import { Numberedcheck } from 'components/common/listcheck/listcheck';
import { Newprompt } from 'components/common/prompt/prompt';
import { RadioOption } from 'components/common/radiobutton/radiobutton';
import { Shortinfo, Shortinfonew } from 'components/common/shortinfo/shortinfo';
import {
  Smalltitlesubtitle,
  Titlesubtitle,
} from 'components/common/titlesubtitle/titlesubtitle';
import { useAmount, useStep } from 'helpers/hooks';
// import { removeCommaAmount } from "helpers/utils";
import React, { useEffect, useState } from 'react';
import { Equivalence } from '../equivalence';
// import { Compeletetrans, Stepone } from './fbo';
import fprompt from 'assets/fboprompt.svg';
import { radio_cardusd, radio_ngnusd } from 'assets/assets';
import './styles.scss';
import { connect, useDispatch } from 'react-redux';
import {
  depositUsdViaAccount,
  depositUsdViaCard,
  depositGbpViaNaira,
  depositUsdViaCedis,
} from 'appRedux/actions/usdtrans';
import {
  fetchTransactionOTP,
  localDepositViaCardOther,
} from 'appRedux/actions/wallets';
import {
  __renderRouting,
  formatterUSD,
  getCardRate,
  getUsdDepositRate,
  removeCommaAmount,
} from 'helpers/utils';
import { Success } from 'components/common/success/success';
import errorsvg from 'assets/newerror.svg';
// import { usdAccountDeposit } from 'helpers/data';
import SimplePlaidLink from 'components/plaidlink/plaidlink.tsx';
import { fetchLinkAccount } from 'appRedux/actions/Common';
// import { radio_plaidusd } from 'assets/assets';
import { AlertError } from 'components/common/alertboxes/alertboxes';
import { Textbal } from '../littlebalance/littlebalance';
import infob from 'assets/infob.svg';
import api from 'appRedux/api';
import { Gbpbalance } from './fbo';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import { Transreview } from 'components/common/transactionreview/review';
import { Comptransaction } from 'components/common/completetransaction/comptransaction';

const Gbpdeposit = props => {
  // console.log('GBP PROPS:', props);
  const dispatch = useDispatch();
  const { amount, handleAmount } = useAmount(0);
  const [selected, setSelect] = useState();
  const [rate, setRate] = useState('');
  const [account, setAccount] = useState();
  const [pin, setPin] = useState();
  const { step, nextStep, previousStep, setStep } = useStep(0);

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        // setWithdrawalCharges(res.data.data.data.ghsWithdrawalCharges / 100);
        setRate(res.data?.data?.data[0]);
        // setLoading(false);
      })
      .catch(err => err);
  }, []);

  // console.log("Rate: ",rate);

  const __renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            rate={parseInt(rate?.ngnToGbpConversionRate / 100)}
            setRate={setRate}
            type="Deposit"
            balance={props.balance}
            amount={amount}
            handleAmount={handleAmount}
            continue={nextStep}
            fullname={props.fullname}
            closeModal={props.closeModal}
            currency={props.name}
          />
        );
      case 1:
        return (
          <Steptwo
            balance={props.balance}
            // rate={parseInt(rate?.merchantRate / 100)}
            rate={parseInt(rate?.ngnToGbpConversionRate / 100)}
            setRate={setRate}
            amount={amount}
            selected={selected}
            fullname={props.fullname}
            setSelect={setSelect}
            setStep={setStep}
            viaCard={props.depositUsdViaCard}
            viaAccount={props.depositUsdViaAccount}
            viaCardOther={props.localDepositViaCardOther}
            closeModal={props.closeModal}
            back={previousStep}
            setAccount={setAccount}
            loading={props.transLoading}
            fetchLinkAccount={props.fetchLinkAccount}
            ngnbal={props.ngnbalance}
            ghsbal={props.ghsbalance}
            userWallets={props.userWallets}
            currency={props.name}
          />
        );
      case 2:
        return (
          <Accountdetails
            account={account}
            setStep={setStep}
            userWallets={props.userWallets}
            currency={props.name}
            closeModal={props.closeModal}
            back={previousStep}
          />
        );
      case 4:
        // NGN conversion
        return (
          <Compeletetrans
            rate={parseInt(rate?.ngnToGbpConversionRate / 100)}
            setRate={setRate}
            balance={props.balance}
            selected={selected}
            refetch={props.refetch}
            data={{
              amount: removeCommaAmount(amount).toLocaleString('en-us'),
              charges: (
                getUsdDepositRate(removeCommaAmount(amount)) *
                parseInt(rate?.ngnToGbpConversionRate / 100)
              )
                .toFixed(2)
                .toLocaleString('en-us'),
              amountNGN: formatterUSD
                .format(
                  removeCommaAmount(amount) *
                    parseInt(rate?.ngnToGbpConversionRate / 100),
                )
                .toLocaleString('en-us'),
            }}
            back={() => setStep(1)}
            loading={props.transLoading}
            handlePin={setPin}
            type="deposit"
            finish={() =>
              props.depositGbpViaNaira(
                {
                  amount: removeCommaAmount(amount) * 100,
                  // amount:
                  //   removeCommaAmount(amount)
                  //   *
                  //   parseInt(rate?.ngnToGbpConversionRate / 100)
                  charges: +formatterUSD.format(
                    formatterUSD.format(
                      getUsdDepositRate(removeCommaAmount(amount)),
                    ) * 100,
                  ),
                  currency: 'GBP',
                  transactionOtp: pin,
                },
                value => setStep(value),
              )
            }
          />
        );
      case 3:
        return (
          <Aboutnumb
            setStep={setStep}
            closeModal={props.closeModal}
            back={previousStep}
          />
        );
      case 5:
        return (
          <Success
            title="Successful"
            subtitle={props.transMessage}
            button="Okay, thank you"
            onClick={() => {
              props.refetch();
              props.closeModal();
            }}
          />
        );
      case 6:
        return (
          <Success
            image={errorsvg}
            title={`Failed`}
            subtitle={props.transMessage}
            onClick={() => setStep(0)}
            button="Try Again"
            type="error"
          />
        );
      case 7:
        // Ghana Cedis conversion
        return (
          <Compeletetrans
            rate={parseInt(rate?.merchantRate / 100)}
            selected={selected}
            balance={props.balance}
            refetch={props.refetch}
            dispatch={dispatch}
            data={{
              amount: removeCommaAmount(amount).toLocaleString('en-us'),
              charges:
                getUsdDepositRate(removeCommaAmount(amount)) *
                parseInt(rate?.merchantRate / 100)
                  .toFixed(2)
                  .toLocaleString('en-us'),
              amountGHS: formatterUSD
                .format(
                  removeCommaAmount(amount) *
                    parseInt(rate?.merchantRate / 100),
                )
                .toLocaleString('en-us'),
            }}
            back={() => setStep(1)}
            loading={props.transLoading}
            handlePin={setPin}
            type="deposit"
            finish={() =>
              props.depositGbpViaNaira(
                {
                  amount: removeCommaAmount(amount) * 100,
                  charges: +formatterUSD.format(
                    formatterUSD.format(
                      getUsdDepositRate(removeCommaAmount(amount)),
                    ) * 100,
                  ),
                  currency: 'GHS',
                  transactionOtp: pin,
                },
                value => setStep(value),
              )
            }
          />
        );

      default:
        return <></>;
    }
  };
  return <>{__renderSteps()}</>;
};

const Stepone = props => {
  // console.log('Stepone:', props);
  // eslint-diasble-next-line no-unused-vars
  var strippedAmount = parseFloat(
    props.amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/,/g, ''),
  );
  var __renderCheck = () => {
    switch (props.type) {
      // case 'Deposit':
      //   return (
      //     strippedAmount !== 0 &&
      //     strippedAmount > 50000 && (
      //       <AlertError
      //         body={`We do not allow users to fund amount less than $5,000 or higher than  $50,000.`}
      //       />
      //     )
      //   );
      case 'Withdrawal':
        return (
          removeCommaAmount(props.amount) > 0 &&
          removeCommaAmount(props.amount) > props.balance && (
            <AlertError
              body={`You have do not have up to £${props.amount} in your GBP wallet,please
                fund your account to continue.`}
            />
          )
        );

      default:
        return <></>;
    }
  };

  return (
    <div className="fbo__bigcol">
      <Titlesubtitle
        // title="Amount"
        title={`${
          props.type === 'Deposit'
            ? `Add Money (GBP)`
            : `Withdrawal Money (GBP)`
        } `}
        subtitle={`Enter amount you will like to ${
          props.type === 'Deposit' ? 'add' : 'withdraw'
        } to your wallet`}
      />

      <div className="fbo__top">
        <Gbpbalance balance={props.balance} />
        <div className="fbo__col">
          <Amountinputcurrency
            type="text"
            currency="£"
            name="amount"
            label="Amount in GBP"
            value={
              props.amount === 'NaN' ? 0 : props.amount?.toLocaleString('en-US')
            }
            disabled={false}
            placeholder="Enter amount to deposit"
            onChange={props.handleAmount}
            pattern="[0-9,.]*"
          >
            {/* <Shortinfo image={info} subject="Amount should be greater than £9" /> */}
          </Amountinputcurrency>
          {removeCommaAmount(props.amount) < 0 ||
            (removeCommaAmount(props.amount) > 50000 && (
              <AlertError
                body={`We do not allow users to fund amount less than £1 or higher than £50,000.`}
              />
            ))}
          {__renderCheck()}
        </div>
      </div>
      <div className="mb-2"></div>
      <div className="fbo__btns">
        {props.type === 'Deposit' ? (
          <Platformbutton
            name="Continue"
            type="normal"
            click={props.continue}
            disabled={
              (removeCommaAmount(props.amount) < 0 &&
                removeCommaAmount(props.amount) > 50000) ||
              removeCommaAmount(props.amount) === 0 ||
              props.amount === 'NaN'
                ? true
                : false
            }
          />
        ) : (
          <Platformbutton
            name="Continue"
            type="normal"
            click={props.continue}
          />
        )}
      </div>
    </div>
  );
};

const Steptwo = props => {
  // console.log('Steptwo : ', props);
  const [currencyExists, setCurrencyExists] = useState({});

  useEffect(() => {
    const isCurrencyExists = currencyToCheck =>
      props.userWallets.some(
        item => item.currency.toLowerCase() === currencyToCheck.toLowerCase(),
      );

    const currenciesToCheck = ['NGN', 'GHS', 'ZMW']; // Add more currencies

    const currencyResults = {};
    currenciesToCheck.forEach(currency => {
      currencyResults[currency] = isCurrencyExists(currency);
    });

    setCurrencyExists(currencyResults);
  }, [props.userWallets]);

  // console.log(currencyExists);

  var handleLinking = payload => {
    props.fetchLinkAccount(
      '/bank/link_account',
      {
        publicToken: payload.publicToken,
        accountID: payload.accountID,
        amount: removeCommaAmount(props.amount) * 100,
        charges: 50,
        description: 'GBP wallet Funding',
      },
      () => props.setStep(5),
      () => props.setStep(6),
    );
  };

  var charge = formatterUSD
    .format(removeCommaAmount(props.amount) * 0.049)
    .toLocaleString('en-US');

  var __rendeBtn = () => {
    // console.log('selected props:', props);
    switch (props.selected) {
      case 'CARD':
        return (
          <Platformbutton
            type="normal"
            name="Initiate Deposit"
            click={() =>
              props.viaCardOther(
                {
                  currency: props.currency.toLocaleUpperCase(),
                  amount: removeCommaAmount(props.amount) * 100,
                  charges:
                    removeCommaAmount(
                      formatterUSD.format(
                        +getCardRate(
                          removeCommaAmount(props.amount),
                          props.name,
                        ),
                      ),
                    ) * 100,
                },
                () => props.closeModal(),
                // () => props.setStep(6),
              )
            }
            disabled={props.loading ? true : false}
          />
        );
      // ((formatterUSD.format(charge) * 100).toFixed(2))
      case 'NAIRA':
        return (
          <Platformbutton
            type="normal"
            name="Continue"
            disabled={
              props.ngnbal < removeCommaAmount(props.amount) * props.rate
                ? true
                : false
            }
            click={() => props.setStep(4)}
          />
        );
      case 'CEDIS':
        return (
          <Platformbutton
            type="normal"
            name="Continue"
            disabled={
              props.ghsbal < removeCommaAmount(props.amount) * props.rate
                ? true
                : false
            }
            click={() => props.setStep(7)}
          />
        );
      case 'ZMW':
        return (
          <Platformbutton
            type="normal"
            name="Continue"
            disabled={
              props.zmwbal < removeCommaAmount(props.amount) * props.rate
                ? true
                : false
            }
            click={() => props.setStep(7)}
          />
        );
      case 'ACCOUNT':
        return (
          <Platformbutton
            type="normal"
            name="Continue"
            click={() =>
              props.viaAccount(
                {
                  amount: removeCommaAmount(props.amount) * 100,
                  senderName: props.fullname,
                  // charges:+(formatterUSD.format(getUsdDepositRate(removeCommaAmount(props.amount))*props.rate)* 100)
                  charges: +removeCommaAmount(
                    formatterUSD.format(charge) * 100,
                  ),
                },
                data => {
                  props.setAccount(data);
                  props.setStep(2);
                },
                () => props.setStep(4),
              )
            }
            disabled={props.loading ? true : false}
          />
        );
      case 'Plaid':
        return <SimplePlaidLink handleLinking={handleLinking} />;
      default:
        return '';
    }
  };
  return (
    <div className="fbo__bigcol">
      <Titlesubtitle
        steps="1 of 1"
        title="Add Money"
        subtitle="Choose from our varieties of options."
      />
      <div className="fbo__top">
        <div className="fbo__col --list">
          <span className="fbo__option">
            <RadioOption
              image={radio_cardusd}
              changed={() => props.setSelect('CARD')}
              id="0"
              isSelected={props.selected === 'CARD'}
              label="Add money using Card"
              sublabel="Add money using either your credit or debit card."
              value="CARD"
            />
            {currencyExists['NGN'] && (
              <RadioOption
                image={radio_ngnusd}
                changed={() => props.setSelect('NAIRA')}
                id="1"
                isSelected={props.selected === 'NAIRA'}
                label="Add money using Naira wallet"
                sublabel="Add money using Naira wallet"
                value="NAIRA"
              />
            )}
            {/* {currencyExists['GHS'] && (
              <RadioOption
                image={radio_ngnusd}
                changed={() => props.setSelect('CEDIS')}
                id="2"
                isSelected={props.selected === 'CEDIS'}
                label="Add money using Cedis wallet"
                sublabel="Add money using Cedis wallet"
                value="CEDIS"
              />
            )} */}
            {/* {currencyExists['ZMW'] && (
              <RadioOption
                image={radio_ngnusd}
                changed={() => props.setSelect('ZMW')}
                id="3"
                isSelected={props.selected === 'ZMW'}
                label="Add money using Kwacha wallet"
                sublabel="Add money using Kwacha wallet"
                value="ZMW"
              />
            )} */}

            {props.selected === 'CARD' && (
              <Shortinfonew image={infob}>
                <p>
                  This option opens a new tab, so allow your browser to open a
                  new tab.
                </p>
              </Shortinfonew>
            )}

            {props.selected === 'CARD' && (
              <Equivalence
                first="total amount "
                second=" "
                amount={''}
                equal={
                  '£' +
                  formatterUSD.format(removeCommaAmount(props.amount)) +
                  ' + £' +
                  formatterUSD
                    .format(removeCommaAmount(props.amount) * 0.049)
                    .toLocaleString('en-US') +
                  ' Charge'
                }
              />
            )}

            {/* Naira deposit */}
            {props.selected === 'NAIRA' && (
              <Equivalence
                first={``}
                second={` `}
                amount={`@${props.rate}, £${removeCommaAmount(
                  props.amount,
                ).toLocaleString('en-us')} `}
                equal={
                  '₦' +
                  formatterUSD
                    .format(removeCommaAmount(props.amount) * props.rate)
                    .toLocaleString('en-US') +
                  ' + ₦' +
                  formatterUSD.format(
                    getUsdDepositRate(removeCommaAmount(props.amount)) *
                      props.rate,
                  ) +
                  ' charge.'
                }
                // (getUsdDepositRate(removeCommaAmount(props.amount)) * props.rate).toFixed(2)
              />
            )}

            {/* cedis deposit */}
            {props.selected === 'CEDIS' && (
              <Equivalence
                first={``}
                second={` `}
                amount={`@${props.rate}, £${removeCommaAmount(
                  props.amount,
                ).toLocaleString('en-us')} `}
                equal={
                  '₵' +
                  formatterUSD
                    .format(removeCommaAmount(props.amount) * props.rate)
                    .toLocaleString('en-US') +
                  ' + ₵' +
                  formatterUSD.format(
                    getUsdDepositRate(removeCommaAmount(props.amount)) *
                      props.rate,
                  ) +
                  ' charge.'
                }
              />
            )}

            {/* ZMW deposit */}
            {props.selected === 'ZMW' && (
              <Equivalence
                first={``}
                second={` `}
                amount={`@${props.rate}, £${removeCommaAmount(
                  props.amount,
                ).toLocaleString('en-us')} `}
                equal={
                  '₵' +
                  formatterUSD
                    .format(removeCommaAmount(props.amount) * props.rate)
                    .toLocaleString('en-US') +
                  ' + ₵' +
                  formatterUSD.format(
                    getUsdDepositRate(removeCommaAmount(props.amount)) *
                      props.rate,
                  ) +
                  ' charge.'
                }
              />
            )}
          </span>
          {/* <RadioOption
            image={radio_plaidusd}
            changed={() => props.setSelect('Plaid')}
            id="4"
            isSelected={props.selected === 'Plaid'}
            label="Add money using an account"
            sublabel="Deposit directly  from your account."
            value="Plaid"
          /> */}

          {/* <Equivalence
            first="Charge of 2.9% of "
            second="you get "
            amount={removeCommaAmount(props.amount).toLocaleString('en-US')}
            equal={
              '£' +
              (removeCommaAmount(props.amount) * 0.029).toLocaleString('en-US')
            }
          /> */}
        </div>
      </div>

      <div className="fbo__scol">
        {props.selected === 'NAIRA' &&
          props.ngnbal <= removeCommaAmount(props.amount) * props.rate && (
            <AlertError
              body={`You do not have up to ${'₦' +
                formatterUSD
                  .format(removeCommaAmount(props.amount) * props.rate)
                  .toLocaleString('en-US')} in your Naira wallet.`}
            />
          )}

        {props.selected === 'CEDIS' &&
          props.ghsbal <= removeCommaAmount(props.amount) * props.rate && (
            <AlertError
              body={`You do not have up to ${'₵' +
                formatterUSD
                  .format(removeCommaAmount(props.amount) * props.rate)
                  .toLocaleString('en-US')} in your Cedis wallet.`}
            />
          )}
        {props.selected && (
          <Backcontinue goBack={() => props.back()}>
            {__rendeBtn()}
          </Backcontinue>
        )}
        {props.selected === 'NAIRA' && (
          <Textbal
            wallet="Naira Wallet"
            bal={formatterUSD.format(props.ngnbal)}
            currency="₦"
          />
        )}
        {props.selected === 'CEDIS' && (
          <Textbal
            wallet="Cedis Wallet"
            bal={formatterUSD.format(props.ghsbal)}
            currency="₵"
          />
        )}
      </div>
    </div>
  );
};

const Accountdetails = props => {
  var data = [
    {
      name: 'Account Number',
      value: props.account.accountNumber,
    },
    {
      name: ' Bank Name',
      value: props.account.bankName,
    },
    {
      name: 'Routing Number',
      value: props.account.routingNumber,
    },
    {
      name: 'Address',
      value: props.account.address,
    },
    {
      name: 'Transaction Reference',
      value: props.account.trxRef,
    },
  ];

  return (
    <Newprompt img={fprompt}>
      <div className="fbo__bigccol">
        <Titlesubtitle
          title="Account Details"
          subtitle="Attached below are the details of your Founders account."
        />
        <div className="fbo__row">
          {data.map((item, index) => (
            <Clipboard key={index} title={`${item.name}`} link={item.value} />
          ))}
        </div>

        <div className="fbo__ccol">
          <Shortinfo subject="This is Eligible for FDIC insurance up to $250K through our bank partners." />
          <Platformbutton
            type="slant"
            name="What are these numbers ?"
            click={() => props.setStep(3)}
          />
        </div>

        {/* <div className="mb-2"></div>  */}
        <Platformbutton
          type="normal"
          name="Back"
          click={() => props.setStep(1)}
        />
      </div>
    </Newprompt>
  );
};

const Aboutnumb = props => {
  var data = [
    {
      name: 'Account Number',
      value: '123456789011231231231323',
    },
    {
      name: 'Routing Number',
      value: '123456789011231231231323',
    },
  ];

  return (
    <Newprompt img={fprompt}>
      <Titlesubtitle
        title="Bank Transfers"
        subtitle="How to transfer money from another bank into the account details."
      />

      <div className="fbo__col">
        <Numberedcheck
          index={1}
          body="Find the transfers section of the bank’s mobile app or website."
        />
        <Numberedcheck
          index={2}
          body="You will need to add the bank details as an external
                    account, enter your routing and account number "
        >
          <div className="fbo__list">
            {data.map((item, index) => (
              <Smalltitlesubtitle
                key={index}
                title={item.name}
                subtitle={item.value}
              />
            ))}
          </div>
        </Numberedcheck>
        <Numberedcheck
          index={3}
          body="Select the Checking account option as
                    the account type"
        />
      </div>

      <Backcontinue goBack={() => props.setStep(2)}>
        <Platformbutton
          type="normal"
          name="Done"
          click={() => props.closeModal()}
        />
      </Backcontinue>
    </Newprompt>
  );
};

const Compeletetrans = props => {
  // console.log('Compeletetrans props :', props);
  return (
    <div className="transfinancreview">
      <div className="transfinancreview --review">
        {props.type === 'deposit' && props.selected === 'NAIRA' ? (
          <Transreview
            data={[
              { title: 'Transaction Type', value: 'Deposit' },
              { title: 'Currency', value: '(£) GBP' },
              { title: 'Amount', value: `£${props.data.amount}` },
              {
                title: `Amount NGN`,
                value: `₦${props.data.amountNGN}`,
              },
              { title: `Rate `, value: ` ${props.rate}` },
            ]}
          />
        ) : props.type === 'deposit' && props.selected === 'CEDIS' ? (
          <Transreview
            data={[
              { title: 'Transaction Type', value: 'Deposit' },
              { title: 'Currency', value: '(£) GBP' },
              { title: 'Amount', value: `£${props.data.amount}` },
              {
                title: `Amount GHS`,
                value: `₵${props.data.amountGHS}`,
              },
              { title: `Rate `, value: ` ${props.rate}` },
              { title: `Charges`, value: ` ₵${props.data.charges}` },
              // { title: `Charges`, value: ` ₵${props.data.charges.toFixed(2)}` },
              // Math.ceil(parseFloat( (props.data.amount * props.rate))).toLocaleString('en-US')
            ]}
          />
        ) : props.type === 'deposit' && props.selected === 'ZMW' ? (
          <Transreview
            data={[
              { title: 'Transaction Type', value: 'Deposit' },
              { title: 'Currency', value: '(£) GBP' },
              { title: 'Amount', value: `£${props.data.amount}` },
              {
                title: `Amount GHS`,
                value: `₵${props.data.amountGHS}`,
              },
              { title: `Rate `, value: ` ${props.rate}` },
              { title: `Charges`, value: ` ₵${props.data.charges}` },
              // { title: `Charges`, value: ` ₵${props.data.charges.toFixed(2)}` },
              // Math.ceil(parseFloat( (props.data.amount * props.rate))).toLocaleString('en-US')
            ]}
          />
        ) : (
          <Transreview
            data={[
              { title: 'Transaction Type', value: 'Wire Transfer' },
              { title: 'Currency', value: '(£) GBP' },
              { title: 'Amount', value: `$${props.data.amount}` },
              { title: 'Recipient Account', value: props.data.account_number },
              {
                title: __renderRouting(props.data.type),
                value: props.data.routing_number,
              },
              // {title:'Recipient Bank',value:props.data.bank_name},
              { title: 'Fee %', value: '1.3%' },
              // ,{title:'Wire Charge', value:'$25'} ,
              { title: 'Total Fee', value: `£` + props.data.fee },
              // {title:'Fee', value :` $${Math.ceil(parseFloat( (props.data.amount * 0.013))).toLocaleString('en-US')}`},
              { title: 'Total Amount', value: `£` + props.data.total },
            ]}
          />
        )}
      </div>

      <Comptransaction
        fetcher={fetchTransactionOTP}
        setPin={props.handlePin}
        loading={props.loading}
        goBack={() => props.back()}
        btn={`Initiate Withdrawal`}
        onFinish={() => alert('')}
        len={4}
        lower={true}
      >
        <Backcontinue text="Continue" goBack={() => props.back()}>
          <Platformbutton
            name={
              props.type === 'deposit'
                ? 'Initiate Deposit'
                : 'Initiate Withdrawal'
            }
            type="normal"
            click={() => props.finish()}
          />
        </Backcontinue>
      </Comptransaction>
      <p style={{ textAlign: 'center' }}>
        Didn't get the code?{' '}
        <span
          style={{ cursor: 'pointer', color: 'green' }}
          onClick={() => props?.dispatch(fetchTransactionOTP())}
        >
          Resend
        </span>
      </p>
    </div>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transLoading, transMessage } = transactions;
  return {
    transLoading,
    transMessage,
  };
};

const mapDispatchToProps = {
  depositUsdViaAccount,
  depositUsdViaCard,
  fetchLinkAccount,
  depositGbpViaNaira,
  depositUsdViaCedis,
  localDepositViaCardOther,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gbpdeposit);
