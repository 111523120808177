import React, { useState } from 'react';
import { fellngsGf, emojihappy } from 'assets/feedPageAssets';
import { FaTimes } from 'react-icons/fa';
import './EmojiPicker.css'

const EmojiPicker = ({ onSelect }) => {
  const [showPicker, setShowPicker] = useState(false);

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const emojis = [
    '😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂',
    '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', 
    '😝', '🤓', '😡', '🥳', '🫣', '🤗', '🤫', '🙄', 
    '🤕', '🤮', '👏', '👍', '👊'
    // Add more emojis as needed
  ];

  return (
    <div>
        
      <button onClick={togglePicker} className=''> <img src={emojihappy} alt='imgIcon' style={{marginRight:'0px'}}/></button>
      {showPicker && (
        <>
       
        <div className="epickerbg absolute " style={{maxWidth:'20rem', right:'70px'}}>
          {emojis.map((emoji, index) => (
            <span key={index} className='cursor-pointer mx-6' onClick={() => onSelect(emoji)} style={{fontSize:'20px'}}>
              {emoji}
            </span>
          ))}
        </div>
        </>
      )}
    </div>
  );
};

export default EmojiPicker;
