import { sendDeletionCode, deleteProfile } from 'appRedux/actions/profile';
import { Platformbutton } from 'components/common/button/button';
import DividedPinInput from 'components/common/DividedPinInput';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Success } from 'components/common/success/success';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import errorsvg from 'assets/errorsvg.svg';
import bin from 'assets/deletecard.svg';
import './accountdetails.scss';
import { Newprompt } from 'components/common/prompt/prompt';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Backcontinue } from 'components/common/backcontinue/backcontinue';

const DeleteAcct = props => {
  const [step, setStep] = useState(0);
  const [others, setOthers] = useState('');
  const [code, setCode] = useState('');

  const [selected, setSelected] = useState();

  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth?.userData);

  var options = [
    {
      label: 'Poor Services',
      value: 'Poor Services',
    },
    {
      label: 'Virtual Card',
      value: 'Virtual Card',
    },
    {
      label: 'Poor Account Reconciliation',
      value: 'Poor Account Reconciliation',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ];

  const handleChange = selectedOption => {
    setSelected(selectedOption);
  };

  const resend = () => {
    dispatch(sendDeletionCode());
    props.refetch();
  };

  const finalVerify = () => {
    var data = selected.value?.includes('Others')
      ? {
          code: code,
          agreeWithTermsAndCondition: true,
          reason: [others],
        }
      : {
          code: code,
          agreeWithTermsAndCondition: true,
          reason: selected.value,
        };

    props.deleteProfile(data, setStep);
  };

  var _renderSteps = () => {
    switch (step) {
      case 0:
        return (
          <Newprompt img={bin}>
            <Titlesubtitle
              title="Delete Account"
              subtitle={` Hello, ${userData.firstName} why do you want to delete
                        your account?`}
            />
            <>
              <Singleselect
                value={selected}
                options={options}
                name="reason"
                placeholder="Due to ..."
                onChange={value => handleChange(value)}
              />
            </>
            <span className="mt-4"></span>
            {selected?.value?.includes('Others') && (
              <Inputfloat
                label="Others"
                type="text"
                value={others}
                placeholder={`Other reason`}
                onChange={e => {
                  setOthers(e.target.value);
                }}
              />
            )}
            <span className="mt-4"></span>
            <Backcontinue
              back="Cancel"
              color="red"
              goBack={() => props.closeModal()}
            >
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => finalVerify()}
                disabled={
                  selected?.length !== 0 || others !== '' ? false : true
                }
              />
            </Backcontinue>
          </Newprompt>
        );
      case 1:
        return (
          <div className="deleteacct-container">
            <div className="deleteacct-inner-container">
              <div className="__top">
                <img src={bin} alt="" />
              </div>
              <div className="__middle">
                <p>Delete Account</p>
                <p>
                  Hello, {userData.firstName} a four digits code was sent to
                  your mail {''}
                  {userData.email}.
                </p>
                <DividedPinInput len={4} onChange={setCode} />
                <p className="resend" onClick={() => resend()}>
                  I did not get code,resend
                </p>
              </div>
              <div className="__bottom">
                <button
                  className="delete-account"
                  disabled={code.length !== 4}
                  onClick={() => finalVerify()}
                >
                  Delete Account
                </button>
                <button className="cancel" onClick={() => props.closeModal()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <Success
            title="Successful"
            subtitle={
              'Request successfully received and will be processed within 24Hrs.'
            }
            onClick={() => props.closeModal()}
            button="Okay, Thank you."
          />
        );

      case 3:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Failed"
            subtitle={props.message}
            button="Try Again"
            onClick={() => setStep(1)}
          />
        );
      default:
        return <>default</>;
    }
  };

  return <>{_renderSteps()}</>;
};

const mapStateToProps = ({ profile }) => {
  const { singleProfile, loading, message } = profile;
  return {
    singleProfile,
    profile,
    loading,
    message,
  };
};

export default connect(mapStateToProps, {
  sendDeletionCode,
  deleteProfile,
})(DeleteAcct);
