import { useLocation } from '@reach/router';
// import { openLoan } from 'appRedux/actions/domore';
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import black from 'assets/vcards/gg-black.svg';
import blue from 'assets/vcards/gg-blue.svg';
import green from 'assets/vcards/gg-green.svg';
import purple from 'assets/vcards/gg-purple.svg';
import yellow from 'assets/vcards/gg-yellow.svg';
import save from 'assets/save_tr.svg';
import deposit from 'assets/deposit_tr.svg';
import withdraw from 'assets/withdraw_tr.svg';
import completed from 'assets/completedsavings.svg';
import pending from 'assets/pendingsavings.svg';
import active from 'assets/activesavings.svg';
import airtime from 'assets/airtime_tr.svg';
import transfer from 'assets/transfer_tr.svg';

import naira from 'assets/wallets/naira.svg';
import usd from 'assets/wallets/usd.svg';
import cedis from 'assets/wallets/cedis.svg';
import kwacha from 'assets/wallets/zambia.svg';
import pounds from 'assets/wallets/pounds.svg';
//import ghana from 'assets/wallets/ghana.svg';
import founders from 'assets/wallets/founders.svg';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import debounce from 'lodash/debounce';
import { currencies } from './data';

export const objectValuesStringify = obj => Object.values(obj).join(', ');

export const useUrlQuery = queryParam => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const roundToXDigits = (value, digits) => {
  if (!digits) {
    digits = 2;
  }
  value = value * Math.pow(10, digits);
  value = Math.round(value);
  value = value / Math.pow(10, digits);
  return value;
};

export const formatAmount = (amount, n, x) => {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return amount?.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

export const getCurrency = cur => {
  switch (cur) {
    case 'GBP':
    case 'gbp':
      return '£';
    case 'USD_CENTS':
    case 'USD':
      return '$';
    case 'CAD_CENTS':
      return 'C$';
    case 'NGN_KOBO':
    case 'NGN':
    case 'ngn':
      return '₦';
    case 'CEDIS':
    case 'cedis':
    case 'Cedis':
    case 'GHS':
    case 'ghs':
      return 'GH₵';
    case 'zmw':
    case 'ZMW':
    case 'KWACHA':
    case 'kwacha':
    case 'Kwacha':
      return 'ZMW';
    default:
      return '';
  }
};

export const getStatusImage = status => {
  switch (status) {
    case 'completed':
    case 'cancelled':
    case 'terminated':
      return completed;
    case 'active':
      return active;
    case 'pending':
      return pending;
    case 'CANCELLED':
      return 'nothing';
    default:
      return '';
  }
};

export const getCurrencyName = cur => {
  switch (cur) {
    case 'USD_CENTS':
      return 'USD';
    case 'CAD_CENTS':
      return 'CAD';
    case 'NGN_KOBO':
    case 'ngn':
      return 'Naira';
    case 'GHC':
    case 'ghc':
    case 'ghs':
      return 'Cedis';
    case 'ZMW':
    case 'zmw':
      return 'Kwacha';
    case 'GBP':
    case 'gbp':
      return 'Pounds';
    default:
      return '';
  }
};

export const getCardImage = cur => {
  switch (cur) {
    case 'Black':
      return black;
    case 'Blue':
      return blue;
    case 'Green':
      return green;
    case 'Purple':
      return purple;
    case 'Yellow':
      return yellow;
    default:
      return green;
  }
};

export const getCardApi = value => {
  switch (value) {
    case 'Stripe':
      return 'stripe/fundCardFromWallet';
    case 'Juice':
      return 'juice/fundCardFromWallet';
    case 'Mono':
      return 'mono/fund_card';
    case 'Checkbook':
      return 'card/vc/4/fund';
    default:
      return '/card/vc/1/fund';
  }
};

export const getCardPinApi = value => {
  switch (value) {
    case 'Stripe':
      return 'stripe/changeCardPin';
    case 'Juice':
      return 'juice/changeCardPin';
    case 'Mono':
      return 'mono/cards/update_pin';
    default:
      return '';
  }
};

export const __renderRouting = value => {
  switch (value) {
    case 'ROUTING_NUMBER':
      return 'Routing Number';
    case 'SWIFT':
      return 'Swift Code';
    case 'BIC':
      return 'BIC';
    default:
      return '';
  }
};

export const __renderWalletName = value => {
  switch (value) {
    case 'Founders Wallet':
    case 'Business USD Balance':
    case 2:
      return ' --Founders';
    case 'Naira wallet':
    case 'NGN wallet':
    case 'NGN Wallet':
    case 'Naira Balance':
    case 0:
      return ' --Naira';
    case 'Cedis Balance':
    case 'GHC wallet':
    case 'GHS wallet':
    case 'GHS Wallet':
      return ' --Cedis';
    case 'Kwacha Balance':
    case 'ZMW Balance':
    case 'ZMW Wallet':
    case 'ZMW wallet':
      return ' --Kwacha';
    case 'Pounds Balance':
    case 'GBP Balance':
    case 'GBP Wallet':
    case 'GBP wallet':
      return ' --Pounds';
    case 'Safelock wallet':
      return ' --safelock';
    case 'Analytics':
      return ' --analyticscard';
    case 'Usd wallet':
    case 'USD wallet':
    case 'USD Wallet':
    case 'Personal USD Balance':
    case 1:
      return ' --Usd';
    default:
      return '';
  }
};

export const __renderWalletTitle = value => {
  switch (value) {
    case 'pusd':
      return 'Personal USD';
    case 'naira':
      return 'Naira';
    case 'ngn':
      return 'Naira';
    case 'zmw':
      return 'Kwacha';
    case 'gbp':
      return 'Pounds';
    case 'busd':
      return 'Business USD';
    default:
      return '';
  }
};

export const __renderWalletSvg = value => {
  switch (value) {
    case 'Pounds Balance':
    case 'Pounds Wallet':
    case 'GBP Wallet':
    case 'GBP wallet':
    case 'gbp':
    case 4:
      return pounds;
    case 'Kwacha Balance':
    case 'Kwacha Wallet':
    case 'ZMW Wallet':
    case 'ZMW wallet':
    case 'zmw':
    case 4:
      return kwacha;
    case 'Cedis Wallet':
    case 'Cedis Balance':
    case 'GHS Wallet':
    case 'GHS wallet':
    case 'ghs':
    case 3:
      return cedis;
    case 'Founders Wallet':
    case 'Business USD Balance':
    case 'founders':
    case 2:
      return founders;
    case 'Naira wallet':
    case 'Naira Balance':
    case 'NGN wallet':
    case 'NGN Wallet':
    case 'ngn':
    case 0:
      return naira;
    case 'Usd wallet':
    case 'USD wallet':
    case 'USD Wallet':
    case 'Personal USD Balance':
    case 'usd':
    case 1:
      return usd;
    default:
      return '';
  }
};

export const toWholeCurrency = num => num?.toLocaleString();

export const errorMessage = error => {
  if (error?.data?.errors) {
    return objectValuesStringify(error?.data?.errors);
  } else {
    return error?.data?.message || error.message;
  }
};

export const pathwayActionFunc = (value, name) => {
  // eslint-disable-next-line
  var dispatch = useDispatch;
  switch (value) {
    // case 'Proof of fund' || 'Proof of Funds'  || 'Proof of Fund' || 'proof of fund' || 'proof of funding':
    //   return dispatch(openLoan())
    // case 'Pay for Proof of Funds':
    //   return dispatch(openLoan())
    case 'Pay for Initial Evaluation':
      return window.open('https://calendly.com/vesti/founderpaid');
    case 'Virtual Call Meeting':
      return window.open('https://calendly.com/vesti/founderpaid');
    default:
      return navigate(`/merchants?merchant=${name}`);
  }
};

export const pathwayActionLink = value => {
  switch (value) {
    case 'Virtual Call Meeting':
      return 'Schedule Meeting';
    case 'Pay for Initial Evaluation':
      return 'Schedule Meeting';
    case 'Apply for Proof of Fund':
      return 'Apply For Proof Of Fund';
    default:
      return value;
  }
};

export const openLink = value => {
  window.open(value);
};

export const openMail = value => {
  window.location.href = `mailto:${value}`;
};

export const removeCommaAmount = value => {
  return parseFloat(
    value
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/,/g, ''),
  );
};

export const returnAmount = value => {
  return value === 'NaN' ? 0 : value.toLocaleString('en-US');
};

export const virtualBankFullName = value => {
  return value.split('(')[1].split(')')[0];
};

export const transImg = value => {
  switch (value) {
    case 'WITHDRAWAL':
    case 'DEBIT':
    case 'LIEN':
    case 'PLACE':
      return withdraw;
    case 'TRANSFER':
      return transfer;
    case 'SAVING':
      return save;
    case 'AIRTIME':
      return airtime;
    default:
      return deposit;
  }
};

export const transColor = value => {
  switch (value) {
    case 'WITHDRAWAL FROM WALLET':
    case 'WITHDRAWAL_FROM_WALLET':
    case 'NEW CARD CREATION':
    case 'CARD CREATION':
    case 'DEBIT FROM WALLET':
    case 'PLACE LIEN':
    case 'CONVERSION TO USD WALLET':
    case 'TRANSFER SENT':
    case 'TRANSFER':
    case 'SAVING':
    case 'debit':
    case 'Withdrawal':
      // case 'WITHDRAWAL':
      return '-red';
    case 'AIRTIME':
    case 'BILL PAYMENT':
    case 'BILL_PAYMENT':
      return '-red';
    case 'Deposit':
      return '-green';
    case 'PROCESSING':
    case 'pending':
      return '-tumeric';
    default:
      return '-green';
  }
};

export const transSign = value => {
  switch (value) {
    case 'WITHDRAWAL FROM WALLET':
    case 'WITHDRAWAL_FROM_WALLET':
    case 'NEW CARD CREATION':
    case 'CARD CREATION':
    case 'NEW_CARD_CREATION':
    case 'DEBIT FROM WALLET':
    case 'PLACE LIEN':
    case 'TRANSFER SENT':
    case 'TRANSFER':
    case 'CONVERSION TO USD WALLET':
    case 'debit':
    case 'WITHDRAWAL':
      return '- ';
    case 'SAVING':
    case 'AIRTIME':
    case 'BILL PAYMENT':
    case 'BILL_PAYMENT':
      return '- ';
    default:
      return '+';
  }
};

export const transStatus = value => {
  switch (value) {
    case 'SUCCESS':
    case 'COMPLETED':
    case 'completed':
    case 'posted':
      return 'success';
    case 'FAILURE':
    case 'FAILED':
    case 'FALSE':
    case 'false':
    case 'void':
    case false:
      return 'failed';
    case 'PENDING':
    case 'pending':
    case 'IN_PROCESS':
    case 'open':
      return 'pending';
    default:
      return '';
  }
};

export const getInitials = name => {
  var initials = name.split(' ');
  initials = initials[0].substring(0, 1) + initials[1].substring(0, 1);
  return initials;
};

export const shuffleArray = arr => {
  return !arr ? [] : arr.sort(() => 0.5 - Math.random());
};
export const formatterUSD = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const getUsdDepositRate = amount => {
  var fivePerc = amount * 0.05;
  return fivePerc < 1000 ? fivePerc : 1000;
};

export const getUsdAchRate = amount => {
  // var perc = formatterUSD.format(amount * 0.013)
  var perc = amount * 0.013;
  return perc > 5 ? 5 : removeCommaAmount(perc);
};

export const getNGNRate = amount => {
  // var perc = formatterUSD.format(amount * 0.013)
  var perc = amount * 0.015;
  return perc > 2000
    ? 2100
    : amount >= 2500
    ? removeCommaAmount(perc + 100)
    : removeCommaAmount(perc);
};

export const getCardRate = (amount, curr) => {
  var perc = () => {
    switch (curr?.toLocaleLowerCase()) {
      case 'ngn_kobo':
      case 'ngn':
        return amount * 0.015 > 2000
          ? 2100
          : amount >= 2500
          ? removeCommaAmount(amount * 0.015 + 100)
          : removeCommaAmount(amount * 0.015);
      case 'cedis':
      case 'ghs':
      case 'ghc':
        return amount * 0.02 > 2000
          ? 2100
          : amount >= 2500
          ? removeCommaAmount(amount * 0.015 + 100)
          : removeCommaAmount(amount * 0.015);
      case 'ZMW':
      case 'zmw':
        var fivePerc = amount * 0.05;
        return fivePerc < 1000 ? fivePerc : 1000;
      case 'GBP':
      case 'gbp':
        var fivePerc = amount * 0.05;
        return fivePerc < 1000 ? fivePerc : 1000;
      default:
        return 1;
    }
  };

  return perc();
};

export const getUsdWireRate = amount => {
  // var formatterUSD = new Intl.NumberFormat('en-US');
  if (amount > 0 && amount <= 10000) {
    return removeCommaAmount(formatterUSD.format(amount * 0.013 + 25));
  } else if (amount > 10000 && amount <= 100000) {
    return removeCommaAmount(formatterUSD.format(amount * 0.01 + 25));
  } else {
    return removeCommaAmount(formatterUSD.format(amount * 0.008 + 25));
  }
};

export const getUsdWireperc = amount => {
  if (amount > 0 && amount <= 10000) {
    return ` 1.3%`;
  } else if (amount > 10000 && amount <= 100000) {
    return ` 1%`;
  } else {
    return ` 0.8%`;
  }
};

export const walletToCharge = value => {
  switch (value) {
    case 'USD_CENTS':
      return 'USD';
    case 'NGN_KOBO':
    case 'NGN':
      return 'NGN';
    case 'GHS':
      return 'GHS';
    case 'KES':
      return 'KES';
    case 'ZMW':
      return 'ZMW';
    case 'GBP':
      return 'GBP';
    default:
      return value;
  }
};

export const currencyRate = (value, rate) => {
  switch (value) {
    case 'NGN-USD':
      return rate?.MONO_CARD_RATE;
    case 'NGN_KOBO':
      return 'NGN';
    default:
      return value;
  }
};

export const getSelectedAccount = (setAccount, data, countries, states) => {
  setAccount({
    routing_number: data.routingNumber,
    account_number: data.accountNumber,
    billing_address: data.billingAddress,
    country: countries.filter(item => item.value === data.country)[0],
    // country: {value:data.country, label:data.country},
    account_holder_name: data.accountHolderName,
    postalCode: data.postalCode,
    district: data.district,
    // city: states.filter(item=> item.value === data.city)[0],
    city: { value: data.city, label: data.city },
    bank_name: data.bankName,
    bank_city: data.bankCity,
    bank_address: data.bankAddress,
    bank_state: { value: data.bankState, label: data.bankState },
    // bank_state: states.filter(item=> item.value === data.bankState)[0],
    bank_zip: data.bankZip,
    type: 'routing_number',
  });
};

export const handleDownloadPdf = async (element, name, cb) => {
  // const element = printRef.current;
  cb(true);
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL('image/png');
  // setTimeout(()=> {
  //   cb(false)
  // },500)

  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth * 2) / imgProperties.width;

  pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
  cb(false);
  pdf.save(`${name}.pdf`);
};

export const nameInUppercase = value => {
  return !value ? '' : value.charAt(0).toUpperCase() + value.slice(1);
};

export const getCurrencyCode = cur => {
  // console.log(cur);
  switch (cur) {
    case 'USA':
    case 'United States':
      return 'USD';
    case 'NGA':
    case 'Nigeria':
    case 'NIGERIA':
      return 'NGN';
    case 'GHA':
    case 'Ghana':
    case 'GHANA':
      return 'GHS';
    case 'ZMB':
    case 'Zambia':
    case 'ZAMBIA':
      return 'ZMW';
    case 'ZMB':
    case 'Zimbabwe':
    case 'ZWE':
      return 'ZWL';
    case 'GBR':
    case 'UK':
    case 'United Kingdom':
      return 'GBP';
    case 'KEN':
    case 'Kenya':
      return 'KES';
    case 'South Africa':
      return 'ZAR';
    case 'LBR':
    case 'Liberia':
      return 'LBR';
    case 'CMR':
    case 'Cameroon':
      return 'CMR';
    case 'TUR':
    case 'Turkey':
      return 'TUR';
    default:
      return '';
  }
};

export const getPhoneCode = cur => {
  switch (cur) {
    case 'United States':
      return '+1';
    case 'Nigeria':
      return '+234';
    case 'Ghana':
      return '+233';
    case 'Zambia':
      return '+260';
    case 'United Kingdom':
      return '+44';
    case 'Kenya':
      return '+254';
    case 'South Africa':
      return '+27';
    case 'Liberia':
      return '+231';
    case 'Cameroon':
      return '+237';
    case 'Turkey':
      return '+90';
    default:
      return '';
  }
};

export const getCurrencyImg = cur => {
  switch (cur) {
    case 'GH':
    case 'ghs':
      return 'https://upload.wikimedia.org/wikipedia/commons/4/40/Flag_of_Ghana.png';
    case 'NG':
    case 'ngn':
      return 'https://res.cloudinary.com/wevesti/image/upload/v1698480453/stgcyigpbiadb2g6ipjj.jpg';
    case 'ZM':
    case 'zmw':
    case 'kwacha':
      return 'https://res.cloudinary.com/wevesti/image/upload/v1698480509/l6crbhlwgcrgk1axwa9j.jpg';
    case 'MXW':
    case 'Pesos':
    case 'pesos':
      return 'https://res.cloudinary.com/wevesti/image/upload/v1699076452/xihtkljb4qi7rfrgcygv.png';
    case 'UK':
    case 'gbp':
      return 'https://res.cloudinary.com/wevesti/image/upload/v1698480604/s5f9gmx0d9ujzxqmgrgt.jpg';
    default:
      return '';
  }
};

export const debounceApiCall = debounce(searchTerm => {
  // Make API call with the search term
}, 1000);

export const filterAvailableWallets = (allWallet, type) => {
  var finalArr = [];
  // eslint-disable-next-line
  const filteredArr = allWallet?.map((item, id) => {
    var neww = currencies.filter(
      curr => curr.currency !== item.currency.toLocaleLowerCase(),
    );
    // var newType =  currencies.filter(curr => curr.currency === item.currency.toLocaleLowerCase())

    if (neww) {
      return neww;
    }
  });

  // Return the filtered array
  //console.log(finalArr);
  return filteredArr[filteredArr?.length - 1];
};

export const addHourTotime = (dateString, hours) => {
  const date = new Date(dateString);
  date.setHours(date.getHours() + hours);
  return date;
};
