import React, { useState, useEffect } from 'react';
import api from 'appRedux/api';
import { OthergroupsSinglePost } from './othergroupsSinglePost';
import { Singlepost } from '../singlepost';
import { useUserData } from 'helpers/hooks';

import { Createpost } from 'components/feeds/createpost';
import CreateOtherGroupsPost from './createOtherGroupsPost';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import { useDispatch, useSelector } from 'react-redux';
import { errorMessage } from 'helpers/utils';
import Loader from 'components/Loader/Loader';
// import Notifybox from 'components/common/notifybox/notifybox';
// import Refer from "../../../assets/refer.svg"
import { navigate } from '@reach/router';
import { useRef } from 'react';
import { Modal } from 'antd';
import { Newpost } from 'components/common/notifybox/newpost';
import { userId } from 'containers/Admin/Pathways';

const GroupsPost = props => {
  const [groupPost, setGroupPost] = useState([]);
  const { userData } = useUserData();
  const dispatch = useDispatch();
  const [action, setAction] = useState(false);

  const userD = useSelector(state => state.auth?.userData);

  const [post, setPost] = useState({
    // title: 'moveMeIn12Months',
    message: '',
    groupId: props.groupId,
    image: '',
    button: 'Post',
  });

  const setInput = e => {
    var value = e.target.value;
    setPost({ ...post, message: value });
  };

  const insertEmoji = emoji => {
    const updatedDescription = post.message + emoji;
    setPost({ ...post, message: updatedDescription });
  };

  const createPost = e => {
    setAction(false);

    e.preventDefault();
    setPost({ ...post, button: 'Creating Post...' });
    var formData = new FormData();

    formData.append('action', 'message');
    formData.append('actionId', props.groupId);
    formData.append('message', post.message);
    formData.append('images', post.image);
    //  formData.append('postPictureUrl', post.image);
    if (
      userData.firstName === null ||
      userData.firstName === '' ||
      userData.lastName === null ||
      userData.lastName === ''
      // userData.username.includes('null')
    ) {
      // console.log(userData.verifiedKyc);
      openNotificationWithIconErr(
        'Post',
        'Only Users with full profile details can post, go to your profile and update your profile.',
        'error',
      );
      setPost({ ...post, button: 'Post' });
    } else {
      api
        .post(
          `/group/create-message-and-reply?groupId=${props.groupId}`,
          formData,
        )
        .then(res => {
          openNotificationWithIcon(
            'You have successfully created a post',
            'Success',
            'success',
          );

          setPost({ message: '', image: '', button: 'Create Post' });
          setAction(true);
        })
        .catch(err => {
          openNotificationWithIconErr(errorMessage(err), 'Post', 'error');
          setPost({ message: '', image: '', button: 'Create Post' });
        });
    }
  };

  useEffect(() => {
    // setIsloading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/group/messages-and-comments?groupId=${props.groupId}`,
        );
        const data = await response.data.data.data;
        setGroupPost(data);
        // setIsloading(false);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchData();
  }, [action]);

  var onScroll = () => {
    // const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
    const scrollTop = scrollRef.current.scrollTop;
    setScroll(scrollTop);
  };

  const [scroll, setScroll] = useState();
  const scrollRef = useRef(null);
  // console.log(groupPost);
  return (
    <div className="px-1" style={{ paddingTop: '1rem' }}>
      <div className="">
        <CreateOtherGroupsPost
          name={userData.firstName}
          post={post}
          insertEmoji={insertEmoji}
          setInput={setInput}
          // setImage={setImage}
          createPost={createPost}
          picture={userData.profilePictureURL}
          // remove={Remove}
        />
      </div>

      <div className="" style={{ position: 'relative', marginTop: '2rem' }}>
        {(scroll > 200) & (scroll < 30000) ? (
          <Newpost />
        ) : (
          <div
            style={{
              height: '30px',
              padding: '20px',
              position: 'sticky',
              top: '20%',
            }}
          ></div>
        )}
        {groupPost?.map((post, index) => {
          const {
            comments,
            createdAt,
            groupId,
            id,
            images,
            message,
            reactions,
            // replies,
            senderId,
            updatedAt,
            userComments,
            userId,
            title,
            date,
            hasLiked,
            likes,
            shares,
            postPictureUrl,
          } = post;

          if (post?.creator?.username?.includes('null')) {
            return '';
          } else {
            return (
              <OthergroupsSinglePost
                key={id}
                id={id}
                groupId={props.groupId}
                userD={userD}
                setGroupPost={setGroupPost}
                username={post?.creator?.username}
                firstname={post?.creator?.firstName}
                lastname={post?.creator?.lastName}
                fullname={
                  post?.creator?.firstName + ' ' + post?.creator?.lastName
                }
                email={post?.creator?.email}
                userId={userId}
                title={title}
                description={message}
                userComments={post?.replies}
                profileUrl={post?.creator?.profilePictureURL}
                createdAt={createdAt}
                date={date}
                likes={reactions}
                fetchVestiPosts={groupPost}
                hasLiked={hasLiked}
                postPictureUrl={postPictureUrl}
                userData={userD}
                setAction={setAction}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default GroupsPost;
