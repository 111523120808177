import React from 'react';
import './littlebalance.scss';
import bank from 'assets/smallbank.svg';
import { __renderWalletSvg } from 'helpers/utils';
export const Littlebalance = props => {
  return (
    <div className={`littlebalance ${props.type ? `--${props.type}` : ''}`}>
      <img
        src={props.type ? __renderWalletSvg(props.type) : bank}
        alt="balance"
      />
      <div className="littlebalance__right">
        <p>{props.title}</p>
        <p>
          {props.currency}
          {props.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </p>
      </div>
    </div>
  );
};

export const Textbal = props => {
  return props.small ? (
    <p className="littlebalance__bal">
      {props.wallet} Balance ={' '}
      <strong>
        {props.currency}
        {props.bal}{' '}
      </strong>
    </p>
  ) : (
    <p className="littlebalance__bal">
      You have{' '}
      <strong>
        {props.currency} {props.bal}{' '}
        {/* {props.currency} {props.bal / 100} */}
      </strong> &nbsp;
      in your {props.wallet}.
    </p>
  );
};
