import {
  CREATE_MONO_CARD,
  CARD_ACTION,
  FETCH_CARD,
  FETCH_CARDS,
  FETCH_CARDTRANS,
} from 'appRedux/constants';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from './Common';
import { errorMessage, getCardApi, getCardPinApi } from 'helpers/utils';
import { navigate } from '@reach/router';
import { cardColors } from 'helpers/data';
// import { useSelector } from 'react-redux';
// import _ from 'lodash';

export const createMonoCardHolder = (payload, cb) => {
  return dispatch => {
    dispatch({ type: CREATE_MONO_CARD, payload: { loading: true } });
    const url = 'mono/create_card_holder';
    api
      .post(url, {
        ...payload,
        state: payload.state.label,
        lga: payload.lga.value,
      })
      .then(res => {
        dispatch({
          type: CREATE_MONO_CARD,
          payload: { loading: false, message: res.data.message },
        });
        cb(3);
      })
      .catch(error => {
        dispatch({
          type: CREATE_MONO_CARD,
          payload: { loading: false, message: error.data.message },
        });
        cb(5);
      });
  };
};

export const createVirtualCard = (payload, cb) => {
  return dispatch => {
    dispatch({ type: CREATE_MONO_CARD, payload: { loading: true } });
    // const url = '/card/vc/4/create';
    const url = '/card/vc/1/create';
    api
      .post(url, {
        ...payload,
        // cardColor: cardColors[Math.floor(Math.random() * 4) + 1],
      })
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Virtual Card', 'success');
        dispatch({
          type: CREATE_MONO_CARD,
          payload: { loading: false, message: res.data.message },
        });

        setTimeout(() => {
          cb(3);
        }, 100);
      })
      .catch(error => {
        openNotificationWithIconErr(
          error.data.message,
          'Virtual Card',
          'error',
        );
        dispatch({
          type: CREATE_MONO_CARD,
          payload: { loading: false, message: error.data.message },
        });

        setTimeout(() => {
          cb(4);
        }, 100);
      });
  };
};

export const createMoreVirtualCard = (payload, cb) => {
  return dispatch => {
    dispatch({ type: CREATE_MONO_CARD, payload: { loading: true } });
    const url = '/card/vc/1/create-more';
    api
      .post(url, {
        ...payload,
        // cardColor: cardColors[Math.floor(Math.random() * 4) + 1],
      })
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Virtual Card', 'success');
        dispatch({
          type: CREATE_MONO_CARD,
          payload: { loading: false, message: res.data.message },
        });

        setTimeout(() => {
          cb(3);
        }, 100);
      })
      .catch(error => {
        openNotificationWithIconErr(
          error.data.message,
          'Virtual Card',
          'error',
        );
        dispatch({
          type: CREATE_MONO_CARD,
          payload: { loading: false, message: error.data.message },
        });

        setTimeout(() => {
          cb(4);
        }, 100);
      });
  };
};

export const fetchSingleVCard = payload => {
  return dispatch => {
    dispatch({ type: FETCH_CARD, payload: { loading: true } });
    // const url = `mono/card/${payload}`;
    const url = `juice/singleCard/${payload}`;
    api
      .get(url)
      .then(res => {
        dispatch({
          type: FETCH_CARD,
          payload: { loading: false, currCard: res.data.data.cardDetails },
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_CARD,
          payload: { loading: false, currCard: '' },
        });
        // cbb(false)
      });
  };
};

export const fundCard = (payload, name, cb) => {
  return dispatch => {
    dispatch({ type: CARD_ACTION, payload: { cardLoading: true } });
    const url = getCardApi(name);
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Card Funding', 'success');
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: res.data.message },
        });

        setTimeout(() => {
          cb(3);
        }, 100);
      })
      .catch(error => {
        openNotificationWithIconErr(
          error.data.message,
          'Card Funding',
          'error',
        );

        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: error.data.message },
        });
        cb(4);
      });
  };
};

export const changeCardPin = (payload, name, cb) => {
  // alert(name)
  return dispatch => {
    dispatch({ type: CARD_ACTION, payload: { cardLoading: true } });
    const url = getCardPinApi(name);
    api
      .patch(url, payload)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'PIN update', 'success');
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: res.data.message },
        });

        setTimeout(() => {
          cb(2);
        }, 100);
      })
      .catch(error => {
        //console.log(errorMessage(error))
        openNotificationWithIconErr(errorMessage(error), 'PIN update', 'error');
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: errorMessage(error) },
        });
        cb(3);
      });
  };
};

export const freezeUnfreezeCard = (payload, status, cb) => {
  // alert(name)
  return dispatch => {
    dispatch({ type: CARD_ACTION, payload: { cardLoading: true } });
    const url = status.includes('Inactive')
      ? `/mono/unfreeze/${payload}`
      : `/mono/freeze/${payload}`;
    api
      .patch(url)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Virtual Card', 'success');
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: res.data.message },
        });

        setTimeout(() => {
          cb();
        }, 100);
      })
      .catch(error => {
        // console.log(errorMessage(error));
        openNotificationWithIconErr(
          errorMessage(error),
          'Virtual Card',
          'error',
        );
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: errorMessage(error) },
        });
        cb();
      });
  };
};

export const activateDeactivateCard = (payload, cb) => {
  // alert(name)
  return dispatch => {
    dispatch({ type: CARD_ACTION, payload: { cardLoading: true } });
    const url = '/card/vc/1/activate-and-deactivate';
    api
      .post(url, payload)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Virtual Card', 'success');
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: res.data.message },
        });
        // console.log(res);

        setTimeout(() => {
          cb();
        }, 100);
      })
      .catch(error => {
        // console.log(errorMessage(error));
        openNotificationWithIconErr(
          errorMessage(error),
          'Virtual Card',
          'error',
        );
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: errorMessage(error) },
        });
        cb();
      });
  };
};

export const liquidateCard = (payload, id, cb) => {
  // alert(name)
  // var load = name === 'Mono' ? {amountInUsdCent:payload.amount * 100, cardId:payload.cardId} : payload
  return dispatch => {
    dispatch({ type: CARD_ACTION, payload: { cardLoading: true } });
    const url = `/mono/cards/liquidate/${id}`;
    api
      .patch(url, payload)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Liquidate Card', 'success');
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: res.data.message },
        });

        setTimeout(() => {
          cb(3);
        }, 100);
      })
      .catch(error => {
        // console.log(error.data.data.message)
        openNotificationWithIconErr(
          error.response.data.message,
          'Liquidate Card',
          'error',
        );

        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: error.response.data.message },
        });
        cb(4);
      });
  };
};

// delete card mono/cards/delete/cardId

export const deleteCard = (payload, status, cb) => {
  // alert(name)
  return dispatch => {
    dispatch({ type: CARD_ACTION, payload: { cardLoading: true } });
    // const url = `mono/cards/delete/${payload}`;
    const url = `/card/vc/1/activate-and-deactivate`;
    const data = { cardId: payload };
    api
      .post(url, data)
      .then(res => {
        // console.log(res);
        // openNotificationWithIcon(res.data.message, 'Virtual Card', 'success');
        openNotificationWithIcon(
          `Operation SUccessful`,
          'Virtual Card',
          'success',
        );
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: res.data.message },
        });

        setTimeout(() => {
          cb();
          // navigate('/bank');
        }, 100);
      })
      .catch(error => {
        openNotificationWithIconErr(
          error.response.data.message,
          'Virtual Card',
          'error',
        );
        dispatch({
          type: CARD_ACTION,
          payload: { cardLoading: false, message: errorMessage(error) },
        });
        cb();
      });
  };
};

export const fetchAllCards = () => {
  return dispatch => {
    dispatch({ type: FETCH_CARDS, payload: { loading: true } });
    const url = '/card/vc/all';
    api
      .get(url)
      .then(res => {
        // console.log('Card Response: ', res);
        var cards = res.data.data.cardDetails
          .sort(
            (a, b) =>
              new Date(b.createdAt.split(' ')[0]) -
              new Date(a.createdAt.split(' ')[0]),
          )
          .filter(
            item =>
              item.providerName !== 'Juice' &&
              item.providerName !== 'Mono' &&
              item.providerName !== 'Stripe',
          );
        // var cards = res.data.data.cardDetails.sort((a, b) => new Date(b.createdAt.split(' ')[0]) - new Date(a.createdAt.split(' ')[0])).filter(item => item.providerName !== 'Juice ')
        dispatch({
          type: FETCH_CARDS,
          payload: { loading: false, allCards: cards },
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_CARDS, payload: { loading: false } });
      });
  };
};

export const fetchCardTransactions = (payload, page) => {
  return dispatch => {
    dispatch({ type: CARD_ACTION, payload: { loading: true } });
    api
      .get(`/card/vc/user/transactions?cardid=${payload}&page=${page}`)
      .then(res => {
        // console.log('Card Transactions: ', res);
        dispatch({
          type: FETCH_CARDTRANS,
          payload: { transactions: res.data },
        });
        dispatch({
          type: CARD_ACTION,
          payload: { loading: false, message: res.data.message },
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_CARDTRANS, payload: { transactions: [] } });
        dispatch({
          type: CARD_ACTION,
          payload: { loading: false, message: errorMessage(error) },
        });
      });
  };
};
