import React, { useEffect, useState } from 'react';
import GGcard from 'assets/physicalCard/GGcardP.png';
import Giftbox from 'assets/physicalCard/giftgroup.svg';
import Bgpattern from 'assets/physicalCard/GGreferralPattern.svg';
import { PhysicalCardTransHistory } from './physicalCardTransHistory';
import { navigate } from '@reach/router';
import api from 'appRedux/api';

const ActivatedUsercard = props => {
  const storedCurrency = localStorage.getItem('currency');

  const [rewardPrice, setRewardPrice] = useState(4500);
  const [currSymbol, setCurrSymbol] = useState('N');

  useEffect(() => {
    api
      .get(`/app-config/list?source=vesti`)
      .then(res => {
        if (storedCurrency === 'NGN') {
          setRewardPrice(res.data?.data?.data[0]?.totalNgnReward / 100);
          setCurrSymbol('N');
        } else if (storedCurrency === 'GBP') {
          setRewardPrice(res.data?.data?.data[0]?.totalGbpReward / 100);
          setCurrSymbol('£');
        } else if (storedCurrency === 'GHS') {
          setRewardPrice(res.data?.data?.data[0]?.totalGhsReward / 100);
          setCurrSymbol('GH¢');
        } else if (storedCurrency === 'USD') {
          setRewardPrice(res.data?.data?.data[0]?.totalUsdReward / 100);
          setCurrSymbol('$');
        } else if (storedCurrency === 'ZMW') {
          setRewardPrice(res.data?.data?.data[0]?.totalZmwReward / 100);
          setCurrSymbol('ZMW');
        }
      })
      .catch(err => err);
  }, []);

  return (
    <div className="activatedCardPage">
      <div className="activatedCardPage__top">
        <div style={{ height: '100%' }}>
          <img style={{ height: '100%' }} src={GGcard} alt="" />
        </div>

        <div className="activatedCardPage__top__referral">
          <div className="patternBgGG">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '5px',
                flexDirection: 'column',
              }}
            >
              <h1>
                Refer and Earn {currSymbol}
                {rewardPrice}
              </h1>

              <p>
                Get up to {currSymbol}
                {rewardPrice} when you refer 5 friends to get a GlobalGeng
                Physical Card.
              </p>

              <div style={{ marginTop: '20px' }}>
                <p
                  style={{
                    background: '#461A64',
                    color: 'white',

                    padding: '20px 10px',
                    textAlign: 'center',
                    borderRadius: '12px',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/myprofile?tab=referral');
                  }}
                >
                  Get your invite code
                </p>
              </div>
            </div>

            <img
              src={Giftbox}
              alt=""
              className="activatedCardPage__top__referral__img1"
            />

            {/* <img
              src={Bgpattern}
              alt=""
              className="activatedCardPage__top__referral__img2"
            /> */}
          </div>
        </div>
      </div>

      <div style={{ marginTop: '30px' }}>
        <PhysicalCardTransHistory transactions={props.transactions} />
      </div>
    </div>
  );
};

export default ActivatedUsercard;
