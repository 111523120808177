import React, { useState } from 'react';
import dummyAvatar from 'assets/dummy-avatar.png';
import { Textarea } from 'components/common/inputs/textarea';
import EmojiPicker from 'containers/Admin/NewDashboard/EmojiPicker';
import './createpost.scss';
import './postInput.scss';
import {
  feedsImgIcon,
  feelingsIcon,
  activityPollIcon,
  videoGifIcon,
  vdeoGif,
  activityp,
  fellngsGf,
  emojihappy,
} from 'assets/feedPageAssets';
import { useSelector } from 'react-redux';

export const Createpost = props => {
  const userData = useSelector(state => state.auth?.userData);
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <img
          src={!props.picture ? dummyAvatar : props.picture}
          alt="profilePic"
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50vw',
            objectFit: 'cover',
          }}
        />

        <div className="w-full pl-2">
          <div className=" flex relative mypostInput justify-between items-center w-full pr-0">
            <div
              className=" "
              style={{ width: '90%', marginLeft: '4px', paddingTop: '10px' }}
            >
              <textarea
                className="mypostInput__input"
                type="text"
                label=""
                name="post"
                placeholder={`What's on your mind ${userData?.firstName} ?`}
                value={props.post.description}
                onChange={props.setInput}
              />
            </div>

            <div
              className="emojiPickerContainer"
              style={{ position: 'absolute', right: '-60px' }}
            >
              <EmojiPicker onSelect={props.insertEmoji} />
            </div>
          </div>
        </div>
      </div>

      <button
        className="createpost__right__bottom__button"
        onClick={e => props.createPost(e)}
        disabled={props.post.button === 'Posting' ? true : false}
      >
        {props.post.button}
      </button>
    </div>
  );
};
