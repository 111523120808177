import React, { useState } from 'react';
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from 'components/common/inputs/singleselect';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Platformbutton } from 'components/common/button/button';
import { useStep } from 'helpers/hooks';
import { Success } from 'components/common/success/success';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { filterAvailableWallets, getCurrencyImg } from 'helpers/utils';
import { connect } from 'react-redux';
import { addNewWallet } from 'appRedux/actions/wallets';
import errorsvg from 'assets/newerror.svg';

const Addwallet = props => {
  // console.log(props)
  const [selected, setSelect] = useState();
  // eslint-disable-next-line
  const { step, setStep } = useStep(0);
  const userData = useSelector(state => state.auth.userData, _.isEqual);
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);

  switch (step) {
    case 0:
      return (
        // <section className="w-full md:w-[400px] lg:w-[450px] bg-red-90">
        <section className="w-full md:w-full lg:w-full bg-red-90">
          <Titlesubtitle
            title="Add Wallet"
            subtitle="Add from our selected wallets"
          />
          <span className="my-[20px]"></span>
          <Singleselect
            value={selected}
            options={filterAvailableWallets(allWallet).map(item => ({
              value: item.currency,
              label: item.name.toLocaleUpperCase(),
              image: item.icon,
            }))}
            placeholder="Select Wallet Type"
            onChange={value => setSelect(value)}
            components={{ SingleValue: IconSingleValue, Option: IconOption }}
          />
          <p className="my-12 h-[20px]"></p>
          {/* <div className="mt-5"> */}
          <Platformbutton
            name={selected ? `Request ${selected.label} Wallet ` : 'Select'}
            type="normal"
            disabled={!selected || props.wLoading ? true : false}
            click={() =>
              props.addNewWallet(
                {
                  userId: userData.id,
                  name: selected.value.toLocaleUpperCase() + ' ' + 'wallet',
                  currency: selected.value.toLocaleUpperCase(),
                  currencyFlag: getCurrencyImg(selected.value),
                },
                value => setStep(value),
              )
            }
          />
          {/* </div> */}
        </section>
      );
    case 1:
      return (
        <Success
          title="Successful"
          // subtitle={props.transMessage}
          subtitle={'Wallets created successfully'}
          button="Okay, Thank You"
          onClick={() => {
            props.refetch();
            // props.closeModal();
            props.onClick()
          }}
        />
      );
    case 2:
      return (
        <Success
        title="Unsuccessful"
        image={errorsvg}
          // subtitle={props.transMessage}
          subtitle="Unable to create new wallet now"
          button="Sorry, Please try again"
          onClick={() => {
            setStep(0);
          }}
        />
      );
    default:
      return <></>;
  }
};

const mapStateToProps = ({ wallets }) => {
  const { wLoading } = wallets;
  return {
    wLoading,
  };
};

const mapDispatchToProps = {
  addNewWallet,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addwallet);
