import React, { useEffect, useState } from 'react';
import './index.css';
import Layout from 'components/common/DashboardLayout';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import {
  getCurrency,
  objectValuesStringify,
  toWholeCurrency,
} from 'helpers/utils';
// import { useUserData } from 'helpers/hooks';
import { Backtrack } from 'components/common/back/back';
import { navigate } from '@reach/router';
import { Platformbutton } from 'components/common/button/button';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Amountinputcurrency } from 'components/common/inputs/amountinput';
import moment from 'moment';
import { Singleselect } from 'components/common/inputs/singleselect';

const EditSavings = props => {
  // console.log(props.location.state)
  // const { userData } = useUserData();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const savings = props.location?.state?.savings;
  const [formData, setFormData] = useState({
    targetAmount: '',
    amount: '',
    frequency: '',
    autoTopUp: '',
    paymentChannel: '',
  });
  // console.log('savings: ', savings);

  // eslint-disable-next-line

  useEffect(() => {
    if (savings === undefined || savings === null) {
      navigate('/savings');
    }
  }, [savings]);

  const formatNumberInput = value => {
    // Remove existing commas and convert to string
    const stringValue = value.toString().replace(/,/g, '');

    // Use a regular expression to add commas for thousands separators
    const formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  };

  var setInput = e => {
    var name = e.target.name;
    var value = e.target.value;

    if (name === 'targetAmount' || name === 'amount') {
      value = formatNumberInput(value);
    }

    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };

  var handleSelect = (name, value) => {
    setFormData({ ...formData, [name]: value?.value });
  };
  // console.log(formData);

  const handleSaveChanges = async () => {
    const url = '/safe-lock/update';
    const strippedTargetAmount = formData.targetAmount.replace(/,/g, '');
    const strippedAmount = formData.amount.replace(/,/g, '');
    const data = {
      targetAmount: parseFloat(strippedTargetAmount) * 100,
      safeLockId: savings?.id,
      autoTopUp: formData.autoTopUp,
      frequency: formData.frequency,
      paymentChannel: formData.paymentChannel,
      amount: parseFloat(strippedAmount) * 100,
    };

    // console.log('Data: ', data);
    setLoading(true);
    try {
      const res = await api.patch(url, data);
      // console.log({ ...res });
      openNotificationWithIcon(
        res.data?.message,
        'Savings Plan Update',
        'success',
      );
      // setTransMessage(res.data?.message);
      // navigate('/savings-details');
      navigate(`/savings-details`, { state: { id: savings?.id } });
    } catch (error) {
      if (error?.data?.errors) {
        // setTransMessage(error?.data?.errors);
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Savings Plan Update',
          'error',
        );
        // setStep(5);
      } else {
        //console.log('err', error?.data?.message);
        // setTransMessage(error?.data?.message || error?.message);
        openNotificationWithIconErr(
          error?.data?.message || error?.message,
          'Savings Plan Update',
          'error',
        );
      }
      //console.log({ ...error });
    }
    setLoading(false);
  };

  var frequencyOptions = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
    {
      label: 'Anytime(Save when you want)',
      value: 'anytime',
    },
  ];

  var automateOptions = [
    {
      label: 'Auto',
      value: 'auto',
    },
    {
      label: 'Manual Top-up',
      value: 'default',
    },
  ];

  var paymentChannelOptions = [
    {
      label: 'Card',
      value: 'card',
    },
    {
      label: 'Wallet',
      value: 'wallet',
    },
  ];

  return (
    <Layout>
      <div
        className=" isw-container"
        style={{ height: '85vh', width: '100%', overflow: 'scroll' }}
      >
        <div className="flex_page_container pt-4" id="savings">
          <Backtrack
            click={() =>
              navigate(`/savings-details`, { state: { id: savings.id } })
            }
          />

          <div className="font-weight-normal mt-5">
            <div className=" editsavings">
              <p className="font-semibold ">Savings Name</p>
              <div className="editsavings-inner">
                <Inputfloat
                  type="text"
                  label="Savings Name"
                  name="name"
                  defaultValue={savings?.name}
                  disabled={true}
                  onChange={setInput}
                />
              </div>
            </div>
            <div className=" editsavings">
              <p className="font-semibold ">Savings Target</p>
              <div className="editsavings-inner">
                <Amountinputcurrency
                  type="text"
                  currency={getCurrency(savings?.currency)}
                  name="targetAmount"
                  label="Target Amount"
                  placeholder={toWholeCurrency(savings?.targetAmount / 100)}
                  value={formData.targetAmount}
                  disabled={false}
                  defaultValue={toWholeCurrency(savings?.targetAmount / 100)}
                  onChange={setInput}
                  pattern="[0-9,.]*"
                  autoComplete="false"
                />
              </div>
            </div>
            <div className=" editsavings">
              <p className="font-semibold ">Savings Frequency</p>
              <div className="editsavings-inner">
                <Singleselect
                  placeholder={savings?.frequency}
                  value={savings?.frequency.value}
                  options={frequencyOptions}
                  onChange={value => handleSelect('frequency', value)}
                />
              </div>
            </div>
            <div className=" editsavings">
              <p className="font-semibold ">Amount to Save periodically</p>
              <div className="editsavings-inner">
                <Amountinputcurrency
                  type="text"
                  currency={getCurrency(savings?.currency)}
                  name="amount"
                  label="Amount"
                  value={formData.amount}
                  placeholder={toWholeCurrency(savings?.amount / 100)}
                  defaultValue={toWholeCurrency(savings?.amount / 100)}
                  disabled={false}
                  onChange={setInput}
                  pattern="[0-9,.]*"
                  autoComplete="false"
                />
              </div>
            </div>
            <div className=" editsavings">
              <p className="font-semibold ">Automated Saving</p>
              <div className="editsavings-inner">
                <Singleselect
                  placeholder={savings?.autoTopUp}
                  value={formData.frequency.value}
                  options={automateOptions}
                  onChange={value => handleSelect('autoTopUp', value)}
                />
              </div>
            </div>
            <div className=" editsavings">
              <p className="font-semibold ">Maturity Date</p>
              <div className="editsavings-inner editsavings-inner">
                <Inputfloat
                  type="text"
                  label="Maturity Date"
                  name="name"
                  defaultValue={moment(savings?.endDate).format('ll')}
                  disabled={true}
                  onChange={setInput}
                />
              </div>
            </div>
            <div className=" editsavings">
              <p className="font-semibold ">Payment Method</p>
              <div className="editsavings-inner">
                <Singleselect
                  placeholder={savings?.paymentChannel}
                  value={formData.paymentChannel.value}
                  options={paymentChannelOptions}
                  onChange={value => handleSelect('paymentChannel', value)}
                />
              </div>
            </div>
            <div className="editButtoncont editButton">
              <Platformbutton
                name="Save Changes"
                type="normal"
                disabled={
                  (!formData.targetAmount &&
                    !formData.endDate &&
                    !formData.amount &&
                    !formData.paymentChannel &&
                    !formData.frequency) ||
                  loading
                  // ? true
                  // : false
                }
                click={() => handleSaveChanges()}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditSavings;
