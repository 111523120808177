import React, { useEffect, useState } from 'react';
import { Section1 } from './section1';

import { useDispatch, useSelector } from 'react-redux';
import { fetchViasForms } from 'appRedux/actions/visaForm';
import { updateUploadedFiles, handleSections } from 'appRedux/actions/visaForm';
import Inputfloat from 'components/common/inputs/inputfloat';
import './section.scss';
import { NewInputFloat } from 'components/common/inputs/inputfloat';
import {
  BigIconOption,
  BigIconSingleValue,
  CustomizedSingleselect,
} from 'components/common/inputs/singleselect';
import covenLogo from 'assets/viasForm/covenlabsFullLogo.svg';
import InfraNovaLogo from 'assets/viasForm/infracomLogo.png';
import errIcon from 'assets/viasForm/uploadError.svg';
import { handleH1bFormRes } from 'appRedux/actions/visaForm';

export const H1BFullsection = props => {
  const [visaType, setVisaType] = useState('');
  const storedvisaType = useSelector(state => state.visaForm.visaAbbr);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const Forms = useSelector(state => state.visaForm.submittedForms);
  const userD = useSelector(state => state.auth?.userData);
  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  const h1bDegreeRes = useSelector(state => state.visaForm.degree);
  const h1bCompanyRes = useSelector(state => state.visaForm.fillingCompany);

  useEffect(() => {
    if (splitLocation[2] === '0-1visa' && !storedvisaType) {
      setVisaType('0-1');
    } else if (splitLocation[2] === 'EB-1visa' && !storedvisaType) {
      setVisaType('EB-1');
    } else if (splitLocation[2] === 'EB-2NIWvisa' && !storedvisaType) {
      setVisaType('EB-2 NIW');
    } else if (splitLocation[2] === 'H1Bvisa' && !storedvisaType) {
      setVisaType('H1B');
    } else if (splitLocation[2] === 'B-1B-2visa' && !storedvisaType) {
      setVisaType('B-1/B-2');
    } else if (splitLocation[2] === 'F1visa' && !storedvisaType) {
      setVisaType('F1');
    } else if (storedvisaType) {
      setVisaType(storedvisaType);
    }
  }, []);

  // const uploadedFiles = useSelector(state => state.visaForm.uploadedFiles);

  //       dispatch(updateUploadedFiles({}));
  useEffect(() => {
    dispatch(fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName));
  }, []);

  const [state, setState] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    degree: h1bDegreeRes ? h1bDegreeRes : '',
    recruitingCompany: h1bCompanyRes ? h1bCompanyRes : '',
    companyName: '',
    Ein: '',
    EmployerAddress: '',
    employerEmail: '',
    employerContact: '',
  });

  var setInput = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(handleH1bFormRes(state.degree, state.recruitingCompany));
  }, [state]);

  var setDOBInput = e => {
    const { name, value } = e.target;
    // Remove any non-numeric characters
    const cleaned = value.replace(/\D/g, '');
    // Ensure the length is not more than 6 characters
    const truncated = cleaned.substring(0, 6);
    // Insert '/' after every 2 numbers
    const formatted = truncated
      .replace(/(\d{2})(\d{0,2})/, '$1/$2')
      .replace(/(\d{2})\/(\d{2})(\d{0,2})/, '$1/$2/$3');
    setState({ ...state, [name]: formatted });
  };

  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };

  //  var handleSelect = value => {
  //    setState({ ...state, value });

  //  };

  const [option, setOption] = useState(null);
  const setMyOption = value => {
    setOption(value);
  };

  var selectOpt = [{ opt: 'Yes' }, { opt: 'No' }];
  var selectfillingCompany = [
    { opt: 'Coven Works', icon: covenLogo },
    { opt: 'Infra Nova Inc', icon: InfraNovaLogo },
    { opt: 'I have company filing for me', icon: '' },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <div>
        <p style={{ color: '#67A948', fontWeight: '600', fontSize: '18px' }}>
          Personal Information Section{' '}
        </p>
        <p style={{ color: '#2B5219', fontSize: '13px', fontWeight: '600' }}>
          Fill in this form accurately with up to date information.
        </p>
      </div>

      <div className="h1bInputsection mt-6">
        <Inputfloat
          type="text"
          name="firstName"
          label="FIRST NAME (AS WRITTEN ON YOUR PASSPORT)"
          value={state.firstName}
          disabled={false}
          placeholder="First Name"
          onChange={setInput}
        />
        <Inputfloat
          type="text"
          name="middleName"
          label="MIDDLE NAME (AS WRITTEN ON YOUR PASSPORT)"
          value={state.middleName}
          disabled={false}
          placeholder="Middle Name"
          onChange={setInput}
        />
        <Inputfloat
          type="text"
          name="lastName"
          label="LAST NAME (AS WRITTEN ON YOUR PASSPORT)"
          value={state.lastName}
          disabled={false}
          placeholder="Last Name"
          onChange={setInput}
        />
        <Inputfloat
          type="text"
          name="dob"
          label="DATE OF BIRTH (MM/DD/YY)"
          value={state.dob}
          disabled={false}
          placeholder="MM/DD/YY"
          onChange={setDOBInput}
        />
        <CustomizedSingleselect
          value={state.degree}
          options={selectOpt.map((op, index) => ({
            key: index,
            label: op.opt,
            value: op.opt,
          }))}
          placeholder="Do you have a degree (Bachelors Degree/HND)"
          onChange={selectedValue => {
            handleSelect('degree', selectedValue);
            dispatch(handleH1bFormRes(selectedValue, state.recruitingCompany));
          }}
          name="DO YOU HAVE A DEGREE (Bachelors Degree/HND)"
        />
        <CustomizedSingleselect
          value={state.recruitingCompany}
          options={selectfillingCompany.map((op, index) => ({
            key: index,
            label: '',
            value: op.opt,
            image: op.icon,
          }))}
          placeholder="Choose recruiting company"
          onChange={selectedValue => {
            handleSelect('recruitingCompany', selectedValue);
            dispatch(handleH1bFormRes(state.degree, selectedValue));
          }}
          name="CHOOSE RECRUITING COMPANY/EMPLOYER TO FILE FOR YOU"
          components={{
            SingleValue: BigIconSingleValue,
            Option: BigIconOption,
          }}
        />

        {state.recruitingCompany == 'I have company filing for me' ||
        h1bCompanyRes == 'I have company filing for me' ? (
          <>
            {' '}
            <Inputfloat
              type="text"
              name="companyName"
              label="Company Name"
              value={state.companyName}
              disabled={false}
              placeholder="Company Name"
              onChange={setInput}
            />
            <Inputfloat
              type="text"
              name="Ein"
              label="EIN of filing company (Employer Identification Number)"
              value={state.Ein}
              disabled={false}
              placeholder="EIN of filing company"
              onChange={setInput}
            />
            <Inputfloat
              type="text"
              name="EmployerAddress"
              label="Address of Employer"
              value={state.EmployerAddress}
              disabled={false}
              placeholder="Address of Employer"
              onChange={setInput}
            />
            <Inputfloat
              type="text"
              name="employerEmail"
              label="Employer contact person Email"
              value={state.employerEmail}
              disabled={false}
              placeholder="Employer contact person Email"
              onChange={setInput}
            />
            <Inputfloat
              type="text"
              name="employerContact"
              label="Employer contact person Phone number"
              value={state.employerContact}
              disabled={false}
              placeholder="Employer contact person Phone number"
              onChange={setInput}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      {state.degree == 'No' ? (
        <div
          className={`${state.degree == 'No' ? 'warningIn' : 'warningOut'} `}
        >
          {' '}
          <img src={errIcon} alt="" /> A Bachelor Degree/HND is mandatory to
          complete registration and enter the lottery.
        </div>
      ) : (
        <></>
      )}

      <Section1
        Forms={Forms}
        fetchViasForms={fetchViasForms}
        loading={props.loading}
        setLoading={props.setLoading}
        userD={userD}
        visaType={visaType}
        updateUploadedFiles={updateUploadedFiles}
        handleSections={handleSections}
        isClarityVideoChecked={props.isClarityVideoChecked}
        state={state}
      />
    </div>
  );
};
