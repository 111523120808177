import React, { useState, useEffect } from 'react';
import Layout from 'components/common/DashboardLayout';
import { navigate } from '@reach/router';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { Note } from '../../../note';
import { O1form } from '../../../Forms/o1form';
import { useSelector, useDispatch } from 'react-redux';
import { SimpleCancelmodal } from 'components/common/simplifiedmodal';
import { updateUploadedFiles } from 'appRedux/actions/visaForm';
import { openNotificationWithIcon } from 'appRedux/actions/Common';
import '../../../visatype.scss';
import icon from 'assets/viasForm/formNotificationIcon.svg';
import { Platformbutton } from 'components/common/button/button';
import { Payment } from '../../../payment';
import { ReportForm } from '../../../Forms/reportForm/reportForm';
import api from 'appRedux/api';
import { fetchViasForms, handleSections } from 'appRedux/actions/visaForm';
import { UploadSuccess } from '../../../Forms/uploadSuccess';
import { Info } from '../../Right-Info-Section/info';
import Vloader from 'assets/vestiLoader/vestiLoader.gif';
import { H1BFullsection } from '../../../Forms/h1bFormSections/sections';
import { RightSection } from './rightSection';
import { ProgressPage } from './progressPage';
import { SuccessRight } from './successRight';
import { SkeletonFormLoader, SkeletonNoteLoader } from '../../SkeletonLoader';

export const H1bStage1 = () => {
  const dispatch = useDispatch();

  const [reportModal, setReportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadedFiles = useSelector(state => state.visaForm.uploadedFiles);
  const checkVisaType = useSelector(state => state.visaForm.visaType);

  const [openWarning, setOpenWarning] = useState(false);

  const [opensubmitWarning, setOpenSubmitWarning] = useState(false);

  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  const [myvisaType, setMyVisaType] = useState('');
  const [visaValue, setVisaValue] = useState('');

  const Forms = useSelector(state => state.visaForm.submittedForms);
  const userD = useSelector(state => state.auth?.userData);

  const [countNonNullFilesValues, setCountNonNullFilesValues] = useState(0);
  const [totalFileCount, setTotalFileCount] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [paymentProgress, setPaymentProgress] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [amount, setAmount] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [h1bFee, setH1bfee] = useState(null);

  const [isloading, setIsloading] = useState(false);
  const [delayedLoader, setDeLayedLoader] = useState(false);

  useEffect(() => {
    if (splitLocation[2] === ':visaType') {
      navigate('/pathways/visa');
    } else if (splitLocation[2] === 'H1Bvisa') {
      setMyVisaType('H1B  visa');
      setVisaValue('H1B');
    }
  }, [paymentProgress]);

  useEffect(() => {
    // dispatch(fetchViasForms(userD?.id, userD?.email, 'H1B', userD?.firstName));

    const url = `https://pathwayapi.wevesti.com/api/v1/pathway/fetch-visa-prices?visaType=H1B`;

    try {
      api.get(url).then(res => {
        setH1bfee(res.data.priceLists[0]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    dispatch(updateUploadedFiles());
  }, [loading]);

  useEffect(() => {
    // Check if Forms is not empty and Forms[0] is not null or undefined
    if (Forms.length > 0 && Forms[0] !== null && Forms[0] !== undefined) {
      // Count the number of non-null values in Forms[0]
      const count = Object.keys(Forms[0]).filter(key => Forms[0][key] !== null)
        .length;
      const TotalCount = Object.keys(Forms[0]).length;
      setCountNonNullFilesValues(count);
      setTotalFileCount(TotalCount);
      setUploadPercentage(
        Forms[0].fileUploadPercentage === null
          ? 0
          : Forms[0].fileUploadPercentage,
      );
      setAmountPaid(Forms[0].amountPaid);
      setPaymentProgress(
        Forms[0].paymentPercent === null ? 0 : Forms[0].paymentPercent,
      );
      setIsSubmitted(Forms[0]?.submitted);
    } else {
      setCountNonNullFilesValues(0);
      setUploadPercentage(0);
      setPaymentProgress(0);
      setIsSubmitted(false);
    }
  }, [Forms, loading]);

  const numberofUnsavedFile = Object.keys(uploadedFiles).length;
  const [paymentModal, setPaymentModal] = useState(false);

  const [showSubmittedForm, setShowSubmmittedForm] = useState(false);

  const [isClarityVideoChecked, setIsClarityVideoChecked] = useState(false);

  const handleVideoCheckboxChange = () => {
    setIsClarityVideoChecked(!isClarityVideoChecked);
  };

  useEffect(() => {
    setDeLayedLoader(true); // Set delayedLoader to true immediately
    setTimeout(() => {
      setDeLayedLoader(false);
      setIsloading(false); // Set delayedLoader back to false after 4 seconds
    }, 4000);
  }, []);

  // console.log('upload', Forms[0]?.fileUploadPercentage);

  return (
    <>
      <Layout>
        <SimpleCancelmodal
          open={paymentModal}
          onClick={() => setPaymentModal(false)}
        >
          <div style={{ marginTop: '20px' }}></div>
          <Payment
            visatitle={`${myvisaType} Visa`}
            visaLink={splitLocation[2]}
            vType={visaValue}
            formId={Forms[0]?.id}
            amountPaid={!Forms[0]?.amountPaid ? 0 : Forms[0]?.amountPaid}
            paymentPercent={paymentProgress}
            h1bFee={h1bFee}
          />
        </SimpleCancelmodal>
        <SimpleCancelmodal
          open={openWarning}
          onClick={() => setOpenWarning(false)}
        >
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={icon}
              alt=""
              style={{ width: '120px', height: '120px' }}
            />
            <p
              style={{
                color: '#67A948',
                fontWeight: '600',
                fontSize: '20px',
              }}
            >
              You have {numberofUnsavedFile} file(s) unsaved
            </p>
            <p style={{ color: '#2B5219', fontSize: '13px' }}>
              If you leave this page, your changes may be lost. Do you want to
              save your changes?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                marginTop: '14px',
                width: '100%',
              }}
            >
              <div
                style={{
                  // background: 'red',
                  color: 'red',
                  fontWeight: '600',
                  fontSize: '13px',
                  padding: '12px 0px',
                  width: '100%',
                  textAlign: 'center',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  border: '1px solid red',
                }}
                onClick={() => {
                  setLoading(true);
                  dispatch(updateUploadedFiles({}));
                  setOpenWarning(false);
                  navigate('/pathways/visa');
                  setLoading(false);
                }}
              >
                Don't Save
              </div>

              <div
                style={{
                  background: '#67A948',
                  color: 'white',
                  fontWeight: '600',
                  fontSize: '13px',
                  padding: '12px 0px',
                  width: '100%',
                  textAlign: 'center',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // updateApplication();
                }}
              >
                Save Changes
              </div>
            </div>
          </div>
        </SimpleCancelmodal>

        <SimpleCancelmodal
          open={opensubmitWarning}
          onClick={() => setOpenSubmitWarning(false)}
        >
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={icon}
              alt=""
              style={{ width: '120px', height: '120px' }}
            />
            <p
              style={{
                color: '#67A948',
                fontWeight: '600',
                fontSize: '20px',
              }}
            >
              {totalFileCount === 0
                ? '48'
                : totalFileCount -
                  (numberofUnsavedFile + countNonNullFilesValues)}{' '}
              file(s) yet to be uploaded
            </p>
            <p style={{ color: '#2B5219', fontSize: '13px' }}>
              Ready to submit? Ensure that you have uploaded all files in each
              section.
            </p>

            {/* <div className="mb-4"></div> */}
            <Platformbutton
              type="normal"
              name={'Okay, thank you'}
              click={() => {
                setOpenSubmitWarning(false);
              }}
            />
          </div>
        </SimpleCancelmodal>

        <SimpleCancelmodal
          open={reportModal}
          onClick={() => setReportModal(false)}
        >
          <div
            style={{
              marginTop: '20px',
            }}
          >
            <ReportForm formId={Forms[0]?.id} setReportModal={setReportModal} />
          </div>
        </SimpleCancelmodal>

        <div className="visaMainFromSection">
          <p
            // onClick={() => {
            //   navigate('/pathways/visa');
            // }}
            style={{
              display: 'flex',
              color: '#67A948',
              alignItems: 'center',
              gap: '5px',
              fontWeight: '600',
              cursor: 'pointer',
              fontSize: '16px',
            }}
            onClick={() => {
              // Object.keys(uploadedFiles).length === 0
              //   ?
              // dispatch(handleSections({}));

              navigate('/pathways/visa');
              // : setOpenWarning(true);
            }}
          >
            <FaArrowLeftLong /> Go Back
          </p>

          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <h1
              style={{
                color: '#14290A',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              U.S. H1B Visa Petition Stage 1 (Lottery Registration)
            </h1>

            <p
              style={{
                color: '#2B5219',
                fontSize: '13px',
                fontWeight: '600',
                display: `${!isSubmitted && !delayedLoader ? 'block' : 'none'}`,
              }}
            >
              Fill out all fields in this form to be entered into the H1B
              Lottery
            </p>
          </div>

          <div
            style={{
              display: `${delayedLoader ? 'flex' : 'none'}`,
              justifyContent: 'space-between',
              width: '90vw',
            }}
          >
            <div
              className=""
              style={{
                display: `${delayedLoader ? 'block' : 'none'}`,
                width: '100%',
              }}
            >
              <SkeletonFormLoader />
            </div>

            <div
              className=""
              style={{
                display: `${delayedLoader ? 'block' : 'none'}`,
                width: '100%',
              }}
            >
              <SkeletonFormLoader />
            </div>
          </div>

          <div className={`${delayedLoader ? '' : 'VisaFormMainPage'}`}>
            <div className="VisaFormMainPage__formsection">
              <>
                <div
                  style={{
                    display: `${
                      !isSubmitted && !delayedLoader
                        ? // &&
                          // Forms[0]?.fileUploadPercentage !== 100
                          'block'
                        : 'none'
                    }`,
                  }}
                >
                  <H1BFullsection
                    isClarityVideoChecked={isClarityVideoChecked}
                    loading={loading}
                    setLoading={setLoading}
                  />

                  {/* <ProgressPage /> */}
                </div>

                <div
                  style={{
                    display: `${
                      isSubmitted && !delayedLoader
                        ? // &&
                          // Forms[0]?.fileUploadPercentage == 100
                          'block'
                        : 'none'
                    }`,
                    height: '100%',
                  }}
                >
                  <ProgressPage lotteryStatus={Forms[0]?.lotteryStatus} />
                  {/* <UploadSuccess
                      setShowSubmmittedForm={setShowSubmmittedForm}
                      paymentProgress={paymentProgress}
                    /> */}
                </div>
              </>
            </div>
            {/* )} */}

            {/* if the form has not been finally submitted, display ends here */}
            <div className="VisaFormMainPage__quickreadSubsection">
              <div
                style={{
                  display: `${
                    !isSubmitted && !delayedLoader
                      ? //  &&
                        // Forms[0]?.fileUploadPercentage !== 100
                        'block'
                      : 'none'
                  }`,
                }}
              >
                <RightSection
                  handleVideoCheckboxChange={handleVideoCheckboxChange}
                  isClarityVideoChecked={isClarityVideoChecked}
                  paymentPercent={paymentProgress}
                  visaValue={visaValue}
                  formId={Forms[0]?.id}
                  firstName={userD?.firstName}
                  h1bFee={h1bFee}
                />
              </div>

              <div
                style={{
                  display: `${
                    isSubmitted && !delayedLoader
                      ? // &&
                        // Forms[0]?.fileUploadPercentage == 100
                        'block'
                      : 'none'
                  }`,
                  // height: '100%',
                  // overflowY: 'auto',
                }}
              >
                <SuccessRight />
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </Layout>
    </>
  );
};
