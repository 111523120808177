/* eslint-disable global-require */

export const Logo = require('./syca.png');
export const MainImg1 = require('./main_img1.svg');
export const MainImg2 = require('./main_img2.svg');
export const MainImg3 = require('./main_img3.svg');
export const MainImg4 = require('./main_img4.svg');
export const MainImg5 = require('./main_img5.svg');
export const AndBtn = require('./and_btn.svg');
export const IosBtn = require('./ios_.svg');
export const VerifyIco = require('./verify_ico.svg');
export const FBIco = require('./FB.svg');
export const TWIco = require('./TW.svg');
export const IGIco = require('./IG.svg');
export const ChevDIco = require('./chev_down.svg');
export const LockIco = require('./lock.svg');
export const LoginImg = require('./girl_pic.svg');
export const SeachIco = require('./searchico.svg');
export const NotifIco = require('./notif.svg');
export const ProfSmImg = require('./profpicsm.svg');
export const AddIco = require('./add_btn.svg');
export const AddCardIco = require('./add_card.svg');
export const VisaImg = require('./vc.svg');
export const MasterCardImg = require('./mc.svg');
export const TransferSuccess = require('./transfer_success.svg');
export const Campaign = require('./campaign.svg');
export const Money = require('./money.svg');
export const Exclusive = require('./exclusive.svg');
export const Platinum = require('./platinum.svg');
export const Standard = require('./standard.svg');
export const Selfcare = require('./selfcare.svg');
export const Hand = require('./hand.svg');
export const Uploadicon = require('./upload.svg');
export const Padlock = require('./padlock.svg');
export const  ArrowDown = require('./Vector.svg');
export const  Japaimg = require('./sidebar-svgs/japaimg.png');
export const  Group = require('./Group.png');
export const  Britain = require('./Britain.png');
export const  sevisserv = require('./sevisserv.png');
export const  ukvisa = require('./ukvisa.png');
export const  USA = require('./USA.png');
export const  usdepartment = require('./usdepartment.png');
export const  wesservice = require('./wesservice.png');
export const  Canada = require('./Canada.png');

export const  feeds = require('./sidebar-svgs/feeds.svg');
export const  member = require('./sidebar-svgs/mmber.svg');
export const  cash = require('./sidebar-svgs/sidebar__bank.svg');
export const  merchant = require('./sidebar-svgs/sidebar__merchants.svg');
export const  airtime = require('./sidebar-svgs/sidebar__bill.svg');
export const  billspayment = require('./sidebar-svgs/airtime.svg');
export const  phone = require('./sidebar-svgs/phone.svg');
export const  advice = require('./sidebar-svgs/adv.svg');
export const  savings = require('./sidebar-svgs/savings.svg');
export const  profile = require('./sidebar-svgs/sidebar__profile.svg');
export const  pathway = require('./sidebar-svgs/sidebar__pway.svg');
export const  provider = require('./sidebar-svgs/provider.svg');
export const  logout = require('./sidebar-svgs/logout.svg');
export const  chistory = require('./sidebar-svgs/creditcard.svg');
export const  cards = require('./sidebar-svgs/sidebar__cards.svg');
export const  cc = require('./sidebar-svgs/sidebar__cc.svg');
export const  feed = require('./sidebar-svgs/feeds.svg');

// radios
export const radio_bank = require('./radios/bank.svg');
export const radio_ach = require('./radios/ach.svg');
export const radio_card = require('./radios/debcrecard.svg');
export const radio_int = require('./radios/international.svg');
export const radio_usd = require('./radios/usdwallet.svg');
export const radio_ngn = require('./radios/ngnwallet.svg');
export const radio_users = require('./radios/vestiusers.svg');
export const radio_achx = require('./radios/achx.svg');
export const radio_cardx = require('./radios/cardx.svg');
export const radio_usdx = require('./radios/usdx.svg');
export const radio_ngnx = require('./radios/ngnwalletx.svg');
export const radio_plaidx = require('./radios/plaidx.svg');
export const radio_plaidusd = require('./radios/plaidusd.svg');
export const radio_plaid = require('./radios/plaid.svg');
export const radio_ngnusd = require('./radios/ngnwalletusd.svg');
export const radio_cardusd = require('./radios/debcrecardusd.svg');
export const radio_mail = require('./radios/sendtomail.svg');
export const radio_achusd = require('./radios/achusd.svg');
export const radio_usersusd = require('./radios/vestiusersusd.svg');

//  currencies
export const naira = require('./currency/naira.svg');
export const cedis = require('./currency/cedis.svg');
export const pesos = require('./currency/pesos.svg');
export const kwacha = require('./currency/kwacha.svg');
export const pounds = require('./currency/gbp.svg');