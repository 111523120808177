import React, { useState } from 'react';
import vwhite from 'assets/vestiwhite.svg';
import Visa from 'assets/VisaIcon.svg';
import Mastercard from 'assets/mastercard.svg';
import './singlecard.scss';
import '../../containers/Admin/allcards/slider.css';
import { openUpdateBox } from 'appRedux/actions/update';
import { useDispatch } from 'react-redux';
import { getCardImage } from 'helpers/utils';
// import { cardColors } from "helpers/data";

export const Singlecard = props => {
  const [show, setShow] = useState(false);
  var dispatch = useDispatch();

  var openUpdateModal = () => {
    dispatch(openUpdateBox());
  };

  switch (props.data.providerName) {
    case 'Mono':
    case 'Checkbook':
      return (
        <Virtualcard
          userdata={props.userdata}
          data={props.data}
          show={show}
          setShow={setShow}
          goTo={props.goTo}
          from={props.from}
          openUpdateModal={openUpdateModal}
        />
      );
    case 'Stripe':
      return (
        <Founderscard
          userdata={props.userdata}
          data={props.data}
          show={show}
          setShow={setShow}
          goTo={props.goTo}
          from={props.from}
          details={props.details}
          openUpdateModal={openUpdateModal}
        />
      );
    case 'tyrus':
      return (
        <Founderscard
          userdata={props.userdata}
          data={props.data}
          show={show}
          setShow={setShow}
          goTo={props.goTo}
          from={props.from}
          details={props.details}
          openUpdateModal={openUpdateModal}
        />
      );
    default:
      return (
        <Founderscard
          userdata={props.userdata}
          data={props.data}
          show={show}
          setShow={setShow}
          goTo={props.goTo}
          from={props.from}
          details={props.details}
          openUpdateModal={openUpdateModal}
        />
      );
  }
};

export const Virtualcard = props => {
  const [show, setShow] = useState(false);
  console.log('cardProvider :', props.data.cardBrand);
  // var  cardNumber = props.from ? props.data.cardNumber :props.data.cardNumber
  return (
    <div
      className={`ccard whiteText ${
        props.data.cardColor === 'Yellow' ? 'bluebg' : 'bg-black'
      }`}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img
          src={props.data.cardBrand === 'MasterCard' ? Mastercard : Visa}
          alt="visa"
          style={{ width: '40px' }}
        />
        <img src={vwhite} alt="vesti white" style={{ width: '40px' }} />
      </div>

      {
        <p style={{ fontWeight: '400' }}>
          {show
            ? props.data.cardNumber.replace(/(\d{4})/g, '$1 ')
            : props.data.cardNumber
            ? 'XXXX XXXX XXXX' + props.data.cardNumber.slice(-4)
            : 'XXXXXX'}
          <i
            className={`fas fa-${show ? 'eye-slash' : 'eye'}`}
            onClick={() => setShow(!props.show)}
          ></i>
        </p>
      }

      <div className="cardBtm">
        <span className="">
          <p style={{ fontWeight: '300', fontSize: '13px', color: '#8A8A8B' }}>
            BALANCE
          </p>
          <p style={{ fontSize: '24px', fontWeight: '700' }}>
            $
            {props.data.providerName === 'Checkbook' ||
            props.data.balance === null ? (
              <p>***</p>
            ) : (
              parseFloat(props.data.balance / 100)
            )}
            {/* {props.from
              ? (props.data.balance / 100).toLocaleString('en-us')
              : (parseInt(props.data.balance) / 100).toLocaleString('en-us')} */}
          </p>
        </span>
        <p style={{ fontSize: '12px', fontWeight: '700' }}>
          {props.data.cardName.toUpperCase()}
        </p>
      </div>

      <img
        src={getCardImage(props.data.cardColor)}
        className="gg"
        style={{
          position: 'absolute',
          width: '20rem',
          height: '17rem',
          right: '50px',
          bottom: '10px',
        }}
        alt="gg"
      />
      {props.children}
    </div>
  );
};

export const Founderscard = props => {
  const [show, setShow] = useState(false);
  console.log('Founderscard cardProvider :', props.data.cardBrand);
  return (
    <div
      className={`ccard whiteText ${
        props.data.cardColor === 'Yellow' ? 'bluebg' : 'bg-black'
      }`}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img
          src={props.data.cardBrand === 'MasterCard' ? Mastercard : Visa}
          alt="mastercard"
          style={{ width: '40px' }}
        />
        <img src={vwhite} alt="vesti white" style={{ width: '40px' }} />
      </div>

      {
        <p style={{ fontWeight: '400' }}>
          {show
            ? props.data.cardNumber.replace(/(\d{4})/g, '$1 ')
            : props.data.cardNumber
            ? 'XXXX XXXX XXXX' + props.data.cardNumber.slice(-4)
            : 'XXXXXX'}
          <i
            className={`fas fa-${show ? 'eye-slash' : 'eye'}`}
            onClick={() => setShow(!props.show)}
          ></i>
        </p>
      }

      <div className="cardBtm">
        <span className="">
          <p style={{ fontWeight: '300', fontSize: '13px', color: '#8A8A8B' }}>
            BALANCE
          </p>
          <p style={{ fontSize: '24px', fontWeight: '700' }}>
            $
            {props.from
              ? (props.data.balance / 100).toLocaleString('en-us')
              : (parseInt(props.data.balance) / 100).toLocaleString('en-us')}
          </p>
        </span>
        <p style={{ fontSize: '12px', fontWeight: '700' }}>
          {props.data.cardName.toUpperCase()}
        </p>
      </div>

      <img
        src={getCardImage(props.data.cardColor)}
        className="gg"
        style={{
          position: 'absolute',
          width: '20rem',
          height: '17rem',
          right: '50px',
          bottom: '10px',
        }}
        alt="gg"
      />
      {props.children}
    </div>
  );
};
