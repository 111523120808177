import React, { useState, useEffect, useRef } from 'react';
import {
  RadioOption,
  VerySmallRadioOption,
} from 'components/common/radiobutton/radiobutton';
import { Platformbutton } from 'components/common/button/button';
import { radio_card, radio_ngn } from 'assets/assets';
import DividedPinInput from 'components/common/DividedPinInput';
// import '../../../components/common/completetransaction/comptransaction.scss';
// import './subindex.scss';
import { Success } from 'components/common/success/success';
import errorsvg from 'assets/newerror.svg';
import api from 'appRedux/api';
import { objectValuesStringify } from 'helpers/utils';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Getpricefromdollar } from 'containers/Admin/Streaming/getpricefromdollar';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import gbp from 'assets/currency/unitedkingdomFlag.png';
import { Form } from 'antd';
import Loader from 'components/Loader';
import { VisaRequirementModal, H1BVisaRequirementModal } from './visatypes';
import bank from 'assets/viasForm/top.svg';
import './visatype.scss';
import { useReactToPrint } from 'react-to-print';
import { InvoiceComponent } from './Invoice/Invoice';
import { PaystackButton } from 'react-paystack';
import { UseWebView } from './Invoice/webView';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const Payment = props => {
  const userData = useSelector(state => state.auth?.userData);
  const [amount, setAmount] = useState();
  const [actualAmt, setActualAmt] = useState();

  const [walletoption, setWalletOption] = useState('USD');

  const [rate, setRate] = useState(null);

  const [ngnInvoice, setNgnInvoive] = useState(null);

  useEffect(() => {
    // settIsLoading(true);
    const fetchpriceData = async () => {
      try {
        const response = await api.get(
          `/vesti-fx/lists?currency=${walletoption}`,
        );
        setRate(response.data?.usdConversionRate);
        // settIsLoading(false);
      } catch (error) {
        // settIsLoading(false);
        console.log(error);
      }
    };

    fetchpriceData();
  }, [walletoption]);

  useEffect(() => {
    const url = `https://pathwayapi.wevesti.com/api/v1/pathway/fetch-visa-prices?visaType=${props.vType}`;
    const amountPaid = props.paymentPercent / 100;

    try {
      api.get(url).then(res => {
        // setAmount(res.data.priceLists[0].amount);
        // setAmount(res.data.priceLists[0].amount - amountPaid);
        setActualAmt(res.data.priceLists[0].amount);

        props.paymentPercent == 0 || !props.paymentPercent
          ? setAmount(res.data.priceLists[0].amount)
          : setAmount(
              res.data.priceLists[0].amount -
                amountPaid * res.data.priceLists[0].amount,
            );

        // cb();
      });
    } catch (error) {
      console.log(error);
    }
  }, [props.paymentPercent]);

  const [option, setOption] = useState('full-payment');
  const [paymentmethodoption, setPaymentmethodOption] = useState('wallet');
  const [installmental, setInstallmental] = useState(50);

  const [step, setStep] = useState(1);
  const [transactionPin, setTransactionPin] = useState('');
  const [formdata, setformdata] = useState({});

  const [errormsg, setErrorMsg] = useState('');
  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);
  const storedCurrency = localStorage.getItem('currency');

  const id = userData?.id;
  // console.log(userData.address.countryCode);

  const setPaymentOption = value => {
    setOption(value);
  };

  const handleInstallmentals = value => {
    setInstallmental(value);
  };

  const setWalletPaymentOption = value => {
    setWalletOption(value);
  };

  const handlePaymentOption = value => {
    setPaymentmethodOption(value);
  };

  var goBack = () => {
    step <= 1 ? setStep(0) : setStep(step - 1);
  };

  const handleCompleteTransaction = async formId => {
    const actualAmount = (installmental / 100) * (actualAmt * rate);

    const newAmount = amount * rate;

    const paymentPercentage = props.paymentPercent
      ? parseInt(props.paymentPercent) + parseInt(installmental) > 100
        ? 100
        : parseInt(props.paymentPercent) + parseInt(installmental)
      : installmental;

    const payload = {
      userId: userData?.id,
      email: userData?.email,
      firstName: userData?.firstName,
      fullname: `${userData?.firstName} ${userData?.lastName}`,
      currency: walletoption,
      amount: option === 'full-payment' ? newAmount : actualAmount,
      visaType: props.vType,
      // +props.paymentPercent + +
      paymentPercent: option === 'full-payment' ? 100 : paymentPercentage,
      amountPaid: option === 'full-payment' ? newAmount : actualAmount,
      totalCost: actualAmt * rate,
      // amountPaid: props.amountPaid,
      // submitted: false,
      id: props.formId ? props.formId : formId,
      paymentOption: paymentmethodoption,
      transactionOtp: transactionPin,
    };

    const url = `https://pathwayapi.wevesti.com/api/v1/pathway/pay-for-visa-document`;

    try {
      const res = await api.post(url, payload);
      const { data } = res;
      setStep(5);
      // props.setOpenModal(false);
    } catch (error) {
      console.log(error);
      if (error?.data?.errors) {
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Visa Form Payment',
          'error',
          setErrorMsg(error?.data?.errors),
        );
      } else {
        const err = error?.data?.message || error.message;
        openNotificationWithIconErr(err, 'Visa Form Payment', 'error');
        setErrorMsg(err);
      }
      setStep(6);
      // setLoading(false);
    }
  };

  const handleCompleteTransactionwithcard = async formId => {
    const actualAmount = (installmental / 100) * (actualAmt * rate);

    const newAmount = amount * rate;

    const paymentPercentage = props.paymentPercent
      ? parseInt(props.paymentPercent) + parseInt(installmental) > 100
        ? 100
        : parseInt(props.paymentPercent) + parseInt(installmental)
      : installmental;

    const payload = {
      userId: userData?.id,
      email: userData?.email,
      firstName: userData?.firstName,
      fullname: `${userData?.firstName} ${userData?.lastName}`,
      currency: 'USD',
      amount: option === 'full-payment' ? newAmount : actualAmount,
      visaType: props.vType,
      paymentPercent: option === 'full-payment' ? 100 : paymentPercentage,
      amountPaid: option === 'full-payment' ? newAmount : actualAmount,
      totalCost: actualAmt * rate,

      id: props.formId ? props.formId : formId,
      paymentOption: paymentmethodoption,
    };

    const url = `https://pathwayapi.wevesti.com/api/v1/pathway/pay-for-visa-document`;

    try {
      const res = await api.post(url, payload);
      const { data } = res;
      setTimeout(() => {
        window.open(data?.data?.url);
      }, 100);
    } catch (error) {
      console.log(error);
      if (error?.data?.errors) {
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Visa Form Payment',
          'error',
          setErrorMsg(error?.data?.errors),
        );
      } else {
        const err = error?.data?.message || error.message;
        openNotificationWithIconErr(err, 'Visa Form Payment', 'error');
        setErrorMsg(err);
      }
      setStep(6);
      // setLoading(false);
    }
  };

  const [invoiceDetails, setInvoiceDetails] = useState({
    accountName: '',
    amountdue: '',
    customerEmail: '',
    customerName: '',
    number: '',
  });

  const payForVisaWithoutFormId = async () => {
    var url =
      'https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents';
    const formData = new FormData();

    formData.append('email', userData?.email);
    formData.append('userId', userData?.id);
    formData.append('userId', userData?.firstName);
    formData.append('visaType', props.vType);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      if (paymentmethodoption === 'wallet') {
        handleCompleteTransaction(data.formId);
      } else {
        handleCompleteTransactionwithcard(data.formId);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const createForm = async () => {
    var url =
      'https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents';
    const formData = new FormData();

    formData.append('email', userData?.email);
    formData.append('userId', userData?.id);
    formData.append('userId', userData?.firstName);
    formData.append('visaType', props.vType);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      setStep(7);
    } catch (error) {
      console.error('Error creating file:', error);
    }
  };

  // Calculate the expiration date by adding 14 days to the current date
  const currentDate = new Date();

  const expirationDate = new Date();
  expirationDate.setDate(currentDate.getDate() + 14);

  // Format the current date and expiration date as 'DD/MM/YYYY' strings
  const formatDate = date => {
    const day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const expirationDateString = formatDate(expirationDate);

  const pathname = window.location.pathname;
  const splitLocation = pathname.split('/');

  // Invoice Stripe

  const handlegetInvoice = async formId => {
    setStep(10);
    const payload = {
      formId: props.formId ? props.formId : formId,
      // userId: userData?.id,
      description: `Invoice payment for ${props.vType} Visa`,
      customer_email: userData?.email,
      customer_name: `${userData?.firstName} ${userData?.lastName}`,
      amountCents:
        option === 'full-payment' ? amount : (installmental / 100) * amount,
    };

    const url = `https://pathwayapi.wevesti.com/api/v1/pathway/invoice-payment`;

    try {
      const res = await api.post(url, payload);
      // console.log(res);
      setInvoiceDetails({
        accountName: res.data?.data?.account_name,
        amountdue: res.data?.data?.amount_due,
        customerEmail: res.data?.data?.customer_email,
        customerName: res.data?.data?.customer_name,
        number: res.data?.bank_information?.accountNumber,
        bankName: res.data?.bank_information?.bankName,
        routingNumber: res.data?.bank_information?.routingNumber,
        swiftCode: res.data?.bank_information?.swiftCode,
      });
      setStep(8);
      //  handlePrint();
      // props.setOpenModal(false);
    } catch (error) {
      console.log(error);
      setStep(3);
      if (error?.data?.errors) {
        openNotificationWithIconErr(
          objectValuesStringify(error?.data?.errors),
          'Visa Form Payment',
          'error',
        );
      } else {
        const err = error?.data?.message || error.message;
        openNotificationWithIconErr(err, 'Visa Form Payment', 'error');
      }
    }
  };

  const getInvoiceWithoutFormId = async () => {
    var url =
      'https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents';
    const formData = new FormData();

    formData.append('email', userData?.email);
    formData.append('userId', userData?.id);
    formData.append('visaType', props.vType);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      handlegetInvoice(data.formId);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const fetchNGNinvoiceData = async () => {
    setStep(10);

    //pathwayapi.wevesti.com/api/v1/pathway/invoice-payment
    try {
      const response = await api.get(
        `https://pathwayapi.wevesti.com/api/v1/pathway/invoice-account`,
      );

      setNgnInvoive(response.data?.bank_information);
      setStep(8);
      // settIsLoading(false);
    } catch (err) {
      // settIsLoading(false);
      openNotificationWithIconErr(err, 'Visa Form Payment', 'error');
    }
  };

  switch (step) {
    case 1:
      return (
        <>
          <div className="">
            <div className="w-full">
              <div style={{ marginBottom: '1rem' }}>
                <h2
                  style={{
                    color: '#67A948',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >{` Step 1 of 3`}</h2>
                <p
                  className=" text-[#14290A]"
                  style={{
                    fontSize: '24px',
                    fontWeight: '600',
                    paddingTop: '1rem',
                  }}
                >
                  Select Payment Plan
                </p>
                <p style={{ color: '#2B5219' }}>
                  Select the payment plan you prefer
                </p>
              </div>

              <div className="paymentoption-inner mt-8 center mb-8">
                <RadioOption
                  image={radio_ngn}
                  changed={setPaymentOption}
                  id="1"
                  isSelected={option === 'full-payment'}
                  label={
                    <div style={{ display: 'flex' }}>
                      Full Payment ({' '}
                      <Getpricefromdollar
                        amount={amount}
                        currency={walletoption}
                      />{' '}
                      )
                    </div>
                  }
                  sublabel="Make full payment and proceed with uploading your documents."
                  value="full-payment"
                />

                {props.paymentPercent > 50 ? (
                  <></>
                ) : (
                  <>
                    {' '}
                    <div className="mb-4"></div>
                    <RadioOption
                      image={radio_card}
                      changed={setPaymentOption}
                      id="2"
                      isSelected={option === 'installment'}
                      label="Part Payment (Pay Small Small)"
                      sublabel="Make part payment and proceed with uploading your documents."
                      value="installment"
                    />
                  </>
                )}

                {splitLocation[2] === 'visa' ? (
                  <>
                    {' '}
                    <div className="mb-4"></div>
                    <RadioOption
                      image={radio_card}
                      changed={setPaymentOption}
                      id="3"
                      isSelected={option === 'nopay'}
                      label="Pay Later"
                      sublabel="Continue with uploading your documents and pay later."
                      value="nopay"
                    />
                  </>
                ) : (
                  <></>
                )}

                <div className="mb-4"></div>
              </div>
              {option === 'nopay' ? (
                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() => (props.formId ? setStep(7) : createForm())}
                  classname="fas fa-arrow-right"
                />
              ) : (
                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() =>
                    option === 'installment' ? setStep(9) : setStep(2)
                  }
                  classname="fas fa-arrow-right"
                />
              )}
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div className="">
            <div className="w-full">
              <div style={{ marginBottom: '1rem' }}>
                <h2
                  style={{
                    color: '#67A948',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >{` Step 2 of 3`}</h2>
                <p
                  className=" text-[#14290A]"
                  style={{
                    fontSize: '24px',
                    fontWeight: '600',
                    paddingTop: '1rem',
                  }}
                >
                  {' '}
                  Select Payment Method
                </p>
                <p style={{ color: '#2B5219' }}>
                  Select the payment method you prefer
                </p>
              </div>

              <div className="paymentoption-inner mt-8 center mb-8">
                <RadioOption
                  image={radio_ngn}
                  changed={handlePaymentOption}
                  id="1"
                  isSelected={paymentmethodoption === 'wallet'}
                  label="Pay via your Vesti Wallet"
                  sublabel="Payment will be processed immediately"
                  value="wallet"
                />
                <div className="mb-4"></div>
                <RadioOption
                  image={radio_card}
                  changed={handlePaymentOption}
                  id="2"
                  isSelected={paymentmethodoption === 'card'}
                  label="Pay via your Debit Card"
                  value="card"
                  sublabel="Payment will be processed immediately"
                />
                <div className="mb-4"></div>
                <RadioOption
                  image={radio_card}
                  changed={handlePaymentOption}
                  id="3"
                  isSelected={paymentmethodoption === 'transfer'}
                  label="Pay via Bank Transfer"
                  value="transfer"
                  sublabel="Payment will take 2-3 days to be confirmed"
                />

                <div className="mb-4"></div>
              </div>
              <div className="flexButton" style={{}}>
                <div style={{ width: '100%' }}>
                  {' '}
                  <Platformbutton
                    type="secondary"
                    name="Back"
                    click={() => setStep(1)}
                    classname="fas fa-arrow-right"
                  />
                </div>

                <div style={{ width: '100%' }}>
                  {' '}
                  {paymentmethodoption === 'wallet' ? (
                    <Platformbutton
                      type="normal"
                      name="Proceed"
                      click={() => setStep(3)}
                      classname="fas fa-arrow-right"
                    />
                  ) : paymentmethodoption === 'card' ? (
                    <Platformbutton
                      type="normal"
                      name="Proceed"
                      click={() =>
                        props.formId
                          ? handleCompleteTransactionwithcard()
                          : payForVisaWithoutFormId()
                      }
                      classname="fas fa-arrow-right"
                    />
                  ) : (
                    <Platformbutton
                      type="normal"
                      name="Proceed"
                      click={() => setStep(3)}
                      classname="fas fa-arrow-right"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4"></div>
            <p
              style={{
                color: '#14290A',
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              If your payment option is not available, contact our finance team{' '}
              <span
                style={{
                  color: '#67A948',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                finance@Wevesti.com
              </span>
            </p>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <div className="">
            <div className="w-full">
              <div style={{ marginBottom: '1rem' }}>
                {/* <h2
                  style={{
                    color: '#67A948',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >{`Step 1 of 2`}</h2> */}
                <h2 className="font-[600] text-[24px]  text-[#14290A]">
                  Select{' '}
                  {`${
                    paymentmethodoption === 'transfer' ? 'Currency' : 'Wallet'
                  }`}
                </h2>
                <p style={{ color: '#2B5219' }}>
                  Select a currency from our options.
                </p>
              </div>

              <div
                className="paymentoption-inner mt-8 center mb-8"
                style={{ maxHeight: '300px', overflowY: 'scroll' }}
              >
                {allWallet.map(({ id, currencyFlag, currency }) => {
                  // const selectedCurrency = currency.find(() => {});
                  return (
                    <div key={id} className="w-full">
                      {currency === 'USD' || currency === 'NGN' ? (
                        <RadioOption
                          image={currency === 'GBP' ? gbp : currencyFlag}
                          changed={setWalletPaymentOption}
                          id={id}
                          isSelected={walletoption === currency}
                          label={
                            paymentmethodoption === 'transfer'
                              ? `${currency}`
                              : `${currency} Wallet`
                          }
                          sublabel={
                            paymentmethodoption === 'transfer'
                              ? `Transfer in ${currency}`
                              : `Send money with ${currency} Wallet`
                          }
                          value={currency}
                        />
                      ) : (
                        <div></div>
                      )}

                      <div className="mb-4"></div>
                    </div>
                  );
                })}
              </div>

              <div className="flexButton" style={{}}>
                <div style={{ width: '100%' }}>
                  {' '}
                  <Platformbutton
                    type="secondary"
                    name="Back"
                    click={() =>
                      paymentmethodoption === 'transfer'
                        ? setStep(2)
                        : setStep(2)
                    }
                    classname="fas fa-arrow-right"
                  />
                </div>
                <div style={{ width: '100%' }}>
                  {paymentmethodoption === 'transfer' ? (
                    <Platformbutton
                      type="normal"
                      name="Continue"
                      click={() => {
                        if (walletoption === 'USD') {
                          if (props.formId) {
                            handlegetInvoice();
                          } else {
                            getInvoiceWithoutFormId();
                          }
                        } else if (walletoption === 'NGN') {
                          fetchNGNinvoiceData();
                        }
                      }}
                      classname="fas fa-arrow-right"
                    />
                  ) : (
                    <Platformbutton
                      type="normal"
                      name="Continue"
                      click={() => setStep(4)}
                      classname="fas fa-arrow-right"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="mb-4"></div>
            <p
              style={{
                color: '#14290A',
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              If your payment option is not available, contact our finance team{' '}
              <span
                style={{
                  color: '#67A948',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                finance@Wevesti.com
              </span>
            </p>
          </div>
        </>
      );
    case 4:
      return (
        <>
          {' '}
          <div className="">
            <div className="w-full">
              <div style={{ marginBottom: '1rem' }}>
                <h2
                  style={{
                    color: '#67A948',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >{` Step 3 of 3`}</h2>
                <h2 className="font-[600] text-[24px]  text-[#14290A]">
                  Transaction PIN
                </h2>
                <p style={{ color: '#2B5219', fontWeight: '600' }}>
                  Enter your 4 digit transaction PIN to finalize this
                  transaction.
                </p>
              </div>

              <div
                style={{
                  background: '#F7FFF3',
                  width: '100%',
                  padding: '30px',
                  border: '0.5px solid #97E373',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  borderRadius: '8px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="leftsideSubPayload">Transaction Type</p>
                  <p className="rightsideSubPayload">
                    VISA REQUIREMENT SUBMISSION
                  </p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 460 2"
                  fill="none"
                >
                  <path
                    d="M0 1H460"
                    stroke="#67A948"
                    stroke-width="0.8"
                    stroke-dasharray="4 8"
                  />
                </svg>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="leftsideSubPayload">Payment Plan</p>
                  <p className="rightsideSubPayload">
                    {option === 'full-payment'
                      ? 'Full Payment'
                      : 'Half Payment'}
                  </p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 460 2"
                  fill="none"
                >
                  <path
                    d="M0 1H460"
                    stroke="#67A948"
                    stroke-width="0.8"
                    stroke-dasharray="4 8"
                  />
                </svg>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <p className="leftsideSubPayload">Amount</p>
                  <p className="rightsideSubPayload">
                    <Getpricefromdollar
                      amount={
                        option === 'full-payment'
                          ? amount
                          : (installmental / 100) * actualAmt
                      }
                      currency={walletoption}
                    />
                  </p>
                </div>
              </div>

              <div className="paymentoption-inner mt-8 center mb-8">
                <CompSubtransaction
                  setPin={setTransactionPin}
                  //   loading={true}
                  goBack={() => goBack()}
                  btn="Initiate Payment"
                  onFinish={() =>
                    props.formId
                      ? handleCompleteTransaction()
                      : payForVisaWithoutFormId()
                  }
                  len={4}
                />
              </div>
            </div>
          </div>
        </>
      );
    case 5:
      return (
        <>
          {' '}
          <Success
            title={`Payment Successful`}
            subtitle={`You have successfully made payment for your Visa Form Requirement Document Submission. You can now proceed to uploading your documents`}
            button="Okay, Thank You!"
            onClick={() => props.setPaymentModal(false)}
          />
        </>
      );

    case 6:
      return (
        <>
          <Success
            image={errorsvg}
            title={'Payment Failed!'}
            subtitle={errormsg}
            onClick={() => setStep(1)}
            button="Try Again"
            type="error"
          />
        </>
      );
    case 7:
      return (
        <>
          {props.vType === 'H1B' ? (
            <H1BVisaRequirementModal
              visatitle={props.visatitle}
              visaLink={props.visaLink}
              vType={props.vType}
            />
          ) : (
            <VisaRequirementModal
              visatitle={props.visatitle}
              visaLink={props.visaLink}
              vType={props.vType}
              setVisaReqModal={props.setVisaReqModal}
              setPaymentModal={props.setPaymentModal}
            />
          )}
        </>
      );

    case 8:
      return (
        <BankTransfer
          currency={walletoption}
          userData={userData}
          formId={props.formId}
          vType={props.vType}
          option={option}
          amount={amount}
          installmental={installmental}
          rate={rate}
          setStep={setStep}
          invoiceDetails={invoiceDetails}
          setInvoiceDetails={setInvoiceDetails}
          ngnInvoice={ngnInvoice}
        />
      );

    case 9:
      return (
        <div>
          {' '}
          <>
            <div className="">
              <div className="w-full">
                <div style={{ marginBottom: '1rem' }}>
                  {/* <h2
                    style={{
                      color: '#67A948',
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >{` Step 1 of 3`}</h2> */}
                  <p
                    className=" text-[#14290A]"
                    style={{
                      fontSize: '24px',
                      fontWeight: '600',
                      paddingTop: '1rem',
                    }}
                  >
                    Part payment{' '}
                  </p>
                  <p
                    style={{
                      color: '#2B5219',
                      fontWeight: '600',
                      display: 'flex',
                      gap: '4px',
                    }}
                  >
                    Make a part payment of the sum of{' '}
                    <span style={{ color: '#67A948' }}>
                      {' '}
                      <Getpricefromdollar
                        amount={actualAmt}
                        currency={walletoption}
                      />
                    </span>{' '}
                  </p>
                </div>

                <div className="paymentoption-inner mt-8 center mb-8">
                  <RadioOption
                    image={radio_ngn}
                    changed={handleInstallmentals}
                    id="1"
                    isSelected={installmental === 25}
                    label={
                      <div style={{ display: 'flex' }}>
                        Pay 25% ({' '}
                        <Getpricefromdollar
                          amount={(25 / 100) * actualAmt}
                          currency={walletoption}
                        />{' '}
                        )
                      </div>
                    }
                    // label="Pay 25% (NGN 50,000)"
                    sublabel="This shows your commitment, while document review starts at 50% payment."
                    value={25}
                  />

                  {props.paymentPercent > 50 ? (
                    <></>
                  ) : (
                    <>
                      {' '}
                      <div className="mb-4"></div>
                      <RadioOption
                        image={radio_card}
                        changed={handleInstallmentals}
                        id="2"
                        isSelected={installmental === 50}
                        label={
                          <div style={{ display: 'flex' }}>
                            Pay 50% ({' '}
                            <Getpricefromdollar
                              amount={(50 / 100) * actualAmt}
                              currency={walletoption}
                            />{' '}
                            )
                          </div>
                        }
                        sublabel="This commitment locks you in, and you can submit your document review after uploading."
                        value={50}
                      />
                    </>
                  )}

                  {props.paymentPercent > 25 ? (
                    <></>
                  ) : (
                    <>
                      {' '}
                      <div className="mb-4"></div>
                      <RadioOption
                        image={radio_card}
                        changed={handleInstallmentals}
                        id="3"
                        isSelected={installmental === 75}
                        label={
                          <div style={{ display: 'flex' }}>
                            Pay 75% ({' '}
                            <Getpricefromdollar
                              amount={(75 / 100) * actualAmt}
                              currency={walletoption}
                            />{' '}
                            )
                          </div>
                        }
                        sublabel="This commitment locks you in, and you can submit your document review after uploading."
                        value={75}
                      />
                    </>
                  )}

                  <div className="mb-4"></div>
                </div>

                <div className="flexButton" style={{}}>
                  <div style={{ width: '100%' }}>
                    {' '}
                    <Platformbutton
                      type="secondary"
                      name="Back"
                      click={() => setStep(1)}
                      classname="fas fa-arrow-right"
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <Platformbutton
                      type="normal"
                      name="Continue"
                      click={() => setStep(2)}
                      classname="fas fa-arrow-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      );

    case 10:
      return (
        <div style={{ width: '100%' }}>
          <img
            src={bank}
            alt=""
            style={{
              position: 'absolute',
              left: '0px',
              top: '0px',
              right: '0px',
              width: '100%',
            }}
          />

          <div
            style={{
              marginTop: '13rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                marginBottom: '0.5rem',
              }}
            >
              <p
                style={{
                  color: '#2B5219',
                  fontSize: '20px',
                  fontWeight: '600',
                  display: 'flex',
                  gap: '8px',
                }}
                // onClick={() => {
                //   props.setStep(10);
                // }}
              >
                {' '}
                Pay <Skeleton width={70} height={15} duration={1} />
              </p>
              <p
                style={{
                  color: '#67A948',
                  fontWeight: '600',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // props.formId ? handlegetInvoice() : getInvoiceWithoutFormId();
                }}
              >
                {/* Download Invoice */}
              </p>
            </div>
            <p
              style={{ color: '#3E6F26', fontSize: '13px', fontWeight: '600' }}
            >
              Attached below are the details of the account to deposit money to.
            </p>

            <div className="visaPaymentBankGrid">
              <Skeleton width={215} height={40} duration={1} />
              <Skeleton width={215} height={40} duration={1} />
              <Skeleton width={215} height={40} duration={1} />
              <Skeleton width={215} height={40} duration={1} />
            </div>

            <p
              style={{ color: '#3E6F26', fontSize: '13px', fontWeight: '600' }}
            >
              NB: Payment approval will take 2-3 days. Contact{' '}
              <span style={{ color: '#67A948' }}>finance@wevesti.com </span>
              if you encounter any issues.
            </p>
          </div>
        </div>
      );
    default:
      return <>Not Found</>;
  }
};

export const CompSubtransaction = props => {
  return (
    <Form onFinish={props.onFinish} style={{ width: '100%' }}>
      {props.lower ? <></> : props.children}
      <div className="w-100 flex-fill pt-4" style={{ width: '100%' }}>
        <DividedPinInput
          onChange={props.setPin}
          len={props.len}
          open={props.open}
        />
      </div>
      {props.loading ? (
        <Loader />
      ) : props.children ? (
        props.children
      ) : (
        <div className="btn-container">
          <Platformbutton
            name="Back"
            type="secondary"
            click={() => props.goBack()}
          />

          <Platformbutton name={props.btn} type="submit" />
        </div>
      )}
    </Form>
  );
};

const BankTransfer = props => {
  const bankdata = [
    {
      name: 'Account Number',
      action: props.invoiceDetails?.number,
    },
    {
      name: 'Account Name',
      action: props.invoiceDetails?.accountName,
    },
    {
      name: 'Bank Name',
      action: props.invoiceDetails?.bankName,
    },
    {
      name: 'Routing Number',
      action: props.invoiceDetails?.routingNumber,
    },

    // {
    //   name: 'Swift Code',
    //   action: props.invoiceDetails.swiftCode,
    // },
  ];

  const ngnbankdata = [
    {
      name: 'Account Number',
      action: props.ngnInvoice?.accountNumber,
    },
    {
      name: 'Account Name',
      action: props.ngnInvoice?.accountName,
    },
    {
      name: 'Bank Name',
      action: props.ngnInvoice?.bankName,
    },
  ];

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [Copystate, setIsCopystate] = useState(bankdata.map(() => false));

  const board = useRef(null);

  const copyCodeToClipboard = (action, index) => {
    navigator.clipboard.writeText(action);

    const newCopyStates = [...Copystate];
    newCopyStates[index] = true;
    setIsCopystate(newCopyStates);

    setTimeout(() => {
      const resetCopyStates = [...Copystate];
      resetCopyStates[index] = false;
      setIsCopystate(resetCopyStates);
    }, 3000);
  };

  const toWholeCurrency = num =>
    (num / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  // console.log(invoiceDetails);

  const url = 'https://www.linkedin.com/in/mohammadrostami/';

  {
    /* setInvoiceDetails({
     accountName: res.data?.data?.account_name,
     amountdue: res.data?.data?.amount_due,
     customerEmail: res.data?.data?.customer_email,
     customerName: res.data?.data?.customer_name,
     number: res.data?.bank_information?.accountNumber,
     bankName: res.data?.bank_information?.bankName,
     routingNumber: res.data?.bank_information?.routingNumber,
     swiftCode: res.data?.bank_information?.swiftCode,
   }); */
  }

  console.log('ngn details', props.ngnInvoice);
  console.log('usd invoice', props.invoiceDetails);

  return (
    <>
      <div style={{ width: '100%' }}>
        <img
          src={bank}
          alt=""
          style={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            right: '0px',
            width: '100%',
          }}
        />

        <div
          style={{
            marginTop: '13rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              marginBottom: '0.5rem',
            }}
          >
            <p
              style={{
                color: '#2B5219',
                fontSize: '20px',
                fontWeight: '600',
                display: 'flex',
                gap: '4px',
              }}
              // onClick={() => {
              //   props.setStep(10);
              // }}
            >
              {' '}
              Pay{' '}
              <Getpricefromdollar
                amount={
                  props.option === 'full-payment'
                    ? props.amount
                    : (props.installmental / 100) * props.amount
                }
                currency={props.currency}
              />
            </p>
            <p
              style={{
                color: '#67A948',
                fontWeight: '600',
                cursor: 'pointer',
              }}
              onClick={() => {
                handlePrint();
                // props.formId ? handlegetInvoice() : getInvoiceWithoutFormId();
              }}
            >
              Download Invoice
            </p>
          </div>
          <p style={{ color: '#3E6F26', fontSize: '13px', fontWeight: '600' }}>
            Attached below are the details of the account to deposit money to.
          </p>

          <div className="visaPaymentBankGrid">
            {props.currency === 'USD' ? (
              <>
                {bankdata.map(({ name, action }, index) => {
                  return (
                    <div key={index}>
                      <div style={{ display: 'none' }}>
                        <InvoiceComponent
                          ref={componentRef}
                          invoiceDetails={props.invoiceDetails}
                          currency={props.currency}
                          amount={
                            props.option === 'full-payment'
                              ? toWholeCurrency(props.amount * props.rate)
                              : toWholeCurrency(
                                  (props.installmental / 100) *
                                    props.amount *
                                    props.rate,
                                )
                          }
                          routingNum={props.invoiceDetails?.routingNumber}
                          ngnAccountNum={props.ngnInvoice?.accountNumber}
                          usdAccountNum={props.invoiceDetails?.number}
                          bankname={props.invoiceDetails?.bankName}
                          vType={props.vType}
                        />
                      </div>
                      <p
                        style={{
                          color: '#2B5219',
                          fontSize: '13px',
                          fontWeight: '600',
                        }}
                      >
                        {name}
                      </p>

                      <div
                        className={`${
                          Copystate[index]
                            ? 'visaPaymentBankGrid__active'
                            : 'visaPaymentBankGrid__inactive'
                        }`}
                        ref={board}
                        onClick={() => {
                          copyCodeToClipboard(action, index);
                        }}
                      >
                        <p style={{ color: '#936710' }}>{action}</p>
                        <p
                          style={{
                            color: '#936710',
                            fontSize: '11px',
                            fontWeight: '300',
                          }}
                        >
                          {Copystate[index] ? 'Copied' : '- Click to Copy -'}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {/* setInvoiceDetails({
     accountName: res.data?.data?.account_name,
     amountdue: res.data?.data?.amount_due,
     customerEmail: res.data?.data?.customer_email,
     customerName: res.data?.data?.customer_name,
     number: res.data?.bank_information?.accountNumber,
     bankName: res.data?.bank_information?.bankName,
     routingNumber: res.data?.bank_information?.routingNumber,
     swiftCode: res.data?.bank_information?.swiftCode,
   }); */}

                {ngnbankdata.map(({ name, action }, index) => {
                  return (
                    <div key={index}>
                      <div style={{ display: 'none' }}>
                        <InvoiceComponent
                          ref={componentRef}
                          invoiceDetails={props.invoiceDetails}
                          currency={props.currency}
                          amount={
                            props.option === 'full-payment'
                              ? toWholeCurrency(props.amount * props.rate)
                              : toWholeCurrency(
                                  (props.installmental / 100) *
                                    props.amount *
                                    props.rate,
                                )
                          }
                          routingNum="---"
                          ngnAccountNum={props.ngnInvoice?.accountNumber}
                          usdAccountNum={props.invoiceDetails?.number}
                          bankname={props.ngnInvoice?.bankName}
                          accountName={props.ngnInvoice?.accountName}
                          vType={props.vType}
                        />
                      </div>
                      <p
                        style={{
                          color: '#2B5219',
                          fontSize: '13px',
                          fontWeight: '600',
                        }}
                      >
                        {name}
                      </p>

                      <div
                        className={`${
                          Copystate[index]
                            ? 'visaPaymentBankGrid__active'
                            : 'visaPaymentBankGrid__inactive'
                        }`}
                        ref={board}
                        onClick={() => {
                          copyCodeToClipboard(action, index);
                        }}
                      >
                        <p style={{ color: '#936710' }}>{action}</p>
                        <p
                          style={{
                            color: '#936710',
                            fontSize: '11px',
                            fontWeight: '300',
                          }}
                        >
                          {Copystate[index] ? 'Copied' : '- Click to Copy -'}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <p style={{ color: '#3E6F26', fontSize: '13px', fontWeight: '600' }}>
            NB: Payment approval will take 2-3 days. Contact{' '}
            <span style={{ color: '#67A948' }}>finance@wevesti.com </span>
            if you encounter any issues.
          </p>
        </div>
      </div>
    </>
  );
};
