import React from 'react';
import { useState, useRef } from 'react';
import info from '../../assets/info.svg';
import users from 'assets/usersp.svg';
import amount from 'assets/earned.svg';
import reward from 'assets/reward.svg';
import './referal.scss';

export const Referal = props => {
  const [copied, setCopied] = useState(false);

  const board = useRef(null);

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(props.link);
    setCopied(true);

    props.shareNum && props.shareNum(props.id, props.shares);
  };

  return (
    <div className="referal">
      <div className="referal__inner">
        {props.title && (
          <p className="referal__title">
            {' '}
            <i className="fas fa-link"></i> {props.title}
          </p>
        )}
        {props.link && (
          <p
            ref={board}
            className={`referal__link ${copied ? ' copied' : ''}`}
            onClick={copyCodeToClipboard}
          >
            {props.link}
          </p>
        )}
        {props.children}

        {copied ? (
          <p className="referal__copied">
            <i className="fas fa-thumbs-up"></i>{' '}
            {props.name ? props.name : 'Link'} has been copied to your clipboard
          </p>
        ) : (
          props.link && (
            <p className="referal-info">
              {' '}
              <img src={info} alt="info svg" />
              Click the box above to copy the link so you can share with your
              friends.
            </p>
          )
        )}
      </div>
    </div>
  );
};

export const ShareMessage = props => {
  const [copied, setCopied] = useState(false);

  const board = useRef(null);

  const copyCodeToClipboard = () => {
    setCopied(true);
  };

  return (
    <div className="referal">
      <div className="referal__inner">
        {props.title && (
          <p className="referal__title">
            {' '}
            <i className="fas fa-link"></i> {props.title}
          </p>
        )}
        {props.link && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            {/* const shareUrl = `https://t.me/share/url?url=$
            {encodeURIComponent(props.link)}&text=$
            {encodeURIComponent(props.text)}`; return ( */}
            <a
              href={`https://t.me/share/url?url=$
            {encodeURIComponent(app.wevesti.com/services)}&text=$
            {encodeURIComponent${props.link}}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p
                ref={board}
                className={`referal__link ${copied ? ' copied' : ''}`}
                onClick={copyCodeToClipboard}
                style={{ width: '100%' }}
              >
                Click to share on Telegram
              </p>{' '}
            </a>

            <a
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                `${props.link}`,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p
                ref={board}
                className={`referal__link ${copied ? ' copied' : ''}`}
                onClick={copyCodeToClipboard}
                style={{ width: '100%' }}
              >
                Click to share on X
              </p>
            </a>
            {/* <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                props.link,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p
                className={`referal__link ${copied ? ' copied' : ''}`}
                style={{ width: '100%' }}
              >
                Click to share on Facebook
              </p>
            </a> */}
          </div>
        )}
        {props.children}

        {props.link && (
          <p className="referal-info">
            {' '}
            <img src={info} alt="info svg" />
            Click the box above to share with your friends.
          </p>
        )}
      </div>
    </div>
  );
};

export const Referralbox = props => {
  // eslint-disable-next-line
  const data = [
    {
      title: 'Total users referred',
      value: props.totalReferred ? props.totalReferred : 0,
      img: users,
    },
    {
      title: 'Total referrals earned',
      value: props.ReferralAmount ? props.ReferralAmount : 0,
      img: amount,
    },
  ];

  return (
    <div className="refbox">
      <div className="refbox__inner">
        <div className="refbox__data">
          {data.map((item, index) => (
            <div className="refbox__rdata" key={index}>
              {/* <img className="refbox__dimg" alt={item.title} src={item.img} /> */}
              <span className="refbox__right">
                <p className="refbox__title">{item.title}</p>
                <p className="refbox__subtitle">
                  {item.title.includes('earned') && '₦'} {item.value}
                </p>
              </span>
            </div>
          ))}
        </div>
        <img className="refbox__img" src={reward} alt="reward" />
      </div>
    </div>
  );
};
